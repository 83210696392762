/* btn */

.i-btn {
	display: inline-block;
	padding: 3px 9px;
	min-height: 38px;
	width: 100%;
	border: 0;
	border-radius: 100px;
	background-color: #fff;

	position: relative;
	text-align: center;

	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 24px;
	color: #fff;

	transition: 0.2s ease;
	// defaults
	cursor: pointer;
	outline: none;
}

.i-btn-dropdown {
	display: flex;
	align-items: center;
	background-color: #E7F0F9;
	padding: 5px 30px 5px 18px;
	max-width: 150px;
	width: 100%;
	min-height: 38px;
	font-size: 14px;
	line-height: 21px;
	font-weight: 300;
	color: #1E85A9;

	&:after {
		content: "";
		display: block;
		width: 12px;
		height: 7px;
		background-image: url("../img/arrow-botom-blue.svg");
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		position: absolute;
		top: 50%;
		right: 12px;
		z-index: 1;
		transform: translateY(-50%);
	}
}


.i-btn--small {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 84px;
	width: 100%;
	font-size: 20px;
	line-height: 30px;
	@media(max-width: 767px) {
		font-size: 14px;
		line-height: 21px;
		max-width: 64px;
		min-height: 30px;
	}
}

.i-btn--long {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 210px;
	min-height: 40px;
	width: 100%;
	font-weight: 600;
	@media(max-width: 767px) {
		max-width: 170px;
		min-height: 36px;
		font-size: 14px;
		line-height: 21px;
	}
}

.i-btn-border--yellow {
	border: 1px solid #F7A300;
}

.i-btn-icon--star {
	&:before {
		content: "";
		display: inline-block;
		width: 20px;
		height: 19px;
		background-image: url("../img/star.svg");
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		margin-right: 3px;
		@media(max-width: 767px) {
			width: 15px;
			height: 14px;
		}
	}
}

.i-btn--blue {
	background-color: $color_blue1;
	max-width: 100px;
	padding: 7px 9px;
}

.i-btn--blue-long {
	display: block;
	margin-right: auto;
	margin-left: auto;
	max-width: 156px;
	min-height: 40px;
}

.i-btn--red {
	background-color: #ff594f;
	max-width: 100px;
	padding: 7px 9px;
}

.i-btn-border--red {
	border: 1px solid #F55E46;
}
.i-btn-icon--letter {
	&:before {
		content: "";
		display: inline-block;
		width: 19px;
		height: 14px;
		background-image: url("../img/letter.svg");
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		margin-right: 3px;
		@media(max-width: 767px) {
			width: 15px;
			height: 11px;
		}
	}
}

.i-btn-icon--loupe {
	&:before {
		content: "";
		display: inline-block;
		width: 19px;
		height: 19px;
		background-image: url("../img/loupe.svg");
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		margin-right: 3px;
		@media(max-width: 767px) {
			width: 14px;
			height: 14px;
		}
	}
}

.i-btn-aside {
	@extend .i-btn;

	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 200px;
	background-color: #F6A300;
	font-size: 14px;
	line-height: 21px;
	font-weight: 700;
	text-align: center;
	color: #FFFFFF;
	margin-left: auto;
	margin-right: auto;
	padding: 4px 10px;
	min-height: 40px;

	@at-root :root.hover &:hover {
		opacity: .7;
	}

	&.change {

		.show {
			display: none;
		}

		.hide {
			display: block;
		}
	}

	&__num {
		font-size: 16px;
		line-height: 24px;
		margin-left: 7px;
	}

	&__small {
		font-size: 11px;
		line-height: 16px;
	}

	.hide {
		display: none;
	}
}

.i-btn-flex {
	display: flex;
	align-items: center;
	justify-content: center;
}

.i-btn-nav {
	display: block;
	width: 100%;
	max-width: 120px;
	margin: 0;
	padding: 3px 10px;
	border: none;
	border-radius: 100px;

	font-size: 16px;
	line-height: 24px;
	text-align: center;
	color: #FFFFFF;

	transition: 0.2s ease;
	cursor: pointer;
	outline: none;

	&--red {
		background-color: #FF594F;
	}

	&--blue {
		background-color: #1E85A9;
	}
}
