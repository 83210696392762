@charset "utf-8";

// ▼Sass設定

@import "_variables.scss";

/***************************************
 求人情報・企業情報共通
***************************************/

@mixin pc {
  @media screen and (min-width:768px) {
    @content;
  }
}

@mixin sp {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin hover {
  @media screen and (min-width: 768px) {
    &:hover {
      @content;
    }
  }
}

@media (min-width: 768px) {
  .sp-only {
    display: none !important;
  }
}

@include sp {
  .pc-only {
    display: none !important;
  }
}

.l-job-listArea {
  overflow: hidden;
}

.l-job-header {
  overflow: hidden;
  margin: 0 0 10px;
}

.l-job-search {
  background: #f6f6f6;
  padding: 15px 0;
  margin-bottom: 15px;
  border-radius: 4px;

  @include sp {
    border-radius: 6px;
    margin: 0 10px 20px;
    padding: 10px;
  }

  &__box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  &__bnt {
    border: 1px solid #3b89ad;
    border-radius: 7px;
    background: none;
    color: #3b89ad;
    font-weight: bold;
    line-height: 30px;
    padding: 0 10px;
    font-size: 12px;
  }

  &__text {
    color: #3b89ad;
    font-size: 18px;
    font-weight: bold;

    .sub {
      font-size: 12px;
      font-weight: normal;
      display: inline-block;
      vertical-align: middle;
    }

    .pager {
      color: #000;
      font-weight: normal;
      font-size: 12px;
      margin-left: 5px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .c-form {
    padding-top: 10px;

    &__anchor {
      @include sp {
        width: 100% !important;
      }
    }

    @include pc {
      display: block !important;
    }

    @include sp {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

.l-job-listTab {
  overflow: hidden;
  float: left;

  li {
    width: 190px;
    float: left;
    text-align: center;
    font-size: 15px;

    img {
      width: 15px;
      display: block;
      margin: 0 auto;
    }

    span {
      display: block;
      @include common_Height(46px);
      background: $color_base;
      color: #ffffff;
    }

    a {
      display: block;
      @include common_Height(46px);
      background: #C3C3C3;
      color: $color_gray1;
    }
  }
}

.m-job-logo {
  float: right;
  width: 100px;

  img {
    width: 100%;
  }
}

.l-job-endBox {
  border: solid 1px $color_red1;
  text-align: center;
  padding: 15px 8px 20px;
  font-size: 18px;
  font-weight: bold;
  color: $color_red1;
  margin: 0 0 20px;
}

.l-jobArea {
  margin: 0 0 45px;

  section {
    overflow: hidden;
    margin: 0 0 10px;
  }
}

.l-job-imgBox {
  width: $width_left / 2 - 12;
  margin: 5px 20px 10px 0;
  float: left;

  img {
    width: 100%;
    margin: 0 0 3px;
  }

  span {
    font-size: 12px;
    display: inline-block;
    line-height: 1.4;
  }
}

.l-job-imgSubbox {
  overflow: hidden;
  margin: 15px 0;
  clear: both;

  .l-job-imgBox:nth-child(even) {
    margin: 5px 0 5px;
    float: right;
  }
}

.m-job-sideCategory {
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
}

.l-job-requirements {
  border: solid 1px $color_gray1;
  margin: 0 0 20px;

  h3 {
    @include common_Height(42px);
    background: $color_gray1;
    color: #ffffff;
    padding: 0 15px;
  }

  dl {
    border-bottom: dotted 1px $color_gray3;
    padding: 12px 3px;
    margin: 0 15px;

    &:last-child {
      border: none;
    }

    dt {
      display: inline-block;
      font-weight: bold;
      width: 15%;
      vertical-align: top;
    }

    dd {
      display: inline-block;
      margin: 0;
      width: 84%;
    }
  }
}

.l-job-profile {
  border: solid 1px $color_gray1;
  margin: 0 0 30px;

  h3 {
    @include common_Height(42px);
    background: $color_gray1;
    color: #ffffff;
    padding: 0 15px;
    font-size: 17px;
    font-weight: normal;
  }

  dl {
    border-bottom: dotted 1px $color_gray3;
    padding: 12px 3px;
    margin: 0 15px;

    &:last-child {
      border: none;
    }

    dt {
      display: inline-block;
      font-weight: bold;
      width: 15%;
      vertical-align: top;
    }

    dd {
      display: inline-block;
      margin: 0;
      width: 84%;
    }
  }
}

.l-job-side-btnBox_pc {
  margin: 0 0 20px;

  a {
    display: inline-block;
    border-radius: 6px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    @include common_Height(50px);
    width: 100%;
    margin: 0 0 6px;
    border-bottom: solid 4px;
  }

  .m-job-btn_orange_pc {
    background: $color_orange1;
    border-bottom-color: $color_orange2;
  }

  .m-job-btn_red_pc {
    background: $color_red1;
    border-bottom-color: $color_red2;
  }

  .m-job-btn_green_pc {
    background: $color_green1;
    border-bottom-color: $color_green2;
  }

  .m-job-btn_gray_pc {
    background: $color_gray2;
    border-bottom-color: $color_gray1;
    border: none;
    @include common_Height(54px);
  }

  .m-job-btn_greenWide_pc {
    background: $color_green1;
    border-bottom-color: $color_green2;
    @include common_Height(65px);
  }
}

.l-job-side-btnBox_sp {
  display: none;
}

.l-job-side_sp {
  display: none;
}

.l-job-similarArea {
  margin: 30px 0;
  overflow: hidden;
}

.is-job-floatBox_pc {
  width: $width_right;
}

@media (max-width: 767px) {
  .is-job-floatBox_pc {
    width: 100%!important;
  }
}

.is-job-floatBox_pc.floating {
  position: fixed;
  top: 0;
}

.is-job-floatBox_pc.pinned-bottom {
  position: absolute;
  bottom: 0;
}

.l-job-snsFavoriteBox {
  margin: 20px 0;
  background: #E6E6E6;
  display: table;

  div {
    display: table-cell;
    vertical-align: middle;
  }
}

.m-job-snsFavoriteImg {
  width: 32%;
  display: inline-block;
  vertical-align: top;

  img {
    width: 100%;
  }
}

.m-job-snsFavoriteBtn {
  margin: 10px 0;
  width: 65%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  line-height: 1.4;

  p {
    font-size: 17px;
    font-weight: bold;
    margin: 0 0 15px;

    br {
      display: none;
    }
  }

  small {
    font-size: 16px;
    display: block;
    margin: 10px 0 8px;
  }

  .m-job-snsBtn_fbLike {
    display: inline-block;
    vertical-align: top;
    transform: scale(1.29);
    -webkit-transform: scale(1.29);
    -moz-transform: scale(1.29);
    position: relative;
    top: 0;
    left: -20px;
  }

  .m-job-snsBtn_twitter {
    display: inline-block;
    vertical-align: top;
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    position: relative;
    top: 0;
    left: 20px;
    background: none;
  }
}

.l-search-job-contents {
  padding-bottom: 600px;
}

.l-special-content-area h2 {
  border: solid 1px #5589AD;
  border-radius: 3px;
  color: #5589AD;
  padding: 10px;
  margin: 28px 0 20px;
  font-size: 18px;
  line-height: 1.5;
}

.l-special-content-sub-area {
  margin-bottom: 28px;

  h3 {
    border-bottom: solid 1px #5589AD;
    padding-bottom: 6px;
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-job-listTab {
    li {
      width: 120px;

      img {
        width: 10px;
      }

      span {
        @include common_Height(40px);
      }

      a {
        @include common_Height(40px);
      }
    }
  }

  .m-job-logo {
    width: 80px;
  }

  .l-jobArea {
    margin: 0 0 30px;
    overflow-wrap: break-word;
  }

  .l-job-imgBox {
    width: $width_all / 2 - 2;
    margin: 5px 15px 5px 0;
    float: left;

    img {
      width: 100%;
      margin: 0 0 3px;
    }

    span {
      font-size: 12px;
    }
  }

  .l-job-imgSubbox {
    margin: 15px 0 10px;

    .l-job-imgBox {
      width: $width_all / 2 - 2;
    }
  }

  .l-job-side-btnBox_pc {
    display: none;
  }

  .l-job-side-btnBox_sp {
    display: block;
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: $width_all;
    overflow: hidden;

    a {
      display: block;
      font-size: 16px;
      color: #ffffff;
      border: none;
      text-align: center;
      @include common_Height(48px);
      width: $width_all / 2;
      float: left;
    }
  }

  @mixin l-job-side-btnBox($width) {
    display: block;
    position: fixed;
    bottom: 0;
    z-index: 3;
    width: $width_all;
    overflow: hidden;
    background: #ffffff;

    a {
      display: block;
      font-size: 15px;
      color: #ffffff;
      border: none;
      text-align: center;
      @include common_Height(48px);
      width: $width;
      float: left;
    }
  }

  .l-job-side-btnBox2_sp {
    @include l-job-side-btnBox($width_all / 2);
  }

  .l-job-side-btnBox1_sp {
    @include l-job-side-btnBox($width_all);
  }

  .m-job-btn_red_sp {
    background: $color_red1;
  }

  .m-job-btn_green_sp {
    background: $color_green1;
  }

  .m-job-btn_gray_sp {
    background: $color_gray2;
  }

  .l-job-profile {
    h3 {
      font-size: 16px;
    }
  }

  .l-job-side_pc {
    display: none;
  }

  .l-job-side_sp {
    display: block;
  }

  .m-job-snsFavoriteImg {
    width: 40%;
  }

  .m-job-snsFavoriteBtn {
    width: 58%;

    p {
      font-size: 15px;
      margin: 4px 0 10px;

      br {
        display: inline;
      }
    }

    small {
      font-size: 13px;
      margin: 8px 0 0;
    }

    .m-job-snsBtn_fbLike {
      position: relative;
      left: 0;
      display: block;
      margin: 0 0 12px;
    }

    .m-job-snsBtn_twitter {
      position: relative;
      left: 0;
      display: block;
    }
  }

  .l-special-content-area h2 {
    padding: 10px;
    margin: 26px 0 15px;
    font-size: 15px;
  }

  .l-special-content-sub-area {
    margin-bottom: 26px;
  }
}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-job-header {
    overflow: hidden;
    margin: 0 0 6px;
  }

  .l-job-imgBox {
    width: $width_all;
    margin: 5px 10px 5px 0;
    float: left;

    img {
      width: 100%;
      margin: 0 0 2px;
    }

    span {
      font-size: 11px;
    }
  }

  .l-job-requirements {
    margin: 0 0 20px;

    h3 {
      @include common_Height(40px);
      padding: 0 10px;
      font-size: 16px;
      font-weight: normal;
    }

    dl {
      padding: 8px 3px;
      margin: 0 10px;

      &:last-child {
        border: none;
      }

      dt {
        display: inline-block;
        font-weight: bold;
        width: 100%;
        vertical-align: top;
      }

      dd {
        display: inline-block;
        margin: 0;
        width: 100%;
        font-size: 13px;
      }
    }
  }

  .l-job-profile {
    margin: 0 0 20px;

    h3 {
      @include common_Height(38px);
      padding: 0 10px;
    }

    dl {
      font-size: 12px;
      padding: 6px 2px;
      margin: 0 10px;

      dt {
        width: 24%;
      }

      dd {
        width: 74%;
      }
    }
  }

  .l-job-side-btnBox_sp {
    a {
      font-size: 14px;
    }
  }

  .m-job-snsFavoriteImg {
    width: 47%;
    overflow: hidden;
  }

  .m-job-snsFavoriteBtn {
    width: 52%;

    p {
      font-size: 13px;

      span {
        display: none;
      }
    }

    small {
      font-size: 11px;
      margin: 0;
    }

    .m-job-snsBtn_fbLike {
      margin: 0 0 15px;
    }
  }

  .l-special-content-area {
    font-size: 13px;
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .l-job-listTab {
    overflow: hidden;
    float: left;

    li {
      width: 100px;
      float: left;
      text-align: center;
      font-size: 12px;

      span {
        @include common_Height(38px);
      }

      a {
        @include common_Height(38px);
      }
    }
  }

  .m-job-logo {
    width: 70px;
  }

  .l-job-imgBox {
    span {
      font-size: 10px;
    }
  }

  .l-job-imgSubbox {
    margin: 10px 0 5px;
  }

  .m-job-snsFavoriteImg {
    width: 48%;

    img {
      position: relative;
      left: -5px;
      top: 0;
      width: 105%;
    }
  }

  .m-job-snsFavoriteBtn {
    width: 51%;

    p {
      font-size: 10px;
    }

    small {
      font-size: 10px;
    }
  }

}

/***************************************
 求人一覧(特集部分)
***************************************/

.l-job-list_about {
  margin: 0 0 15px;

  h2 {
    line-height: 1.4;
    font-size: 15px;
    margin: 0 0 8px;
    padding: 5px;
    background: #E7F0F9;
    border-top: solid 1px #AECAE6;
    border-bottom: solid 1px #AECAE6;
  }

  p {
    margin: 0;
    padding: 0 3px;
    font-size: 10px;
    line-height: 1.6;
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-job-list_about {}

}


/***************************************
 求人情報
***************************************/

.l-job-title {
  line-height: 1.4;
  background: $color_blue4;
  border-top: solid 1px #AECAE6;
  border-bottom: solid 1px #AECAE6;
  padding: 16px;
  margin: 0 0 15px;

  h2 {
    font-size: 23px;
  }

  .m-job-titleName {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 10px;
  }

  .l-job-title-iconBox {
    display: inline;
  }
}

.l-job-skill {
  background: #eeeeee;
  padding: 10px 15px 15px;
  margin: 20px 0;

  h3 {
    font-size: 16px;
    margin: 0 0 15px;
    padding: 0 0 5px;
    border-bottom: dotted 1px $color_gray3;
  }
}

.m-common-icon_jobSkill {
  display: inline-block;
  background: $color_blue1;
  border-radius: 3px;
  color: #ffffff;
  padding: 0 6px;
  margin: 0 2px 5px 0;
  @include common_Height(24px);
}

.m-common-icon_business {
  display: inline-block;
  border: solid 1px $color_blue1;
  border-radius: 3px;
  color: $color_blue1;
  padding: 0 6px;
  background: #ffffff;
  margin: 3px 0;
  @include common_Height(22px);
}

.m-common-icon_jobCategory {
  display: inline-block;
  border: solid 1px $color_blue1;
  background: $color_blue1;
  border-radius: 3px;
  color: #ffffff;
  padding: 0 6px;
  margin: 3px 0;
  @include common_Height(22px);
}

.m-common-icon_jobReference {
  display: inline-block;
  border: solid 1px $color_red1;
  background: #ffffff;
  border-radius: 3px;
  color: $color_red1;
  padding: 0 6px;
  margin: 0 0 0 5px;
  @include common_Height(22px);
}

.m-common-icon_location {
  display: inline-block;
  border: solid 1px $color_gray1;
  border-radius: 3px;
  color: $color_gray1;
  padding: 0 6px;
  background: #ffffff;
  margin: 3px 0;
  @include common_Height(22px);
}

.l-job-btnBox {
  text-align: center;
  margin: 10px 0;
}

@mixin entry_btn_style($size) {
  display: inline-block;
  border-radius: 5px;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  margin: 0 2px;
  vertical-align: top;
  @include common_Height($size);
}

.m-jobEntry-btn_red {
  background: $color_red1;
  border: none;
  border-bottom: solid 4px $color_red2;
  @include entry_btn_style(47px);
}

.m-jobEntry-btn_green {
  background: $color_green1;
  border: none;
  border-bottom: solid 4px $color_green2;
  @include entry_btn_style(47px);
}

.m-jobEntry-btn_gray {
  background: $color_gray2;
  border-bottom-color: $color_gray1;
  border: none;
  @include entry_btn_style(51px);
}

.m-job-btn_s {
  width: 280px;
}

.m-job-btn_l {
  width: 380px;
}

.m-job-btn_all {
  width: 100%;
  margin: 0;
}


/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-job-title {
    padding: 10px 12px;
    margin: 0 0 15px;

    h2 {
      font-size: 18px;
    }

    .l-job-title-iconBox {
      margin: 0 0 5px;
      display: block;
    }

    .m-job-titleName {
      margin: 0 0 5px;
      display: block;
    }
  }

  .m-common-icon_business {
    padding: 0 4px;
    @include common_Height(16px);
    font-size: 11px;
  }

  .m-common-icon_jobCategory {
    padding: 0 4px;
    @include common_Height(16px);
    font-size: 11px;
  }

  .m-common-icon_jobReference {
    padding: 0 4px;
    margin: 0 0 0 3px;
    @include common_Height(16px);
    font-size: 11px;
  }

  .m-common-icon_location {
    padding: 0 4px;
    @include common_Height(16px);
    font-size: 11px;
  }

  .m-job-icon_corp {
    display: block;
    font-size: 11px;
    font-weight: bold;
    color: $color_blue1;
  }

  .l-job-skill {
    padding: 5px 10px 10px;
    margin: 15px 0;

    h3 {
      font-size: 15px;
      margin: 0 0 10px;
      padding: 0 0 5px;
    }
  }

  .m-common-icon_jobSkill {
    margin: 0 2px 0 0;
    @include common_Height(20px);
    font-size: 12px;
  }

  .m-jobEntry-btn_red {
    @include entry_btn_style(40px);
    font-size: 14px;
    border-radius: 3px;
    margin: 3px 0;
  }

  .m-jobEntry-btn_green {
    @include entry_btn_style(40px);
    font-size: 14px;
    border-radius: 3px;
    margin: 3px 0;
  }

  .m-jobEntry-btn_gray {
    @include entry_btn_style(44px);
    font-size: 14px;
    border-radius: 3px;
    margin: 3px 0;
  }

  .m-job-btn_s {
    width: 40%;
  }

  .m-job-btn_l {
    width: 58%;
  }

  .l-search-job-contents {
    padding-bottom: 200px;
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .l-job-title {
    padding: 8px;

    h2 {
      font-size: 16px;
    }

    .m-job-titleName {
      font-size: 12px;
    }
  }

  .m-job-icon_corp {
    display: block;
    font-size: 10px;
    font-weight: bold;
    color: $color_blue1;
  }

  .m-job-btn_s {
    width: 100%;
  }

  .m-job-btn_l {
    width: 100%;
  }

}

/***************************************
 ソーシャルボタン（求人）
***************************************/

.l-job-snsBtnArea {
  margin: 0 0 30px;
  overflow: hidden;

  li {
    vertical-align: top;
    display: inline-block;

    a {
      display: block;
      position: relative;
      width: 74px;
      height: 31px;
      border-radius: 2px;

      img {
        width: auto;
        height: 66%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}

.m-job-snsBtn_fb {
  background: #3b5999;
}

.m-job-snsBtn_twitter {
  background: #54abed;
}

.m-job-snsBtn_hatebu {
  background: #008fde;
}

.m-job-snsBtn_line {
  background: #00c300;
}

.m-job-snsBtn_fbLike {
  display: inline-block;
  transform: scale(1.29);
  -webkit-transform: scale(1.29);
  -moz-transform: scale(1.29);
  position: relative;
  top: 2px;
  left: 9px;
}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-job-snsBtnArea {
    margin: 0 0 10px;

    li {
      a {
        width: 42px;
      }
    }
  }

  .m-job-snsBtn_fbLike {
    display: inline-block;
    transform: scale(1.29);
    -webkit-transform: scale(1.29);
    -moz-transform: scale(1.29);
    position: relative;
    top: 2px;
    left: 9px;
  }

}


.c-form {
  width: 940px;
  margin: 0 auto;
  text-align: left;
  $root: &;

  @include sp {
    width: 100%;
  }

  &__title {
    font-size: 16px;
    margin-bottom: 2px;
    color: #fff;
    font-weight: bold;

    @include sp {
      text-align: center;
      color: #156a87;
      font-weight: normal;
      margin-bottom: 5px;
    }
  }

  &__box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    border-radius: 4px;

    @include sp {
      justify-content: space-between;
      background: none;
    }
  }

  &__key {
    width: 180px !important;
    padding: 0 !important;
    margin: 0 !important;
    background: none !important;
    border: none !important;
    padding: 0 18px !important;
    color: #555;
    background: #fff !important;
    height: 64px !important;
    font-weight: bold;
    font-size: 15px;

    &::placeholder {
      color: #ccc;
      opacity: 1;
    }

    @include sp {
      width: 100% !important;
      height: 44px !important;
      border-radius: 6px !important;
      border: 1px solid #dddddd !important;
    }
  }

  &__submit {
    background: #f65e47;
    border: none;
    padding: 0;
    margin: 0;
    width: 68px;
    height: 64px;
    border-radius: 0 4px 4px 0;

    @include hover {
      opacity: 0.8;
    }

    @include sp {
      width: 100%;
      border-radius: 6px;
      margin-top: 12px;
      background: #1e85a8;
      height: 50px;
    }

    >* {
      color: #fff;
      font-size: 18px;
      vertical-align: middle;
      width: 18px;
    }
  }

  &__checkbox {
    width: 172px;
    border-right: 1px solid #dddddd;
    position: relative;

    @include sp {
      width: calc(50% - 3px);
      border: none;
      margin-bottom: 6px;
      text-align: left;
    }

    &:first-child {
      a {
        @include pc {
          border-radius: 4px 0 0 4px;
        }
      }

      #{$root}__val {
        @include pc {
          grid-template-columns: auto auto auto auto auto;
        }

        @include sp {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          li {
            width: 100%;
          }
        }
      }
    }

    &:nth-child(4) {
      #{$root}__val {
        @include pc {
          right: -100%;
          left: inherit;
          grid-template-columns: auto auto auto;
        }
      }

      a {
        @include sp {
          padding-left: 8px;
        }
      }
    }

    &:nth-child(2n+2) {
      #{$root}__val {
        @include sp {
          right: 0;
          left: inherit;
        }
      }
    }

    a {
      color: #555555;
      font-weight: bold;
      font-size: 15px;
      line-height: 64px;
      display: block;
      padding-right: 15px;
      position: relative;
      text-align: center;
      opacity: 1 !important;
      cursor: pointer;
      background: #fff;

      @include sp {
        text-align: left;
        padding-right: 0;
        padding-left: 10px;
        line-height: 42px;
        white-space: nowrap;
        border: 1px solid #dddddd;
        border-radius: 6px;
      }

      @include hover {
        background: #f1f9fb;
      }

      &.active {
        background: #f1f9fb;

        &::after {
          content: '';
          position: absolute;
          left: calc(50% - 12px);
          bottom: -38px;
          width: 20px;
          height: 20px;
          background: #f1f9fb;
          z-index: 99;
          border-left: 1px solid #dddddd;
          border-top: 1px solid #dddddd;
          transform: rotate(45deg);

          @include sp {
            //bottom: -31px;
            display: none;
          }
        }
      }

      &.selecting {
        background: #f1f9fb;
        color: #1e85a9;
      }

      &::before {
        content: '';
        width: 12px;
        height: 8px;
        position: absolute;
        top: calc(50% - 4px);
        right: 14px;
        transition: .3s;
        // background: url(/assets/img/common/common_arrow_bg.png) no-repeat center bottom;
        background-size: 12px 8px;

        @include sp {
          right: 7px;
        }
      }
    }

    i {
      color: #1782a6;
      font-size: 21px;
      margin-right: 7px;
      display: inline-block;
      vertical-align: middle;
    }

    svg {
      width: 18px;
      color: $color_blue1;
      vertical-align: middle;
    }

    .fa-map-marker-alt {
      width: 16px;
    }
    .fa-briefcase {
      width: 20px;
    }
    .fa-building {
      width: 18px;
    }
    .fa-star {
      width: 21px;
    }
  }

  &__val {
    position: absolute;
    top: calc(100% + 27px);
    left: 0;
    background: #f1f9fb;
    color: #000;
    z-index: 9;
    border: 1px solid #cdd2d5;
    border-radius: 6px;
    white-space: nowrap;
    overflow: hidden;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2);

    @include pc {
      display: inline-grid;
      grid-template-columns: auto auto;
    }

    @include sp {
      top: calc(100% + 17px);
      width: calc(100vw - 42px);
      height: calc(100% + 260px);
      overflow-y: scroll;
    }

    li {
      line-height: 54px;
      border-bottom: 1px solid #cdd2d5;
      border-right: 1px solid #cdd2d5;
      margin: 0 -1px -1px 0;
      padding: 0 30px 0 60px;
      box-sizing: border-box;

      @include sp {
        line-height: 40px;
        font-size: 12px;
        padding-left: 40px;
      }

      input[type=checkbox] {
        &+label {
          margin: 0;
          padding: 0;

          &:before {
            border: 2px solid #1a81a2;
            width: 22px;
            height: 22px;
            left: -30px;
            top: 3px;
            border-radius: 4px;
          }

        }

        &:checked+label {
          &::before {
            background: #1a81a2;
          }

          &::after {
            border-left: 3px solid #fff;
            border-bottom: 3px solid #fff;
            left: -25px;
            top: 0;
            margin: 0;
          }
        }
      }
    }
  }

  &__select {
    @include pc {
      display: none !important;
    }

    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }

  &__balloon {
    &.active {
      &::before {
        @include sp {
          content: '';
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          left: calc(50% - 10px);
          top: 46px;
          border-right: 15px solid transparent;
          border-bottom: 15px solid #DDDDDD;
          border-left: 15px solid transparent;
          z-index: 99;
        }
      }

      &::after {
        @include sp {
          content: '';
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          left: calc(50% - 9px);
          top: 48px;
          border-right: 14px solid transparent;
          border-bottom: 14px solid #F1F9FB;
          border-left: 14px solid transparent;
          z-index: 99;
        }
      }
    }
  }
}

[v-cloak] {
  display: none;
}
