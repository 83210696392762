.i-modal {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	padding: 20px;
	visibility: hidden;
	z-index: -100;
	opacity: 0;
	//transition: .4s;

	&.open {
		visibility: visible;
		z-index: 9999;
		opacity: 1;
	}

	&__wrap {
		margin: auto;
		max-width: 420px;
		width: 100%;
	}

	&__inner {
		width: 100%;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	&__inner-wrap {
		position: relative;
	}

	&__desc-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 60px 0;
		text-align: center;
	}

	&__btn-wrap {
		display: flex;
		flex-wrap: wrap;
	}

	&__btn {
		display: block;
		color: #fff;
		width: 100%;
		max-width: 90px;
		margin-right: auto;
		margin-left: auto;
		box-shadow: none;
		background-color: transparent;
		font-size: 15px;
		line-height: 23px;
		font-weight: 300;
		padding: 5px 10px 5px 30px;
		border: 1px solid #FFFFFF;
		border-radius: 125px;
		position: relative;

		&:before {
			content: "";
			display: block;
			width: 12px;
			height: 12px;
			background-image: url("../img/cross.svg");
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: cover;
			position: absolute;
			top: 50%;
			left: 12px;
			z-index: 1;
			transform: translateY(-50%);
		}

		@at-root :root.hover &:hover {
			opacity: 0.7;
		}
	}

		&__desc-title {
			font-size: 24px;
			line-height: 36px;
			font-weight: 300;
			color: #fff;
			margin-bottom: 30px;
		}

		&__desc-list {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			margin-right: -15px;
			margin-left: -15px;
		}

		&__desc-item {
			display: block;
			width: 50%;
			text-align: center;
			padding-right: 15px;
			padding-left: 15px;
			&:nth-child(2) {
				.i-modal__desc-inner-img {
					max-width: 62px;
				}
			}
		}

		&__desc-inner {
			display: block;
			width: 100%;
			border: 0;
			padding: 30px 15px;
			background-color: #FFFFFF;
			box-shadow: 0px 4px 0px #CBCBCB;
			border-radius: 13px;
		}

		&__desc-inner-img {
			display: block;
			width: 100%;
			max-width: 72px;
			margin-right: auto;
			margin-bottom: 10px;
			margin-left: auto;
			img {
				display: block;
				width: 100%;
			}
		}

		&__desc-inner-title {
			font-size: 22px;
			line-height: 33px;
			font-weight: 600;
			color: #555555;
		}
}
