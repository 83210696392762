.i-article {
  margin-top: 20px;
}
.i-article-list {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
  @media (max-width: 767px) {
    margin-right: -5px;
    margin-left: -5px;
  }
}
.i-article-item {
  display: block;
  width: calc(100% / 3);
  padding-right: 8px;
  padding-left: 8px;
  margin-bottom: 24px;
  @media (max-width: 767px) {
    width: 50%;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 12px;
  }
}
.i-article-link {
  display: block;
}
.i-article-wrapper {
  display: block;
  width: 100%;
  max-width: 230px;
  margin-bottom: 7px;

  img {
    display: block;
    width: 100%;
  }
}
.i-article-title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #1e85a9;
  margin-bottom: 5px;
  @media (max-width: 767px) {
    font-size: 13px;
    line-height: 18px;
  }
}
.i-article-text {
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  color: #555;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 10px;
    line-height: 15px;
  }
}
.i-article-text--big {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: 767px) {
    margin-bottom: 6px;
    font-size: 13px;
    line-height: 19px;
  }
}
.i-article-text--small {
  font-size: 11px;
  line-height: 16px;
  @media (max-width: 767px) {
    font-size: 9px;
    line-height: 13px;
  }
}
.i-article-search {
}
.i-article-search-title {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 7px;
  @media (max-width: 767px) {
    font-size: 13px;
    line-height: 19px;
  }
  span {
    display: inline-block;
    position: relative;
    &:before {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      position: absolute;
      top: 50%;
      left: -18px;
      z-index: 1;
      transform: translateY(-50%);
      background-image: url("../img/loupe.svg");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      @media (max-width: 767px) {
        width: 12px;
        height: 12px;
      }
    }
  }
}
.i-article-search-inner {
  display: flex;
  position: relative;
}
.i-article-search-item {
  display: block;
  width: 100%;
  margin-right: 8px;
  @media (min-width: 768px) {
    position: relative;
  }
  &:first-child {
    .i-article-search-btn {
      margin-left: auto;
    }
  }

  &:last-child {
    margin-right: 0;

    .i-article-search-content {
      left: -90px;
      @media (max-width: 767px) {
        left: -15px;
      }
    }
  }
}
.i-article-search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 220px;
  min-height: 46px;
  padding: 6px 30px;
  width: 100%;
  border: 0;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #666;

  position: relative;
  text-align: center;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #666;

  transition: 0.2s ease;
  // defaults
  cursor: pointer;
  outline: none;

  @media (max-width: 767px) {
    max-width: 160px;
    min-height: 42px;
    font-size: 12px;
    line-height: 18px;
  }

  &.active {
    background-color: #666;
    color: #fff;
    &:after {
      background-image: url("../img/arrow-top-white.svg");
    }
  }

  &:after {
    content: "";
    display: block;
    background-image: url("../img/arrow-botom-black.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 14px;
    height: 8px;
    position: absolute;
    top: 50%;
    right: 12px;
    z-index: 1;
    transform: translateY(-50%);
  }
}

.i-article-search-content {
  display: none;
  background-color: #efefef;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding-bottom: 12px;
  width: 400px;
  position: absolute;
  top: 53px;
  left: 45px;
  z-index: 10;
  @media (max-width: 767px) {
    left: -15px;
    width: 100vw;
  }
  &.active {
    display: block;
  }
}
.i-article-search-list {
  margin-bottom: 12px;
}
.i-article-search-list-item {
  padding-right: 24px;
  padding-left: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
}
.i-article-search-label {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
.i-article-search-input {
  display: block;
  margin-right: 8px;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  background-color: #ffffff;
  border: 1px solid #cecece;
  border-radius: 4px;
  position: relative;
  &:after {
    content: "";
    display: block;
    width: 19px;
    height: 15px;
    background-image: url("../img/check.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
  }
}
