@charset "utf-8";

// Sass設定
$width_all: 100%;
$width_sp: 93%;
$width_base: 1050px;
$width_left: 720px;
$width_right: 300px;
$color_base: #555555;
$color_blue1: #1e85a9;
$color_blue2: #5589AD;
$color_blue3: #E2EBF3;
$color_blue4: #E7F0F9;
$color_gray1: #888888;
$color_gray2: #a1a1a1;
$color_gray3: #b8b8b8;
$color_gray4: #EFEFEF;
$color_light_gray1: #DADADA;
$color_yellow1: #FED868;
$color_red1: #F55E46;
$color_red2: #C54B38;
$color_orange1: #f98019;
$color_orange2: #c75c00;
$color_green1: #2BA57E;
$color_green2: #2C8467;

//ボタン・タイトル・カテゴリーアイコンで使う
@mixin common_Height($size){
  line-height: $size;
  height: $size;
}

//ホバーで使う
@mixin common_Alpha($value: 0.7){
opacity: $value;
-moz-opacity: $value;
filter: alpha(opacity=70);
}

//背景に画像入れるときに使う
@mixin common_bgImg($height, $width){
  height: 0;
  padding: $height 0 0;
  width: $width;
  overflow: hidden;
}
