.i-recruitment {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 18px;
}
.i-recruitment-label {
	display: flex;
	align-items: center;
	justify-self: flex-end;
	width: 100%;
	white-space: nowrap;
	cursor: pointer;
	padding-top: 15px;
	padding-bottom: 15px;
	input {
		display: block;
		border: 0;
		clip: rect(0 0 0 0);
		height: auto;
		margin: 0;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
		white-space: nowrap;
	}

	&.checked {
		.i-recruitment-input {
			background-color: #1E85A9;
			padding-right: 21px;
			padding-left: 6px;
			&:after {
				content: "";
				left: 32px;
			}
		}
	}
}
.i-recruitment-label--auto {
	width: auto;
}
.i-recruitment-input {
	position: relative;
	display: block;
	width: 50px;
	height: 20px;
	padding-top: 2px;
	padding-right: 6px;
	padding-bottom: 2px;
	padding-left: 21px;
	font-size: 11px;
	line-height: 16px;
	font-weight: 300;
	color: #fff;
	background-color: #848484;
	border-radius: 100px;
	margin-left: 3px;
	&:after {
		content: "";
		display: block;
		width: 16px;
		height: 16px;
		background-color: #fff;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 2px;
		z-index: 1;
		transform: translateY(-50%);
		transition: 0.2s ease;
	}
}
.i-recruitment-content {
	display: block;
	width: 100%;
	text-align: right;
}
.i-recruitment-title {
	font-size: 14px;
	line-height: 21px;
	font-weight: 600;
	margin: 0;
}
.i-recruitment-title--big {
	font-size: 20px;
	line-height: 30px;
	color: #FF0000;
}
.i-recruitment-caption {
	font-size: 12px;
	line-height: 18px;
	font-weight: 300;
	color: #555555;
}
