.i-mb-20 {
	margin-bottom: 20px;
}

.i-mr-10 {
	margin-right: 10px;
}

.i-ml-10 {
	margin-left: 10px;
}

.i-ml-16 {
	margin-left: 16px;
}

.i-p0 {
	padding-right: 0 !important;
	padding-left: 0 !important;
}
