.l-job-listArea {

	+ .l-common-pagerArea {
		margin-top: 30px;
	}
}

.i-job-item {
	position: relative;
	border: 1px solid #AECAE6;
	box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	padding-top: 17px;
	padding-right: 22px;
	padding-bottom: 22px;
	padding-left: 21px;
	margin-top: 30px;

	&:first-child {
		margin-top: 0;
	}

	@media(max-width:767px) {
		padding: 15px;
		margin-top: 0;
		margin-bottom: 18px;
	}

	&__link {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
	}
}
.i-job-item-inner {
	display: flex;
	@media(max-width:767px) {
		position: relative;
		flex-direction: column;
	}
}
.i-job-img {
	display: block;
	width: 100%;
	max-width: 254px;
	@media(max-width:767px) {
		max-width: calc(105 / 375 * 100vw);
		margin-bottom: 12px;
	}
	img {
		display: block;
		width: 100%;
	}
}
.i-job-content {
	display: block;
	width: 100%;
	padding-left: 21px;
	@media(max-width:767px) {
		padding-left: 0;
	}
}
.i-job-header {
	@media(max-width:767px) {
		display: block;
		width: 100%;
		max-width: calc(205 / 375 * 100vw);
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;

	}
}
.i-job-suptitle {
	font-size: 12px;
	line-height: 18px;
	font-weight: 300;
	@media(max-width:767px) {
		font-size: 10px;
		line-height: 15px;
	}
}
.i-job-title {
	font-size: 20px;
	line-height: 28px;
	font-weight: 600;
	text-decoration-line: underline;
	color: #1E85A9;
	margin-bottom: 10px;
	@media(max-width:767px) {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 0;
	}
}
.i-job-categories {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 10px;
	@media(max-width:767px) {
		margin-bottom: 8px;
	}
}
.i-job-category {
	display: block;
	margin-right: 4px;
	border: 1px solid #888888;
	border-radius: 3px;
	font-size: 11px;
	line-height: 16px;
	font-weight: 300;
	padding: 0 4px;
	color: #888888;
	@media(max-width:767px) {
		font-size: 9px;
		line-height: 14px;
	}
}
.i-job-category--blue {
	border-color:$color_blue1;
	color: $color_blue1;
}
.i-job-list {
}
.i-job-list-item {
	position: relative;
	font-weight: 300;
	font-size: 14px;
	line-height: 15px;
	margin-bottom: 5px;
	padding-left: 20px;
	@media(max-width:767px) {
		font-size: 12px;
		margin-bottom: 3px;
	}
	p {
		margin: 0;
	}
}
.i-job-list-icon {
	display: block;
	position: absolute;
	left: 0;
	top: 0;

	&.i-marketing {
		width: 12px;
		left: 1px;
	}

	&.i-price {
		width: 10px;
		left: 2px;
	}

	&.i-time {
		width: 14px;
	}

	&.i-address {
		width: 12px;
		left: .4px;
	}

	img {
		display: block;
		width: 100%;
	}
	svg,path {
		display: block;
		width: 100%;
		height: auto;
	}
}
.i-job-btns {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 12px;
}
.i-job-btn {
	z-index: 3;
	margin-right: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 3px 9px;
	min-height: 40px;
	max-width: 156px;
	width: 100%;
	border: 0;
	border-radius: 100px;
	background-color: #fff;

	position: relative;
	text-align: center;

	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #fff;

	transition: 0.2s ease;
	// defaults
	cursor: pointer;
	outline: none;
	&:last-child {
		margin-right: 0;
	}

	@media(max-width:767px) {
		margin-right: 10px;
	}
}
.i-job-btn-static {
	z-index: 3;
	margin-right: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 3px 9px;
	min-height: 40px;
	max-width: 156px;
	width: 100%;
	border: 0;
	border-radius: 100px;
	background-color: #fff;

	position: relative;
	text-align: center;

	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #fff;

	// defaults
	cursor: pointer;
	outline: none;
	&:last-child {
		margin-right: 0;
	}

	@media(max-width:767px) {
		margin-right: 10px;
	}
}
.i-job-btn--big {
	max-width: 210px;
	font-size: 14px;
	line-height: 21px;
}
.i-job-btn--yellow {
	color: #F7A300;
	border: 2px solid #F7A300;
	box-shadow: 0px 2px 0px #F6A300;
	.i-job-btn-inner {
		display: inline-block;
		position: relative;
		padding-left: 20px;
		&:before {
			content: "";
			display: block;
			width: 17px;
			height: 16px;
			background-image: url("../img/star.svg");
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: cover;
			position: absolute;
			top: 50%;
			left: 0;
			z-index: 1;
			transform: translateY(-50%);
		}
	}
}
.i-job-btn--small {
	display: block;
	width: 100%;
	font-size: 10px;
	line-height: 15px;
}
.i-job-btn--blue {
	background-color: #1E85A9;
	box-shadow: 0px 4px 0px #136A88;
}

.i-job-btn--blue.i-job-btn--outline-none {
	background-color: #E0E0E0;
	border: 0;
	box-shadow: none;
}

.i-job-btn--arrow {
	&:after {
		content: "";
		width: 7px;
		height: 12px;
		background-image: url("../img/arrow-right.svg");
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
		position: absolute;
		top: 50%;
		right: 12px;
		z-index: 1;
		transform: translateY(-50%);
	}
}

.i-job-btn--gray {
	color: #BEBEBE;
	border: 2px solid #BEBEBE;
	box-shadow: 0 2px 0 #BEBEBE;
	line-height: 15px;
	.i-job-btn-inner {
		&:before {
			background-image: url("../img/star-gray.svg");
		}
	}
}
.i-job-banner {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 100px;
	min-height: 30px;
	width: 100%;
	font-size: 16px;
	line-height: 18px;
	font-weight: 600;
	color: #FFFFFF;
	background: #848484;
	border-radius: 3px 0px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	@media(max-width:767px) {
		font-size: 12px;
		max-width: 74px;
		min-height: 21px;
	}
}
.i-job-aside-item {
	padding-top: 15px;
	padding-bottom: 18px;
	border-bottom: 1px solid #AECAE6;
	@media(max-width: 767px) {
		border: 1px solid #AECAE6;
		box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.15);
		border-radius: 4px;
		padding: 15px;
		margin-bottom: 15px;
	}

	p {
		max-width: 282px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
.i-job-aside-header {
	display: flex;
	align-items: flex-start;
	margin-bottom: 10px;
}
.i-job-aside-img {
	display: block;
	width: 100%;
	max-width: 105px;
	img {
		display: block;
		width: 100%;
	}
}
.i-job-aside-header-content {
	display: block;
	width: 100%;
	padding-left: 10px;
}
.i-job-aside-suptitle {
	font-size: 10px;
	line-height: 15px;
	font-weight: 300;
	margin-bottom: 6px;
	color: #555555;
}
.i-job-aside-title {
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	text-decoration-line: underline;
	color: #1E85A9;
	@media(max-width: 767px) {
		font-size: 16px;
	}
}
.i-job-aside-content {
	.i-job-list-item {
		font-size: 12px;
		line-height: 15px;
		color: #555555;
		padding-left: 15px;

		.i-job-list-icon {

			&.i-marketing {
				width: 11px;
			}

			&.i-price {
				width: 8px;
			}

			&.i-time {
				width: 12px;
			}

			&.i-address {
				width: 10px;
			}
		}
	}
}
.i-job-aside-btns {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 12px;
	.i-job-btn {
		margin-right: 10px;
		font-size: 14px;
		line-height: 21px;
		max-width: 130px;
		min-height: 35px;
		@media(max-width: 767px) {
			font-size: 16px;
			line-height: 24px;
			max-width: 156px;
			min-height: 42px;
		}
	}
}

.m-common-pagerBtn_sp {
	border-radius: 100px;
}

.l-common-sideTitle {
	@include media ('<768px') {
		margin-bottom: 20px;
	}
}

.l-job-remoteTitleArea {
	background: #888;
	color: #fff;
	text-align: center;
	padding: 10px;
	font-size: 11px;
	font-weight: normal;
	margin: 0;
}

.l-job-remoteTitle {
	font-weight: bold;
	font-size: 14px;
}

@media (min-width: 768px) {
	.l-job-remoteTitleArea {
		font-size: 12px;
		margin: 0;
	}

	.l-job-remoteTitle {
		font-size: 16px;
	}
}
