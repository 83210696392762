.i-nav {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: rgba(0, 0, 0, 0.7);
	padding-left: 75px;
	z-index: 100;
	transition: .2s ease;
	opacity: 0;
	visibility: hidden;

	&.open {
		opacity: 1;
		visibility: visible;

		.i-nav__body {
			transform: translateX(0);
		}
	}

	&__close {
		position: absolute;
		top: 13px;
		left: 38px;
		width: 24px;
		height: 24px;
		border: none;
		padding: 0;
		margin: 0;
		background-color: transparent;
		overflow: hidden;

		&:before, &:after {
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 30px;
			height: 4px;
			background-color: #ffffff;
		}

		&:before {
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&:after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}

	&__body {
		height: 100%;
		background-color: #ffffff;
		transform: translateX(101%);
		transition: .3s ease;
		overflow-y: auto;
	}

	&__btn-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 10px;
		padding: 10px 15px;
	}

	&__title {
		padding: 7px 15px;
		background-color: #EFEFEF;

		.i-inner-title {
			font-weight: 700;
			font-size: 11px;
			line-height: 16px;
			color: #888888;
		}
	}

	&__list {

		&:last-child {
			.i-nav__item {
				&:last-child {
					border-bottom: 1px solid #EFEFEF;
				}
			}
		}
	}

	&__item {
		border-bottom: 1px solid #EFEFEF;

		&:last-child {
			border-bottom: none;
		}
	}

	&__link {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 15px 15px 15px 45px;
		column-gap: 13px;
		min-height: 50px;
	}

	&__link-icon-wrap {
		display: block;
		position: absolute;
		width: 25px;
		left: 15px;
		top: 50%;
		transform: translateY(-50%);
	}

	&__link-icon {
		display: block;
		width: max-content;
		//height: auto;
		margin-left: auto;
		margin-right: auto;

		svg,path {
			display: block;
			width: auto;
			height: auto;
		}
	}

	&__link-text {
		display: block;
		font-size: 15px;
		line-height: 17px;
		color: #555555;
	}

	&__link-alert {
		display: block;
		margin-left: auto;
		background-color: #FFFFFF;
		box-sizing: border-box;
		border-radius: 100px;
		font-size: 13px;
		line-height: 14px;
		text-align: center;
		padding: 2px 8px;
		min-width: 35px;

		&.i-message {
			border: 1px solid #FF594F;
			color: #FF594F;
		}

		&.i-save {
			border: 1px solid #F6A300;
			color: #F6A300;
		}
	}

	&__link-arrow {
		display: block;
		width: 15px;
		height: 15px;

		svg,path {
			display: block;
			width: 100%;
			height: 100%;
		}
	}
}
