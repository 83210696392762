@charset "utf-8";

// ▼Sass設定

@import "_variables.scss";

/***************************************
 マイページ共通
***************************************/

.l-mypage-l{
  width: 210px;
  float: left;
}

.l-mypage-r{
  width: 810px;
  float: right;
}

.l-mypage-menu_pc{
  margin: 0 0 20px;
  font-size: 13.5px;
  li{
    background: $color_base;
    margin: 0 0 3px;
    @include common_Height(62px);
    text-indent: 8px;
    color: #ffffff;
    position: relative;
    overflow: hidden;
    a{
      display: block;
      @include common_Height(62px);
      color:$color_gray1;
      background: #DADADA;
    }
    .l-mypage-menuJob_pc{
      display: block;
      @include common_Height(62px);
      color: #ffffff;
      background: $color_blue1;
    }
    .l-mypage-menuScout_pc{
      display: block;
      height: 63px;
      line-height: 1.4;
      color: #ffffff;
      background: #448737;
      text-indent: 0;
      padding: 22px 8px 0;
    }
    .l-mypage-menuScoutmypage_pc{
      display: block;
      height: 62px;
      line-height: 1.4;
      color: #ffffff;
      background: #448737;
      text-indent: 0;
      padding: 14px 8px 0;
    }
  }
}

.l-mypage-menuJob_pc{
  li{
    a{
      display: block;
      @include common_Height(62px);
      color: #ffffff;
      background: $color_blue1;
    }
  }
}

.l-mypage-baseArea{
  margin: 0 0 40px;
  overflow: hidden;
}

.m-mypage-menu_ico{
  display: inline-block;
  background: $color_red1;
  @include common_Height(22px);
  font-size: 12px;
  border-radius: 11px;
  color: #ffffff;
  text-align: center;
  text-indent: 0;
  min-width: 14px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 8px;
  margin: auto;
  padding: 0 4px;
  box-sizing: content-box;
  -webkit-text-size-adjust: auto;
}

.l-mypage-alert{
  border: solid 1px $color_gray3;
  padding: 10px;
  margin: 0 0 20px;
  ul{
    list-style: disc outside;
    line-height: 1.5;
    margin: 0 0 0 15px;
    li{
      margin: 5px 0;
      font-size: 12px;
    }
  }
}

.l-mypage-formBg{
  background: $color_gray4;
  border-radius: 4px;
  padding: 0 0 30px;
  margin: 0 0 15px;
  overflow: hidden;
  h2{
    font-size: 16px;
    margin: 20px 0 10px;
    display: inline-block;
    line-height: 1.4;
    span{
      padding: 0 10px 0 0;
    }
  }
  dl{
    margin: 2px 0;
    padding: 13px 15px;
    background: $color_gray4;
    overflow: hidden;
    line-height: 1.6;
    dt{
      display: block;
      float: left;
      font-weight: bold;
      width: 200px;
      margin: 0 3px 0 0;
      vertical-align: top;
    }
    dd{
      display: block;
      float: left;
      padding: 0;
      margin: 0;
    }
  }
}

.l-mypage-baseBox{
  width: 92%;
  margin: 0 auto;
  p{
    padding: 15px 0 0;
    line-height: 1.4;
  }
  dl{
    background: #ffffff;
    margin: 4px 0;
    dd{
      width: 500px;
      word-wrap: break-word;
      line-height: 1.4;
    }
  }
  p{
    margin: 0 0 10px;
    line-height: 1.4;
  }
}

.m-mypage-btn_gray{
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  vertical-align: top;
  @include common_Height(27px);
  background: $color_gray1;
  border: none;
  font-size: 13px;
  padding: 0 13px;
  font-weight: normal;
}

.m-mypage-btn_red{
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  vertical-align: top;
  @include common_Height(27px);
  background: $color_red1;
  border: none;
  font-size: 13px;
  padding: 0 10px;
  font-weight: normal;
}

input.m-mypage-btn_arrival{
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  vertical-align: top;
  @include common_Height(30px);
  background: $color_red1;
  border: none;
  border-bottom: solid 2px #C54B38;
  font-size: 13px;
  padding: 0 10px;
  font-weight: normal;
  cursor: pointer;
  width: 110px;
  &:hover{
    @include common_Alpha;
  }
}

input.m-mypage-btn_arrival_date{
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  vertical-align: top;
  @include common_Height(30px);
  background: $color_green1;
  border: none;
  font-size: 13px;
  padding: 0;
  font-weight: normal;
  cursor: pointer;
  width: 165px;
  &:hover{
    @include common_Alpha;
  }
}

input.m-mypage-btn_arrival_confirm{
  display: inline-block;
  border-radius: 3px;
  border: solid 1px #EAABA2;
  color: #DA705F;
  text-align: center;
  vertical-align: top;
  @include common_Height(30px);
  background: #FFDAD4;
  font-size: 14px;
  padding: 0 8px;
  font-weight: normal;
  &:hover{
    opacity: 1;
  }
  &:placeholder-shown {
      color: #DA705F;
  }
  &::-webkit-input-placeholder {
      color: #DA705F;
  }
  &:-moz-placeholder {
      color: #DA705F;
      opacity: 1;
  }
  &::-moz-placeholder {
      color: #DA705F;
      opacity: 1;
  }
  &:-ms-input-placeholder {
      color: #DA705F;
  }
}

.picker__box__description {
  padding: 10px 10px;
  font-size: 16px;
  line-height: 1.5;
  > span {
    font-weight: bold;
    color: #DA705F;
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  input.m-mypage-btn_arrival_date{
    @include common_Height(36px);
  }

  input.m-mypage-btn_arrival_confirm{
    @include common_Height(36px);
  }

}

.m-mypage-btn_withdrawal{
  font-size: 11px;
  border-bottom: dotted 1px;
  padding: 0 0 2px;
}

.l-mypage-notesBox{
  width: 100%;
  box-sizing: border-box!important;
  padding: 30px;
  margin: 0 auto 20px;
  text-align: left;
  overflow: hidden;
  background: $color_gray4;
  border-radius: 4px;
  border: solid 1px #DEDEDE;
  line-height: 1.4;
  .message_main_comment{
    font-size:14px;
  }
  .message_sub_comment{
    font-size:12px;
  }
}

.l-mypage-header{
  overflow: hidden;
  margin: 0 0 20px;
}

.l-mypage-listTab{
  overflow: hidden;
  li{
    float: left;
    text-align: center;
    font-size: 15px;
    img{
      width: 15px;
      display: block;
      margin: 0 auto;
    }
    span{
      display: block;
      @include common_Height(46px);
      background: $color_base;
      color: #ffffff;
      padding: 0 30px;
    }
    a{
      display: block;
      @include common_Height(46px);
      background: #C3C3C3;
      color: $color_gray1;
      padding: 0 30px;
    }
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-mypage-l{
    width: $width_sp;
    margin: 0 auto;
    float: none;
  }

  .l-mypage-r{
    width: $width_sp;
    margin: 0 auto;
    float: none;
  }

  .l-mypage-menu_sp{
    width: $width_all;
    position: absolute;
    margin: 0 0 10px;
    z-index: 2;
    ul{
      overflow: hidden;
      background: #DADADA;
      li{
        background: $color_base;
        box-sizing: border-box;
        position: relative;
        font-size: 10.5px;
        width: $width_all / 2;
        float: left;
        border-bottom: solid 1px #ffffff;
        border-right: solid 1px #ffffff;
        &:nth-child(even){
          border-right: none;
        }&:last-child{
        }
        a{
          display: block;
          padding: 0 5px;
          @include common_Height(45px);
          color:$color_gray1;
          background: #DADADA;
        }
      }
    }
    .l-mypage-menu_sp_scout{
      a{
        color: #ffffff;
        background: #609e54;
      }
    }
  }

  .m-mypage-menuBtn_sp{
    // background: $color_base url(../img/common_arrow_bg.png) no-repeat 10px center;
    background-size: auto 20%;
    display: block;
    @include common_Height(44px);
    color: #ffffff;
    font-size: 15px;
    padding: 0 15px;
    text-indent: 20px;
    &:hover{
      @include common_Alpha($value: 1.0);
    }
  }

  .l-mypage-formBg{
    padding: 0 0 15px;
    dl{
      padding: 10px;
      dt{
        width: 160px;
        font-size: 13px;
      }
      dd{
        width: 60%;
      }
    }
    .l-mypage-termsLink{
      margin: 15px 0 25px;
    }
  }

  .l-mypage-listTab{
    li{
      img{
        width: 10px;
      }
      span{
        @include common_Height(40px);
        padding: 0 15px;
      }
      a{
        @include common_Height(40px);
        padding: 0 15px;
      }
    }
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-mypage-alert{
    margin: 0 0 15px;
  }

  .l-mypage-baseArea{
    margin: 0 0 20px;
  }

  .l-mypage-formBg{
    dl{
      padding: 10px 15px;
      dt{
        margin: 0 0 3px;
        width: auto;
      }
      dd{
        width: 100%;
      }
    }
    h2{
      font-size: 15px;
      margin: 15px 0 8px;
    }
  }

  .l-mypage-baseBox{
    margin: 0 auto;
    dl{
      background: #ffffff;
      margin: 4px 0;
    }
    p{
      padding: 10px 0 0;
      font-size: 12px;
    }
  }

  .l-mypage-header{
    margin: 0 0 15px;
  }

  .l-mypage-listTab{
    li{
      font-size: 14px;
    }
  }

  .l-mypage-notesBox{
    .message_main_comment{
      font-size:12px;
    }
    .message_sub_comment{
      font-size:10px;
    }
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .l-mypage-header{
    margin: 0 0 10px;
  }

  .l-mypage-listTab{
    li{
      font-size: 11px;
      span{
        @include common_Height(38px);
        padding: 0 10px;
      }
      a{
        @include common_Height(38px);
        padding: 0 12px;
      }
    }
  }

  .m-mypage-menu_ico{
    @include common_Height(18px);
    font-size: 10px;
    border-radius: 9px;
    min-width: 10px;
  }

}


/***************************************
 マイページ（メッセージ）
***************************************/
.l-message-thread-timestamp-box{
  text-align: right;
}

.l-message-thread-timestamp{
  font-size: 10px;
  font-weight: normal !important;
}

.l-contents-message{
  padding: 0;
}

.l-message-baseArea{
  margin: 0;
  overflow: hidden;
}

.l-mypage-message_l{
  width: 280px;
  height: 562px;
  float: left;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 0 6px 0 0;
}

.l-mypage-message_r{
  width:  520px;
  float: right;
  box-sizing: border-box;

  * {
    box-sizing: content-box;
    -webkit-text-size-adjust: auto;
  }
}

.l-message_topbtnArea{
  text-align: center;
  // display: flex;
  padding: 10px 15px;
  margin: 0 0 10px;
  background: $color_gray4;
  // flex-direction: row;
  overflow: hidden;
  .m-message-employment_btn{
    order: 1;
  }
  .m-message-notAdopted_btn{
    order: 2;
  }
  .m-message-definition{
    order: 3;
    position: relative;
    left: 190px;
    top: 4px;
  }
}

.l-mypage-searchBox{
  margin: 0 0 15px;
  input[type="text"]{
    @include common_Height(42px);
    border: solid 1px $color_gray3;
    border-right: none;
    border-radius: 3px 0 0 3px;
    box-sizing: border-box;
    vertical-align: top;
    padding: 0 8px;
    display: inline-block;
    width: 206px;
    font-size: 14px;
  }
  input[type="submit"]{
    border: 0px;
    // background: url(../img/common_icon_search.png) $color_gray1 no-repeat center;
    background-size: 20px auto;
    @include common_Height(42px);
    width: 50px;
    border-radius: 0 3px 3px 0;
    display: inline-block;
  }
}

.l-mypage-message_list{
  display: block;
  width: 260px;
  border-top: dotted 1px $color_gray3;
  position: relative;
  padding: 10px 13px;
  box-sizing: border-box;
  &:last-child{
    border-bottom: dotted 1px $color_gray3;
  }
  article{
    overflow: hidden;
    &:hover{
      @include common_Alpha;
    }
  }
}

.l-mypage-message_listImg{
  width: 50px;
  height: 50px;
  background: #ffffff;
  position: relative;
  float: left;
  margin: 0 8px 8px 0;
  img{
    display: block;
    width: 90%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.l-mypage-message_listName{
  float: left;
  width: 174px;
  span{
    font-size: 11px;
    color: $color_gray2;
    display: block;
    line-height: 1;
    margin: 0 0 5px;
  }
  h3{
    line-height: 1.5;
    color: $color_gray1;
    font-size: 13px;
    margin: 0 0 3px;
  }
  p{
    margin: 0;
    line-height: 1.4;
    color: $color_gray2;
    font-size: 10px;
  }
}

.l-mypage-message_chatArea{
  overflow-y: scroll;
  box-sizing: border-box;
  margin: 0 0 8px;
  padding: 0 7px 0 0;
  height: 400px;
}

.l-mypage-message_chat{
  position: relative;
  margin: 0 0 10px;
  width: 84%;
  // background: url(../img/message_balloon_l.png) no-repeat 48px bottom;
  background-size: 18px auto;
  section{
    margin: 0 0 0 65px;
    background: $color_gray4;
    padding: 12px;
    border-radius: 6px;
    word-wrap: break-word;
  }
}

.l-mypage-message_chat_self{
  position: relative;
  padding: 0 18px 0 0;
  margin: 0 0 10px 0;
  // background: url(../img/message_balloon_r.png) no-repeat right bottom;
  background-size: 18px auto;
  overflow: hidden;
  section{
    float: right;
    background: $color_gray4;
    overflow: hidden;
    padding: 12px;
    border-radius: 6px;
    width: 70%;
  }
}

.l-mypage-message_chatImg{
  width: 45px;
  height: 45px;
  background: #ffffff;
  position: absolute;
  left: 0;
  bottom: 0;
  img{
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.l-mypage-message_chatBox{
  word-wrap: break-word;
  small{
    margin: 0 0 0 10px;
  }
  span{
    font-size: 11px;
    color: $color_gray2;
    display: block;
    line-height: 1;
    margin: 5px 0 0;
    text-align: right;
  }
  h3{
    line-height: 1.3;
    color: $color_gray1;
    font-size: 12px;
    margin: 0 0 8px;
  }
  p{
    margin: 0;
    line-height: 1.5;
    color: $color_gray1;
    font-size: 14px;
  }
}

.l-mypage-message_textArea_pc{
  text-align: center;
  padding: 15px 0;
  background: $color_gray4;
  overflow: hidden;
  textarea{
    width: 95%;
    height: 80px;
    padding: 5px;
    resize: none;
    outline:none;
    margin: 0 0 5px;
    &:placeholder-shown {
        color: #555;
    }
    &::-webkit-input-placeholder {
        color:#555;
    }
    &:-moz-placeholder {
        color:#555;
        opacity: 1;
    }
    &::-moz-placeholder {
        color:#555;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color:#555;
    }
  }
  .m-mypage-employment_btn{
    float: right;
  }
  .m-mypage-notAdopted_btn{
    float: right;
  }
  .m-mypage-btn_arrival_date{
    float: right;
  }
  .m-mypage-btn_arrival_confirm{
    float: right;
  }
  .m-mypage-arrivalBox{
    float: right;
  }
}

.l-mypage-btnBox{
  width: 95%;
  margin: 15px auto 0;
  text-align: center;
  position: relative;
  p{
    font-weight: bold;
    font-size: 12px;
    color: $color_red1;
    margin: 0 auto 10px;
    line-height: 1.4;
  }
}

@mixin message_btn {
  display: inline-block;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  @include common_Height(28px);
  font-size: 14px;
}

.m-mypage-message_btn{
  @include message_btn;
  background: $color_green1;
  border-bottom: solid 2px $color_green2;
  float: left;
  width: 190px;
  text-align: center;
}

.m-mypage-employment_btn{
  @include message_btn;
  background: $color_red1;
  border-bottom: solid 2px $color_red2;
  margin: 0 0 0 5px;
  width: 200px;
  text-align: center;
}

.m-mypage-notAdopted_btn{
  @include message_btn;
  background: $color_blue1;
  border-bottom: solid 2px #136A88;
  margin: 0 0 0 5px;
  width: 60px;
  text-align: center;
}

.m-mypage-list_btn{
  display: inline-block;
  padding: 0 10px;
  background: $color_gray1;
  color: #ffffff;
  border: none;
  border-bottom: solid 2px #6F6F6F;
  border-radius: 3px;
  @include common_Height(28px);
  vertical-align: top;
  float: right;
}

.m-mypage-btn_normal{
  display: inline-block;
  background: #E67064;
  border: none;
  border-bottom: solid 2px #CA5549;
  color: #ffffff;
  text-align: center;
  border-radius: 3px;
  padding: 0 15px;
  @include common_Height(30px);
}

.m-mypage-btn_send{
  display: inline-block;
  background: $color_green1;
  border: none;
  border-bottom: solid 2px $color_green2;
  color: #ffffff;
  text-align: center;
  border-radius: 3px;
  padding: 0 15px;
  @include common_Height(30px);
}

.m-form-btn_preview{
  display: inline-block;
  background: $color_gray1;
  color: #ffffff;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
  border: none;
  padding: 0 20px;
  @include common_Height(47px);
  position: absolute;
  top: 0;
  right: 0;
}

.m-mypage-btn_facebook{
  display: inline-block;
  background: #AFAFAF;
  border: none;
  border-bottom: solid 3px #9A9A9A;
  color: #464646;
  border-radius: 3px;
  padding: 0 15px;
  @include common_Height(30px);
}

.l-mypage-message-ico_unreadS{
  font-size: 11px;
  position: absolute;
  top: 6px;
  left: 0;
  color: $color_red1;
}

/* 未読時 */
.l-mypage-message_unread{
  span{
    color: $color_base;
    font-weight: bold;
  }
  h3{
    color: $color_base;
  }
  p{
    color: $color_base;
    font-weight: bold;
  }
  .l-mypage-message-ico_unreadS{
    color: $color_red1;
  }
}

/* 選択時 */
.l-mypage-message_selected{
  background: $color_gray1;
  span{
    color: #ffffff;
  }
  h3{
    color: #ffffff;
  }
  p{
    color: #ffffff;
  }
  .l-mypage-message-ico_unreadS{
    color: $color_red1;
  }
}

.l-mypage-message_rejected{
  color: $color_gray1;
  background: $color_light_gray1;
  span{
    color: $color_gray1;
  }
  h3{
    color: $color_gray1;
  }
  p{
    color: $color_gray1;
  }
}

.l-mypage-message_topbtnArea{
  text-align: left;
  display: flex;
  padding: 10px 15px;
  margin: 0 0 10px;
  background: $color_gray4;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  .m-mypage-employment_btn{
    order: 2;
  }
  .m-mypage-notAdopted_btn{
    order: 1;
  }
}

.m-mypage-arrivalBox{
  display: inline-block;
  vertical-align: top;
  span{
    position: relative;
    top: 3px;
    font-size: 13px;
    font-weight: bold;
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-mypage-message_l{
    width: $width_all;
    overflow-y: auto;
    height: auto;
    float: none;
    margin: 0;
    padding: 0;
  }

  .l-mypage-message_r{
    width: $width_all;
    background: #ffffff;
    padding: 0 0 200px;
    max-height: none;
    float: none;

    * {
      box-sizing: content-box;
      -webkit-text-size-adjust: auto;
    }
  }

  .l-mypage-messageTitle_sp{
    @include common_Height(40px);
    background: $color_base;
    color: #ffffff;
    text-align: center;
    font-size: 13px;
    width: 100%;
    position: absolute;
    margin: 0 0 10px;
    z-index: 2;
    img{
      position: absolute;
      top: 0;
      bottom: 0;
      left: -10px;
      margin: auto 0;
      height: 50%;
      padding: 10px 15px 10px 0;
      -webkit-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      -moz-transform: scaleX(-1);
      transform: scaleX(-1);
      filter: FlipH;
      -ms-filter: "FlipH";
    }

    * {
      box-sizing: content-box;
      -webkit-text-size-adjust: auto;
    }
  }

  .l-mypage-searchBox{
    text-align: center;
    input[type="text"]{
      width: 400px;
    }
  }

  .l-message-baseArea {
    overflow: visible;
  }

  .l-contents-message {
    margin: 50px 0 0;
  }

  .l-mypage-message_list{
    width: $width_all;
    overflow: none;
    max-height: none;
  }

  .l-mypage-message_listImg{
    width: 60px;
    height: 60px;
    margin: 0 15px 0 0;
  }

  .l-mypage-message_listName{
    width: 85%;
    span{
      font-size: 14px;
    }
    h3{
      font-size: 18px;
    }
    p{
      font-size: 15px;
    }
  }

  .l-mypage-message_chatArea{
    overflow-y: hidden;
    height: auto;
    padding: 0 0 35px;
  }

  .l-mypage-message_chat{
    // background: url(../img/message_balloon_l.png) no-repeat 60px bottom;
    background-size: 16px auto;
    section{
      margin: 0 0 0 76px;
    }
  }

  .l-mypage-message_chat_self{
    // background: url(../img/message_balloon_r.png) no-repeat right bottom;
    background-size: 16px auto;
    padding: 0 16px 0 0;
    margin: 0 0 10px 0;
    section{
      padding: 12px;
    }
  }

  .l-mypage-message_chatImg{
    width: 50px;
    height: 50px;
  }

  .l-mypage-message_chatBox{
    span{
      font-size: 11px;
    }
    h3{
      font-size: 13px;
    }
    p{
      font-size: 14px;
    }
  }

  .l-mypage-message_textArea_sp{
    background: $color_gray4;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding: 12px 0;
    border-top: solid 2px #ffffff;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    textarea{
      font-size: 12px;
      width: 95%;
      height: 90px;
      padding: 5px;
      resize: none;
      outline:none;
      &:placeholder-shown {
        color: #555;
      }
      &::-webkit-input-placeholder {
          color:#555;
      }
      &:-moz-placeholder {
          color:#555;
          opacity: 1;
      }
      &::-moz-placeholder {
          color:#555;
          opacity: 1;
      }
      &:-ms-input-placeholder {
          color:#555;
      }
    }
  }

  .l-mypage-message_textAreaFixing{
    background: $color_gray4;
    width: 100%;
    margin: 0 auto;
    padding: 12px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    textarea{
      font-size: 12px;
      width: 95%;
      height: 90px;
      padding: 5px;
      resize: none;
      outline:none;
    }
  }

  .m-form-btn_preview{
    @include common_Height(45px);
    font-size: 14px;
    padding: 0 20px;
    position: relative;
    top: 0;
    right: 0;
  }

  .m-mypage-message_btn{
    float: none;
    width: 61%;
    margin: 5px auto 10px;
    display: block;
    @include common_Height(34px);
    position: relative;
    left: 0;
  }

  .m-mypage-employment_btn{
    float: none;
    width: 40%;
    margin: 0 2px;
    @include common_Height(34px);
  }

  .m-mypage-notAdopted_btn{
    float: none;
    width: 20%;
    margin: 0 2px;
    @include common_Height(34px);
  }

  .firstAttendance-lineFeed{
    width: 100%;
    margin-top: 10px;
  }
}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-mypage-searchBox{
    input[type="text"]{
      width: 260px;
    }
  }

  .l-mypage-message_chat{
    // background: url(../img/message_balloon_l.png) no-repeat 40px bottom;
    background-size: 16px auto;
    width: 85%;
    section{
      margin: 0 0 0 55px;
    }
  }

  .l-mypage-message_chat_self{
    section{
      width: 66%;
    }
  }

  .l-mypage-message_listImg{
    width: 45px;
    height: 45px;
    margin: 0 10px 0 0;
  }

  .l-mypage-message_listName{
    width: 83%;
    span{
      font-size: 11px;
    }
    h3{
      font-size: 14px;
    }
    p{
      font-size: 12px;
    }
  }

  .l-mypage-message_chatImg{
    width: 35px;
    height: 35px;
  }

  .l-mypage-message_chatBox{
    span{
      font-size: 10px;
    }
    h3{
      font-size: 11px;
    }
    p{
      font-size: 12px;
    }
  }

  .l-mypage-btnBox{
    p{
      span:before{
        content: "\A";
        white-space: pre;
      }
    }
  }

  .m-mypage-message_btn{
    width: 95%;
  }

  .m-mypage-employment_btn{
    width: 68%;
  }

  .m-mypage-notAdopted_btn{
    width: 24%;
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .l-mypage-searchBox{
    input[type="text"]{
      width: 220px;
    }
  }

  .l-mypage-message_chat{
    width: 87%;
  }

  .l-mypage-message_chat_self{
    section{
      width: 64%;
    }
  }

  .l-mypage-message_listImg{
    width: 50px;
    height: 50px;
    margin: 0 10px 0 0;
  }

  .l-mypage-message_listName{
    width: 76%;
    span{
      font-size: 11px;
    }
    h3{
      font-size: 14px;
    }
    p{
      font-size: 12px;
    }
  }

  .l-mypage-message_chatImg{
    width: 35px;
    height: 35px;
    margin: 0 15px 0 0;
  }

  .l-mypage-message_chatBox{
    span{
      font-size: 9px;
    }
    h3{
      font-size: 11px;
    }
    p{
      font-size: 12px;
    }
  }

  .l-mypage-message-ico_unreadS{
    font-size: 10px;
  }

}

/***************************************
 マイページ（アカウント設定）
***************************************/

.l-mypage-acountBox{
  width: 92%;
  margin: 0 auto;
  dl{
    margin: 6px 0;
    padding: 13px 15px 18px;
    background: #ffffff;
    overflow: hidden;
    line-height: 1.6;
    dt{
      display: block;
      float: left;
      font-weight: bold;
      width: 140px;
      vertical-align: top;
      margin: 0 10px 0 0;
      padding: 7px 0 0;
    }
    dd{
      display: block;
      float: left;
      padding: 0 0 0 15px;
      margin: 0;
      width: 530px;
      border-left: solid 2px $color_gray3;
    }
  }
}

.l-mypage-acountSubBox{
  margin: 0 0 5px;
}

.m-mypage-acountList{
  display: inline-block;
  margin: 0 10px 0 0;
  padding: 6px 0 0;
  width: 215px;
}

.m-mypage-acountMail{
  display: inline-block;
  font-weight: bold;
  padding: 0 0 8px;
}

.l-mypage-acountBtnBox{
  text-align: center;
  padding: 10px 0 0;
  span{
    display: block;
    color: $color_red1;
    font-weight: bold;
    line-height: 1;
    margin: 0 0 8px;
  }
  small{
    display: block;
    margin: 5px auto 0;
    width: 90%;
    color: $color_gray2;
    line-height: 1.4;
  }
}

.l-mypage-withdrawalBtnBox{
  text-align: right;
  width: 92%;
  margin: 0 auto;
}

.m-mypage-textarea{
  width: 95%;
  height: 100px;
  box-sizing: border-box;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-mypage-acountBox{
    dl{
      margin: 5px auto;
      padding: 10px;
      dt{
        float: none;
        width: $width_all;
        margin: 0 0 15px;
        padding: 5px 0;
        font-size: 14px;
        border-bottom: dotted 1px $color_gray3;
      }
      dd{
        float: none;
        padding: 0;
        margin: 0 auto;
        width: 96%;
        border-left: none;
      }
    }
  }

  .m-mypage-acountList{
    display: block;
    margin: 10px 10px 3px 0;
    width: 215px;
    font-size: 12px;
  }

  .m-mypage-textarea{
    width: 100%;
    height: 100px;
  }

}

/***************************************
 マイページ（お気に入り）
***************************************/

.l-mypage-favorite_jobArea{
  overflow: hidden;
  margin: 0 0 30px;
}

.l-mypage-favorite_jobBox{
  float: left;
  width: $width_all / 2 - 1;
  margin: 0 0 15px;
  padding: 18px 15px 15px;
  overflow: hidden;
  background: $color_gray4;
  border-radius: 4px;
  border: solid 1px #DEDEDE;
  box-sizing: border-box;
  position: relative;
  &:nth-child(even){
    float: right;
  }
  &:hover{
    @include common_Alpha;
  }
  h3{
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
    color: $color_gray1;
    margin: 5px 0;
  }
  h4{
    font-size: 16px;
    line-height: 1.4;
    margin: 3px 0 0;
    color: $color_base;
  }
}

.m-mypage-favorite_img{
  width: 150px;
  height: auto;
  background: #cccccc;
  float: left;
  margin: 0 15px 0 0;
  overflow: hidden;
  img{
    width: 100%;
  }
}
.m-mypage-favorite_title{
  width: 195px;
  float: left;
  color: $color_base;
  margin: -4px 0 0;
}

.m-mypage-favorite_btnClose{
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  @include common_Height(22px);
  width: 22px;
  font-size: 20px;
  background: $color_gray3;
  border: none;
  position: absolute;
  top: 8px;
  right: 8px;
  span{
    position: relative;
    top: -2px;
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-mypage-favorite_jobBox{
    padding: 10px;
    float: none;
    width: $width_all;
    &:nth-child(even){
      float: none;
    }
  }

  .m-mypage-favorite_img{
    width: 120px;
  }

  .m-mypage-favorite_title{
    width: 70%;
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-mypage-favorite_jobArea{
    margin: 0 0 10px;
  }

  .l-mypage-favorite_jobBox{
    margin: 0 0 10px;
    h3{
      font-size: 12px;
    }
    h4{
      font-size: 13px;
    }
  }

  .m-mypage-favorite_img{
    width: 110px;
    margin: 0 10px 0 0;
  }

  .m-mypage-favorite_title{
    width: 62%;
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .l-mypage-favorite_jobBox{
    h3{
      font-size: 11px;
    }
    h4{
      font-size: 12px;
    }
  }

  .m-mypage-favorite_img{
    width: 90px;
  }

  .m-mypage-favorite_title{
    width: 63%;
  }

}

/***************************************
 マイページ（おすすめ求人）
***************************************/

.l-mypage-recommend_jobArea{
  overflow: hidden;
  margin: 0 0 30px;
}

.l-mypage-recommend_jobBox{
  float: left;
  width: $width_all / 2 - 1;
  margin: 0 0 15px;
  padding: 18px 15px 15px;
  overflow: hidden;
  background: $color_gray4;
  border-radius: 4px;
  border: solid 1px #DEDEDE;
  box-sizing: border-box;
  position: relative;
  &:nth-child(even){
    float: right;
  }
  &:hover{
    @include common_Alpha;
  }
  h3{
    font-size: 14px;
    font-weight: normal;
    line-height: 1.4;
    color: $color_gray1;
    margin: 5px 0;
  }
  h4{
    font-size: 16px;
    line-height: 1.4;
    margin: 3px 0 0;
    color: $color_base;
  }
}

.m-mypage-recommend_img{
  width: 150px;
  height: auto;
  background: #cccccc;
  float: left;
  margin: 0 15px 0 0;
  overflow: hidden;
  img{
    width: 100%;
  }
}
.m-mypage-recommend_title{
  width: 195px;
  float: left;
  color: $color_base;
  margin: -4px 0 0;
}

.m-mypage-recommend_btnClose{
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  @include common_Height(22px);
  width: 22px;
  font-size: 20px;
  background: $color_gray3;
  border: none;
  position: absolute;
  top: 8px;
  right: 8px;
  span{
    position: relative;
    top: -2px;
  }
}

.m-mypage-refusal_btnClose{
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  @include common_Height(22px);
  background: $color_gray3;
  border: none;
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 0 5px;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-mypage-recommend_jobBox{
    padding: 10px;
    float: none;
    width: $width_all;
    &:nth-child(even){
      float: none;
    }
  }

  .m-mypage-recommend_img{
    width: 120px;
  }

  .m-mypage-recommend_title{
    width: 70%;
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-mypage-recommend_jobArea{
    margin: 0 0 10px;
  }


  .l-mypage-recommend_jobBox{
    margin: 0 0 10px;
    h3{
      font-size: 12px;
    }
    h4{
      font-size: 13px;
    }
  }

  .m-mypage-recommend_img{
    width: 110px;
    margin: 0 10px 0 0;
  }

  .m-mypage-recommend_title{
    width: 62%;
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .l-mypage-recommend_jobBox{
    h3{
      font-size: 11px;
    }
    h4{
      font-size: 12px;
    }
  }

  .m-mypage-recommend_img{
    width: 90px;
  }

  .m-mypage-favorite_title{
    width: 63%;
  }

}

/***************************************
 マイページ（応募履歴）
***************************************/

.l-mypage-history_jobBox{
  margin: 0 0 15px;
  padding: 18px 15px 15px;
  overflow: hidden;
  background: $color_gray4;
  border-radius: 4px;
  border: solid 1px #DEDEDE;
  box-sizing: border-box;
  position: relative;
  h3{
    font-size: 16px;
    line-height: 1.4;
    color: $color_gray1;
    margin: 8px 0 0;
  }
  h4{
    font-size: 18px;
    line-height: 1.4;
    padding: 8px 0;
    color: $color_base;
  }
}

.m-mypage-history_date{
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  padding: 0 10px 0 0;
}

.l-mypage-history_icoBox{
  display: inline-block;
}

.m-mypage-history_img{
  width: 120px;
  height: auto;
  background: #cccccc;
  float: left;
  margin: 0 15px 15px 0;
  overflow: hidden;
  img{
    width: 100%;
  }
}
.m-mypage-history_title{
  width: 78%;
  float: left;
  color: $color_base;
  margin: -4px 0 0;
}

.l-mypage-history_btnBox{
  position: relative;
  top: 0;
  left: 0;
  padding: 10px 0 0;
  clear: both;
  border-top: solid 1px $color_gray3;
  text-align: center;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-mypage-history_jobBox{
    padding: 10px;
    float: none;
    width: $width_all;
    &:nth-child(even){
      float: none;
    }
    h3{
      font-size: 14px;
    }
    h4{
      font-size: 15px;
      padding: 5px 0;
    }
  }

  .l-mypage-history_icoBox{
    display: block;
  }

  .m-mypage-history_img{
    width: 150px;
    margin: 0 15px 10px 0;
  }

  .m-mypage-history_title{
    width: 68%;
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-mypage-history_jobArea{
    margin: 0 0 20px;
  }

  .l-mypage-history_jobBox{
    margin: 0 0 10px;
    h3{
      font-size: 11px;
    }
    h4{
      font-size: 12px;
      padding: 5px 0 10px;
    }
  }

  .m-mypage-history_img{
    width: 110px;
    margin: 0 10px 0 0;
  }

  .m-mypage-history_title{
    width: 62%;
  }

  .m-mypage-history_date{
    font-size: 12px;
  }

  .l-mypage-history_btnBox{
    a{
      margin: 0 0 8px;
    }
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .l-mypage-history_jobBox{
    h3{
      font-size: 11px;
    }
    h4{
      font-size: 12px;
    }
  }

  .m-mypage-history_img{
    width: 58px;
  }

  .m-mypage-history_date{
    font-size: 10px;
  }

  .l-mypage-history_icoBox{
    span{
      font-size: 9px;
    }
  }

  .m-mypage-history_title{
    width: 75%;
  }

}

/***************************************
 マイページ（推薦状）
***************************************/

.l-mypage-referenceBox{
  width: 92%;
  margin: 0 auto;
  dl{
    background: #ffffff;
    margin: 4px 0;
    text-align: center;
    dt{
      width: 100%;
      margin: 0 0 6px;
      line-height: 1.4;
    }
    dd{
      width: 100%;
    }
  }
  p{
    margin: 0 0 10px;
    padding: 15px 0 0;
    line-height: 1.4;
  }
}

.l-mypage-reference-startBox{
  border: solid 1px $color_gray3;
  border-radius: 6px;
  padding: 30px;
  margin: 40px 0 0;
  h3{
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    margin: 0 0 10px;
    span{
      color: $color_red1;
    }
  }
  p{
    text-align: center;
    font-size: 16px;
    line-height: 1.5;

  }
}

.l-mypage-reference_catch{
  margin: 0 0 20px;
  font-size: 22px;
  line-height: 1.5;
  text-align: center;
  span:before{
    content: "\A";
    white-space: pre;
  }
}

.l-mypage-reference_title{
  border-bottom: solid 1px $color_gray3;
  width: 100%;
  padding: 0 0 5px;
}

.l-mypage-referenceTextArea{
  margin: 20px 0 30px;
  border: solid 1px $color_gray1;
  border-radius: 4px;
  overflow: hidden;
  h2{
    background: $color_gray1;
    color: #ffffff;
    font-size: 16px;
    @include common_Height(42px);
    padding: 0 15px;
    font-weight: normal;
  }
  section{
    overflow: hidden;
    padding: 12px 15px;
    border-bottom: dotted 1px $color_gray3;
    &:last-child{
      border: none;
    }
    h3{
      display: inline-block;
      margin: 4px 10px 3px 0;
      font-size: 14px;
    }
    p{
      margin: 0;
      font-size: 12px;
    }
    span{
      display: inline-block;
      float: right;
      font-size: 11px;
      margin: 6px 0 0;
    }
  }
}

.m-reference-ico_request{
  display: inline-block;
  background: $color_green1;
  @include common_Height(21px);
  font-size: 13px;
  padding: 0 8px;
  border-radius: 3px;
  color: #ffffff;
}

.m-reference-ico_refusal{
  display: inline-block;
  background: $color_gray2;
  @include common_Height(21px);
  font-size: 13px;
  padding: 0 8px;
  border-radius: 3px;
  color: #ffffff;
}

.m-about-btn_red{
  background: $color_red1;
  border: none;
  border-bottom: solid 4px $color_red2;
  display: inline-block;
  border-radius: 5px;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  margin: 0 2px;
  vertical-align: top;
  padding: 15px 20px;
  line-height: 1.4;
  br{
    display: none;
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-mypage-reference-startBox{
    h3{
      font-size: 22px;
      text-align: left;
    }
    p{
      font-size: 14px;
      text-align: left;
    }
    br{
      display: none;
    }
  }

}


/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-mypage-referenceBox{
    p{
      padding: 10px 0 0;
      font-size: 12px;
    }
  }

  .l-mypage-reference-startBox{
    padding: 15px;
    h3{
      font-size: 16px;
      text-align: left;
    }
    p{
      margin: 10px 0;
    }
  }

  .l-mypage-reference_catch{
    margin: 0 5px 15px;
    font-size: 14px;
    text-align: left;
    span:before{
      content: normal;
    }
  }

  .l-mypage-referenceTextArea{
    margin: 10px 0 20px;
    h2{
      font-weight: normal;
      font-size: 15px;
      @include common_Height(36px);
      padding: 0 10px;
    }
    section{
      padding: 5px 10px;
      span{
        margin: 0;
        display: block;
        width: 100%;
        text-align: right;
      }
    }
  }

  .m-about-btn_red{
    font-size: 16px;
    padding: 10px 20px;
    br{
      display: block;
    }
  }

  .m-reference-ico_request{
    @include common_Height(18px);
    font-size: 11px;
    padding: 0 6px;
    position: relative;
    top: -1px;
  }

  .m-reference-ico_refusal{
    @include common_Height(18px);
    font-size: 11px;
    padding: 0 6px;
    position: relative;
    top: -1px;
  }

}
