.i-top-wrapper {
	padding-bottom: 440px;

	@include media ('<768px') {
		padding-bottom: 400px;
	}
}

.i-top-visual {
	position: relative;
	padding-top: 30px;
	padding-bottom: 50px;
	background-image: url('../img/img_kv.jpg');
	background-size: cover;
	//background-position: center;
	background-position: 60% -150px;
	background-repeat: no-repeat;
	z-index: 1;

	@include media ('<768px') {
		padding-top: 15px;
		padding-bottom: 30px;
		background-size: 127%;
		background-position: 55% -26px;
	}

	&:after {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, .42);
		z-index: -1;
	}

	&__container {
		display: block;
		width: auto;
		max-width: 900px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 15px;
		padding-right: 15px;
	}

	.page-title {
		font-weight: 700;
		font-size: 41px;
		line-height: 55px;
		color: #ffffff;
		text-align: center;
		margin: 0;
		letter-spacing: 0.07em;

		@include media ('<768px') {
			font-size: 23px;
			line-height: 31px;
			letter-spacing: 0.04em;
		}

		&__wrap {
			position: relative;
			width: max-content;
			max-width: 100%;
			margin-left: auto;
			margin-right: auto;
			padding: 50px 50px 0;

			@include media ('<768px') {
				padding: 35px 28px 0;
			}

			&:before {
				content: attr(data-industry);
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				top: 0;
				left: 0;
				width: 80px;
				height: 55px;
				font-weight: 700;
				font-size: 16px;
				line-height: 24px;
				color: #3A3A3A;
				background-image: url('../img/top-visual/icon.svg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
				padding: 0 5px 10px;

				@include media ('<768px') {
					width: 50px;
					height: 35px;
					font-size: 10px;
					line-height: 15px;
					padding-bottom: 7px;
				}
			}
		}
	}

	&__list {
		display: flex;
		justify-content: center;
		margin-top: 55px;
		margin-left: -8px;
		margin-right: -8px;

		@include media ('<768px') {
			margin-top: 45px;
			margin-left: -4px;
			margin-right: -4px;
		}
	}

	&__item {
		padding-left: 8px;
		padding-right: 8px;

		@include media ('<768px') {
			padding-left: 4px;
			padding-right: 4px;
		}
	}

	&__item-inner {
		display: flex;
		align-items: center;
		justify-content: space-around;
		flex-direction: column;
		width: 100px;
		height: 100%;
		background-color: #ffffff;
		border-radius: 10px;
		padding: 15px 15px 10px;
		box-shadow: 0 4px 0 #C7C7C7;
		transition: .2s ease;

		@include media ('<768px') {
			width: 66px;
			padding: 9px 5px 4px;
		}

		@at-root :root.hover &:hover {
			box-shadow: 0 0 0 #C7C7C7;
			transform: translateY(4px);
			opacity: 1;
		}
	}

	&__inner-icon {
		width: max-content;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;

		@include media ('<768px') {
			max-width: 44%;
		}

		svg {
			display: block;
			width: 100%;
			height: auto;

			@include media ('<768px') {
				max-height: 30px;
			}
		}
	}

	&__inner-title {
		margin-top: 10px;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		color: #3A3A3A;

		@include media ('<768px') {
			margin-top: 5px;
			font-size: 12px;
			line-height: 18px;
		}
	}

	&__condition {
		display: flex;
		align-items: center;
		width: 100%;
		max-width: 450px;
		margin-top: 25px;
		margin-left: auto;
		margin-right: auto;
		padding: 15px;
		border-radius: 10px;
		background: rgba(255, 255, 255, 0.7);

		@include media ('<768px') {
			display: block;
			max-width: 288px;
			margin-top: 15px;
			padding: 7px 15px 15px;
		}
	}

	&__condition-left {
		padding-right: 15px;
		flex-shrink: 0;
		width: 115px;

		@include media ('<768px') {
			width: 100%;
		}
	}

	&__condition-right {
		flex-shrink: 0;
		width: calc(100% - 115px);

		@include media ('<768px') {
			width: 100%;
			margin-top: 5px;
		}

		* {
			&:first-child {
				margin-top: 0;
			}
		}
	}

	&__condition-title {
		font-weight: 700;
		font-size: 14px;
		line-height: 21px;
		color: #3A3A3A;

		@include media ('<768px') {
			font-size: 11px;
			line-height: 16px;
			text-align: center;
		}
	}

	&__condition-link {
		display: block;
		margin-top: 5px;
		font-size: 14px;
		line-height: 21px;
		color: #008ECB;
		text-decoration: underline;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		@include media ('<768px') {
			margin-top: 8px;
			font-size: 12px;
			line-height: 18px;
		}
	}
}

.i-find {
	padding-top: 30px;
	padding-bottom: 30px;

	@include media ('<768px') {
		padding-top: 24px;
		padding-bottom: 24px;
		background-color: #EFEFEF;
	}

	.i-container {
		width: auto;
		max-width: 1050px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 15px;
		padding-right: 15px;
	}

	&__form {
		@include media ('<768px') {
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	&__form-group {
		position: relative;
		max-width: 605px;
		margin-left: auto;
		margin-right: auto;
	}

	&__input {
		display: block;
		width: 100%;

		input {
			display: block;
			width: 100%;
			background-color: #F4F4F4;
			border-radius: 4px;
			padding: 13px 50px 13px 16px;
			border: none;
			font-size: 16px;
			line-height: 24px;
			height: auto;

			@include media ('<768px') {
				font-size: 14px;
				line-height: 20px;
				padding: 10px 40px 10px 16px;
				background-color: #ffffff;
			}

			&::placeholder {
				font-size: 16px;
				line-height: 24px;
				color: #AEAEAE;

				@include media ('<768px') {
					font-size: 14px;
					line-height: 21px;
				}
			}
		}
	}

	&__icon {
		position: absolute;
		display: block;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
		padding: 0;
		margin: 0;
		border: none;
		background-color: transparent;
		width: 23px;
		height: 23px;

		@include media ('<768px') {
			right: 10px;
		}

		svg {
			display: block;
			width: 100%;
			height: 100%;
		}
	}
}

.i-top-content {
	@include media ('<768px') {
		margin-bottom: 20px;
		padding-bottom: 5px;
		background-color: #EFEFEF;
	}

	.i-container {
		width: auto;
		max-width: 80%;
		margin-left: auto;
		margin-right: auto;
		padding-left: 10px;
		padding-right: 10px;

		@include media ('<768px') {
			max-width: none;
		}
	}

	&__inner {
		display: flex;

		@include media ('<768px') {
			display: block;
		}
	}

	&__body {
		flex-grow: 1;
		padding-right: 30px;

		@include media ('<768px') {
			padding-right: 0;
		}
	}

	&__body-list {

	}

	&__body-item {
		position: relative;
		margin-top: 35px;
		z-index: 1;

		@include media ('<768px') {
			margin-top: 0;
			padding-top: 25px;
			padding-bottom: 20px;
		}

		&:first-child {
			margin-top: 0;

			@include media ('<768px') {
				padding-top: 0;
			}
		}

		&:last-child {
			@include media ('<768px') {
				padding-bottom: 0;
			}
		}

		&.i-white-bg {

			&:before {
				content: '';
				display: none;
				position: absolute;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 100vw;
				height: 100%;
				background-color: #ffffff;
				z-index: -1;

				@include media ('<768px') {
					display: block;
				}
			}
		}
	}

	&__body-item-title-group {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 7px 16px;
		background-color: #666666;
		border-radius: 3px;
		column-gap: 10px;

		.i-block-title {
			font-weight: 700;
			font-size: 16px;
			line-height: 24px;
			color: #ffffff;

			@include media ('<768px') {
				font-size: 13px;
				line-height: 19px;
			}
		}
	}

	&__body-item-title-link {
		display: flex;
		align-items: center;
		flex-shrink: 0;

		.i-text {
			display: block;
			font-size: 14px;
			line-height: 21px;
			color: #FFFFFF;

			@include media ('<768px') {
				font-size: 12px;
				line-height: 18px;
			}
		}

		.i-arrow {
			display: block;
			width: 13px;
			height: 15px;
			margin-left: 6px;

			svg,path {
				display: block;
				width: 100%;
				height: 100%;
			}
		}
	}

	&__child-list {
		display: flex;
		flex-wrap: wrap;
		margin: 30px -7px -24px;

		&.i-one {
			margin: 15px 0 0 0;

			.i-top-content__child-item {
				@include media ('<768px') {
					width: 100%;
					padding: 12px;
					background: #FFFFFF;
					box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.15);
					border-radius: 4px;
				}

				&:not(:first-child) {
					@include media ('<768px') {
						margin-top: 15px;
					}
				}
			}

			.i-top-content__child-inner {
				@include media ('<768px') {
					display: flex;
					flex-wrap: wrap;
				}
			}

			.i-top-content__child-img-wrap {
				@include media ('<768px') {
					max-width: 115px;
				}
			}

			.i-top-content__child-title-wrap {
				@include media ('<768px') {
					width: calc(100% - 115px);
					margin-top: 0;
					padding-left: 13px;
				}
			}

			.i-tags {
				@include media ('<768px') {
					width: 100%;
					margin-top: 10px;
				}
			}

			.i-top-content__text-list {
				@include media ('<768px') {
					width: 100%;
				}
			}
		}

		&.i-two {
			margin: 15px -5px -22px;

			.i-top-content__child-item {
				@include media ('<768px') {
					width: calc(100% / 2);
					padding: 0 5px 22px;
				}
			}

			.i-top-content__child-title-wrap {
				@include media ('<768px') {
					margin-top: 5px;
				}

				.i-inner-title {
					@include media ('<768px') {
						font-size: 13px;
						line-height: 18px;
					}
				}
			}
		}
	}

	&__child-list li:nth-child(n+10) {
		display: none;

		@include media ('<768px') {
			display: list-item;
		}
	}

	&__child-item {
		width: calc(100% / 3);
		padding: 0 7px 24px;
		max-width: 414px;

		@include media ('<768px') {
			max-width: none;
		}
	}

	&__child-inner {
		display: block;
		height: 100%;
	}

	&__child-img-wrap {
		width: 100%;
	}

	&__child-img {
		position: relative;
		height: 0;
		padding-bottom: calc(172 / 230 * 100%);

		img {
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
		}
	}

	&__child-title-wrap {
		margin-top: 10px;

		.i-subtitle {
			display: block;
			font-size: 10px;
			line-height: 15px;
			color: #555555;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.i-inner-title {
			margin-top: 5px;
			font-weight: 700;
			font-size: 16px;
			line-height: 22px;
			text-decoration-line: underline;
			color: #1E85A9;

			@include media ('<768px') {
				margin-top: 7px;
			}
		}
	}

	&__text-list {
		margin-top: 10px;
	}

	&__text-item {
		margin-top: 3px;
		background-repeat: no-repeat;
		padding-left: 20px;

		&:first-child {
			margin-top: 0;
		}

		&.i-marketing {
			background-image: url('../img/icons/marketing.svg');
			background-size: 11px 10px;
			background-position: 1px 2.5px;
		}

		&.i-price {
			background-image: url('../img/icons/price.svg');
			background-size: 8px 11px;
			background-position: 2px 2px;
		}

		&.i-time {
			background-image: url('../img/icons/time.svg');
			background-size: 12px 12px;
			background-position: 0 1.5px;
		}

		&.i-address {
			background-image: url('../img/icons/address.svg');
			background-size: 10px 11px;
			background-position: .4px 2.5px;
		}

		.i-text {
			font-size: 12px;
			line-height: 15px;
			color: #555555;
			margin: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&__body-link {
		display: block;
		width: max-content;
		margin-top: 25px;
		margin-left: auto;
		margin-right: auto;
		font-size: 14px;
		line-height: 21px;
		text-decoration-line: underline;
		color: #1E85A9;

		@include media ('<768px') {
			margin-right: 0;
		}
	}

	// aside

	&__aside {
		width: 300px;
		flex-shrink: 0;

		@include media ('<768px') {
			width: 100%;
			margin-top: 30px;
		}
	}

	&__aside-internships {
		background-color: #F4F4F4;
		border-radius: 4px;
		padding: 17px 15px 18px;

		@include media ('<768px') {
			background-color: transparent;
			padding: 0 0 25px 0;
			margin-left: -15px;
			margin-right: -15px;
		}

		.i-btn-aside {
			margin-top: 16px;
		}
	}

	&__aside-internships-title {
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		color: #555555;

		@include media ('<768px') {
			font-size: 18px;
			line-height: 27px;
		}
	}

	&__aside-interview {
		position: relative;
		//margin-top: 40px; -- Not needed when keep jobs aren't displayed
		z-index: 2;

		@include media ('<768px') {
			margin-top: 0;
			padding: 25px 0;
		}

		&:before {
			content: '';
			display: none;
			position: absolute;
			width: 100vw;
			height: 100%;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			background-color: #ffffff;
			z-index: -1;

			@include media ('<768px') {
				display: block;
			}
		}
	}

	&__aside-interview-list {
		margin-top: 15px;

		@include media ('<768px') {
			display: flex;
			flex-wrap: wrap;
			margin: 15px -5px -12px;
		}
	}

	&__aside-interview-item {
		padding-bottom: 15px;
		border-bottom: 1px solid #E0E0E0;

		@include media ('<768px') {
			width: 50%;
			border-bottom: none;
			padding: 0 5px 12px;
		}

		&:not(:first-child) {
			padding-top: 10px;

			@include media ('<768px') {
				padding-top: 0;
			}
		}
	}

	&__aside-interview-inner {
		display: flex;
		flex-wrap: wrap;
	}

	&__aside-interview-title {
		width: 100%;
		font-weight: 700;
		font-size: 15px;
		line-height: 21px;
		color: #1E85A9;
		margin-bottom: 15px;

		@include media ('<768px') {
			order: 2;
			margin-top: 5px;
			margin-bottom: 0;
			font-size: 13px;
			line-height: 16px;
		}
	}

	&__aside-interview-img-wrap {
		width: 100%;
		max-width: 120px;

		@include media ('<768px') {
			max-width: 100%;
			order: 1;
		}
	}

	&__aside-interview-img {
		position: relative;
		height: 0;
		padding-bottom: calc(90 / 120 * 100%);

		img {
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
		}
	}

	&__aside-interview-desc {
		width: calc(100% - 120px);
		padding-left: 10px;

		@include media ('<768px') {
			width: 100%;
			order: 3;
			margin-top: 5px;
			padding-left: 0;
		}

		.i-caption {
			display: block;
			font-size: 11px;
			line-height: 16px;
			color: #555555;

			@include media ('<768px') {
				font-size: 10px;
				line-height: 15px;
			}
		}

		.i-inner-title {
			margin-top: 5px;
			font-size: 15px;
			line-height: 22px;
			font-weight: 400;
			color: #555555;

			@include media ('<768px') {
				margin-top: 0;
				font-size: 13px;
				line-height: 19px;
			}
		}

		.i-text {
			margin-top: 10px;
			font-size: 10px;
			line-height: 15px;
			color: #555555;

			@include media ('<768px') {
				margin-top: 6px;
				font-size: 9px;
				line-height: 13px;
			}
		}
	}

	&__aside-interview-link {
		display: block;
		width: 100%;
		margin-top: 10px;
		margin-left: auto;
		font-size: 12px;
		line-height: 18px;
		text-decoration-line: underline;
		color: #1E85A9;
	}

	&__aside-useful {
		position: relative;
		margin-top: 40px;
		z-index: 2;

		@include media ('<768px') {
			margin-top: 0;
			padding-top: 25px;
		}

		&:before {
			content: '';
			display: none;
			position: absolute;
			width: 100vw;
			height: 100%;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			background-color: #ffffff;
			z-index: -1;

			@include media ('<768px') {
				display: block;
			}
		}
	}

	&__aside-useful-list {
		margin-top: 20px;
	}

	&__aside-useful-item {
		&:last-child {
			.i-top-content__aside-useful-link-item {
				&:last-child {
					border-bottom: 1px solid #E0E0E0;
				}
			}
		}
	}

	&__aside-useful-title {
		background-color: #EFEFEF;
		border-radius: 3px;
		padding: 6px 10px;

		.i-block-title {
			font-weight: 700;
			font-size: 12px;
			line-height: 18px;
			color: #555555;
		}
	}

	&__aside-useful-link-list {

	}

	&__aside-useful-link-item {

		&:not(:last-child) {
			border-bottom: 1px solid #E0E0E0;
		}
	}

	&__aside-useful-link {
		display: flex;
		align-items: center;
		column-gap: 6px;
		padding-top: 15px;
		padding-bottom: 15px;

		@include media ('<768px') {
			column-gap: 9px;
		}
	}

	&__aside-useful-icon-wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background-color: #E7F0F9;
		padding: 4px;
		flex-shrink: 0;

		@include media ('<768px') {
			width: 42px;
			height: 42px;
		}
	}

	&__aside-useful-icon {
		display: block;
		width: max-content;

		&.i-ico-1 {
			@include media('<768px') {
				width: 22px;
			}
		}

		&.i-ico-2 {
			@include media('<768px') {
				width: 30px;
			}
		}

		&.i-ico-3 {
			@include media('<768px') {
				width: 19px;
			}
		}

		&.i-ico-4 {
			@include media('<768px') {
				width: 30px;
			}
		}

		&.i-ico-5 {
			@include media('<768px') {
				width: 16px;
			}
		}

		&.i-ico-6 {
			@include media('<768px') {
				width: 22px;
			}
		}

		svg, path {
			display: block;

			@include media('<768px') {
				width: 100%;
				height: 100%;
			}
		}
	}

	&__aside-useful-text {
		display: block;
		font-weight: 700;
		font-size: 14px;
		line-height: 21px;
		color: #1E85A9;
		flex-grow: 1;

		@include media ('<768px') {
			font-size: 16px;
		}
	}

	&__aside-useful-arrow {
		display: block;
		margin-left: auto;
		width: 13px;
		height: 10px;
		flex-shrink: 0;

		svg,path {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	&__aside-internal-link {
		position: relative;
		margin: 20px 0;
		z-index: 2;

		@include media ('<768px') {
			margin: 25px 0;
		}

		&:before {
			content: '';
			display: none;
			position: absolute;
			width: 100vw;
			height: 100%;
			left: 50%;
			transform: translateX(-50%);
			background-color: #ffffff;
			z-index: -1;

			@include media ('<768px') {
				display: block;
			}
		}
	}

	&__aside-top-summary {
		position: relative;
		z-index: 2;

		&:before {
			content: "";
			display: none;
			position: absolute;
			width: 100vw;
			height: 100%;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			background-color: #fff;
			z-index: -1;

			@include media ('<768px') {
				display: block;
			}
		}
	}
}

.i-tags {
	display: flex;
	flex-wrap: wrap;
	margin-top: 7px;
	gap: 4px;

	&__item {
		display: block;
		background-color: #FFFFFF;
		border: 1px solid #1E85A9;
		border-radius: 3px;
		font-size: 9px;
		line-height: 13px;
		color: #1E85A9;
		padding: 3px 4px;
		margin: 0;

		&.i-disabled {
			border: 1px solid #888888;
			color: #888888;
		}
	}
}
