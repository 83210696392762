.i-search {

	&__body-accordion-form {
		display: block;
		width: 100%;
	}

	&__body-accordion {
		display: block;
		width: 100%;
		border-radius: 4px;
		overflow-x: hidden;
		position: relative;
		padding-bottom: 80px;


		@media(min-width: 768px) {
			overflow-y: auto;
			box-shadow: 0px 1px 3px 2px rgba(0, 0, 0, 0.15);
			height: 580px;
			padding-bottom: 58px;
		}
	}

	&__body-accordion-item {
		position: relative;
		display: block;
		width: 100%;
		border-bottom: 1px solid #D3D3D3;
		.i-recruitment-label {
			justify-content: flex-end;
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}

	&__body-accordion-item-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 11px;
		padding-right: 24px;
		padding-bottom: 11px;
		padding-left: 24px;
		position: relative;

		@media(max-width: 767px) {
			padding-right: 14px;
			padding-left: 14px;
		}

		&.checked {
			.i-recruitment-input {
				background-color: #1E85A9;
				padding-right: 21px;
				padding-left: 6px;
				&:after {
					content: "";
					left: 32px;
				}
			}
		}
	}


	&__body-accordion-item-inner {
		display: flex;
		justify-content: space-between;
		padding-top: 11px;
		padding-right: 15px;
		padding-bottom: 11px;
		padding-left: 24px;
		position: relative;

		@media(max-width: 767px) {
			padding-right: 5px;
			padding-left: 14px;
		}

		&.active {
			&:after {
				display: block;
			}
		}

		&.checked {

			color: #1E85A9;
			.i-article-search-input {
				background-color: #1E85A9;
				border: 0;
			}
		}

		&:after {
			content: "";
			display: none;
			width: 200vw;
			height: 1px;
			background-color: #D3D3D3;
			position: absolute;
			bottom: 0;
			left: -50%;
			z-index: 2;
		}

		&.i-acc-bg {
			z-index: 1;

			&:before {
				content: '';
				display: block;
				position: absolute;
				width: 120vw;
				height: 100%;
				top: 0;
				left: 50%;
				transform: translateX(-50%);
				background-color: #F9F9F9;
				z-index: -1;
			}
		}
	}

	&__body-accordion-title {
		display: block;
		width: 100%;
		font-size: 18px;
		line-height: 27px;
		font-weight: 600;
		@media(max-width: 767px) {
			font-size: 16px;
			line-height: 24px;
			width: auto;
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 1;
			transform: translate(-50%, -50%);
		}
	}

	&__body-accordion-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		width: 30px;
		border: 0;
		border-radius: 0;
		background-color: transparent;

		// position: relative;

		transition: 0.2s ease;
		// defaults
		cursor: pointer;
		outline: none;

		span  {
			display: block;
			width: 12px;
			height: 9px;
		}

		svg {
			display: block;
			width: 100%;
			fill: #7C7C7C;
		}

		&.active {
				transform: rotate(180deg);
				svg {
					fill: #C4C4C4;
				}
		}
	}

	&__body-accordion-btn-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0;
		width: 100%;
		border: 0;
		border-radius: 0;
		background-color: transparent;
		box-shadow: none;

		// position: relative;

		transition: 0.2s ease;
		// defaults
		cursor: pointer;
		outline: none;

		&.active {
			.i-search__body-accordion-btn {
				transform: rotate(180deg);
				svg {
					fill: #C4C4C4;
				}
			}
		}
	}

		&__body-accordion-sublist {
			height: 0;
			overflow: hidden;
			transition: height 0.2s ease;
		}

		&__body-accordion-sub-item {
			display: block;
			width: 100%;
			position: relative;
			padding-left: 24px;

			&:last-child {
				&:after {
					display: none;
				}
			}
			&:after {
				content: "";
				display: block;
				width: 200vw;
				height: 1px;
				background-color: #D3D3D3;
				position: absolute;
				bottom: 0;
				left: -50%;
				z-index: 2;
			}
		}

			&__navigation {
				display: block;
				position: relative;
				top: -70px;
				z-index: 4;
				width: 100%;
				background: rgba(0, 0, 0, 0.7);
				border-radius: 0 0 4px 4px;
				@media(max-width: 767px) {
					top: initial;
					position: fixed;
					bottom: 0;
					border-radius: 0;
				}
			}

			&__navigation-inner {
				padding: 10px 20px;
				height: 70px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			&__navigation-item {
				display: block;
				width: 100%;
			}

			&__navigation-clear {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 3px 9px;
				min-height: 36px;
				width: 100%;
				max-width: 80px;
				margin-right: 5px;
				border: 0;
				border-radius: 100px;
				background-color: #EFEFEF;

				position: relative;
				text-align: center;

				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 21px;
				color: #848484;

				transition: 0.2s ease;
				// defaults
				cursor: pointer;
				outline: none;
			}

			&__navigation-btn {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 3px 9px;
				min-height: 48px;
				width: 100%;
				margin: 0 auto;
				max-width: 230px;
				border: 0;
				box-shadow: 0px 4px 0px #136A88;
				border-radius: 100px;
				background-color: #1E85A9;

				position: relative;
				text-align: center;

				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 21px;
				color: #FFFFFF;

				transition: 0.2s ease;
				// defaults
				cursor: pointer;
				outline: none;
			}

			&__navigation-btn--num {
				font-size: 18px;
				line-height: 27px;
			}

			&__body-accordion-item-text {
				font-size: 16px;
				line-height: 24px;
				font-weight: 600;
				margin: 0;
			}

}
