/* header */
.m-common-headerLogo a {
	padding: 0;
}

.l-common-headerMenuArea_pc {
	width: 800px;
}

.i-common-headerMenuArea_pc {
	@media(min-width: 768px) {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}

.l-common-headerMenuArea_sp {
	@media (max-width: 767px) {
		width: 105px;
	}
}

.i-header-nav {
	background: rgba(0, 0, 0, 0.7);
	display: block;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	transform: translateY(-100%);
	transition: 0.2s ease;

	&.fixed {
		transform: translateY(0);
	}
}
.i-header-nav-inner {
	display: flex;
	align-items: center;
	height: 72px;
	max-width: 798px;
	padding: 0 12px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	@media(max-width: 767px) {
		height: 55px;
		justify-content: center;
	}

	.i-btn {
		margin-right: 20px;
		@media(max-width: 767px) {
			margin-right: 10px;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}
.l-common-headerMenuArea_sp {
	@media(max-width: 767px) {
		display: flex;
		align-items: center;
	}
}
.i-header-action {
	max-width: 150px;
	width: 100%;
	margin-left: 20px;
	position: relative;
}
.i-header-action-content {
	display: none;
	position: absolute;
	max-width: 120px;
	width: 100%;
	top: 47px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 2;
	background-color: #ffffff;
	border-radius: 4px;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
	&.active {
		display: block;
	}
}
.i-header-action-list {
}
.i-header-action-item {
	display: block;
	width: 100%;
	padding: 10px;
	border-bottom: 1px solid #EFEFEF;
	&:last-child {
		border-bottom: 0;
		.i-header-action-btn-img {
			max-width: 16px;
		}
	}
}
.i-header-action-btn {
	display: flex;
	align-items: center;
	width: 100%;
	font-size: 14px;
	line-height: 17px;
	font-weight: 300;
	color: #555555;
}
.i-header-action-btn-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 16px;
	width: 100%;
	margin-right: 5px;
}
.i-header-action-btn-img {
	display: block;
	width: 100%;
	max-width: 11px;
	img {
		display: block;
		width: 100%;
	}
}

.i-header-num {
	margin-left: 5px;
	font-weight: 400;
}

.l-common-headerMenuArea_sp {
	@include media ('<768px') {
		width: auto;
	}
}
