@charset "utf-8";

// ▼Sass設定

@import "_variables.scss";

/***************************************
 管理画面共通
***************************************/

.l-recruit-l{
  width: 180px;
  float: left;
}

.l-recruit-r{
  width: 840px;
  float: right;
}

.l-recruit-menu_pc{
  margin: 0 0 20px;
  li{
    background: $color_base;
    margin: 0 0 3px;
    @include common_Height(62px);
    text-indent: 11px;
    color: #ffffff;
    position: relative;
    a{
      display: block;
      @include common_Height(62px);
      color:$color_gray1;
      background: #DADADA;
    }
  }
}

.l-recruit-submenu_pc{
  margin: 0 0 20px;
  li{
    background: #ffffff;
    border: solid 1px $color_gray3;
    margin: 0 0 3px;
    @include common_Height(42px);
    text-indent: 15px;
    color: #ffffff;
    position: relative;
    a{
      display: block;
      @include common_Height(42px);
      color:$color_gray1;
      background: #ffffff;
    }
  }
}

.l-recruit-baseArea{
  margin: 0 0 30px;
  overflow: hidden;
}

.l-message-baseArea{
  margin: 0;
  overflow: hidden;
}

.m-recruit-menu_ico{
  position: absolute;
  top: 0;
  right: 8px;
  height: 62px;
  display: table;
  div{
    text-align: right;
    display: table-cell;
    vertical-align: middle;
  }
  p{
    font-size: 10px;
    color: $color_gray2;
    height: 16px;
    margin: 3px 0;
    line-height: 1;
  }
  span{
    margin: 0 0 0 3px;
  }
}

.m-recruit-menu_icoUnread{
  display: inline-block;
  background: $color_red1;
  @include common_Height(16px);
  font-size: 11px;
  border-radius: 9px;
  color: #ffffff;
  text-align: center;
  text-indent: 0;
  min-width: 12px;
  padding: 0 5px;
}

.m-recruit-menu_icoNotyet{
  display: inline-block;
  background: $color_green1;
  @include common_Height(16px);
  font-size: 11px;
  border-radius: 9px;
  color: #ffffff;
  text-align: center;
  text-indent: 0;
  min-width: 12px;
  padding: 0 5px;
}

/* 未読時 */
.l-user-list_unread{
  font-weight: bold;
  color: #272727;
}

/* 未読時 */
.l-user-list_icon{
  display: inline-block;
  font-weight: bold;
  background: $color_red1;
  color: #ffffff;
  font-size: 11px;
  @include common_Height(11px);
  width: 11px;
  border-radius: 50%;
  margin: 0 5px 0 0;
  line-height: 1;
}

.l-recruit-alert{
  border: solid 1px $color_gray3;
  padding: 10px;
  margin: 0 0 20px;
  ul{
    list-style: disc outside;
    line-height: 1.5;
    margin: 0 0 0 15px;
    li{
      margin: 5px 0;
      font-size: 12px;
    }
  }
}

.l-recruit-formBg{
  background: $color_gray4;
  border-radius: 4px;
  padding: 30px 0;
  margin: 0 0 20px;
  overflow: hidden;
  h2{
    font-size: 16px;
    margin: 20px 0 10px;
    display: inline-block;
    line-height: 1.4;
    span{
      padding: 0 10px 0 0;
    }
  }
  dl{
    margin: 2px 0;
    padding: 13px 15px;
    background: $color_gray4;
    overflow: hidden;
    line-height: 1.6;
    dt{
      display: block;
      float: left;
      font-weight: bold;
      width: 200px;
      margin: 0 3px 0 0;
      vertical-align: top;
      position: relative;
    }
    dd{
      display: block;
      float: left;
      padding: 0;
      margin: 0;
      word-wrap: break-word;
    }
    small{
      display: block;
      line-height: 1.4;
      margin: 4px 0 0;
    }
  }
}

.l-recruit-baseBox{
  width: 90%;
  margin: 0 auto;
  line-height: 1.4;
  dl{
    background: #ffffff;
    margin: 4px 0;
    dd{
      width: 510px;
      word-wrap: break-word;
    }
  }
  p{
    margin: 0 0 10px;
    line-height: 1.4;
  }
}

.m-recruit-btn_gray{
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  vertical-align: top;
  @include common_Height(25px);
  background: $color_gray1;
  border: none;
  font-size: 13px;
  padding: 0 10px;
  font-weight: normal;
}

.m-recruit-btn_green{
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  vertical-align: top;
  @include common_Height(25px);
  background: $color_green1;
  border: none;
  font-size: 13px;
  padding: 0 10px;
  font-weight: normal;
  cursor: pointer;
  &:hover{
    @include common_Alpha;
  }
}

.m-recruit-btn_blue{
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  vertical-align: top;
  @include common_Height(25px);
  background: $color_blue1;
  border: none;
  font-size: 13px;
  padding: 0 10px;
  font-weight: normal;
  cursor: pointer;
  &:hover{
    @include common_Alpha;
  }
}

.m-recruit-btn_yellow {
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  vertical-align: top;
  @include common_Height(25px);
  background: $color_yellow1;
  border: none;
  font-size: 13px;
  padding: 0 10px;
  font-weight: normal;
  cursor: pointer;
  &:hover{
    @include common_Alpha;
  }
}

.m-recruit-btn_orange {
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  vertical-align: top;
  @include common_Height(25px);
  background: $color_orange1;
  border: none;
  font-size: 13px;
  padding: 0 10px;
  font-weight: normal;
  cursor: pointer;
  &:hover:not([disabled]){
    @include common_Alpha;
  };
  &:disabled{
    background: $color_gray1;
  }
}

.m-recruit-btn_red{
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  vertical-align: top;
  @include common_Height(25px);
  background: $color_red1;
  border: none;
  font-size: 13px;
  padding: 0 10px;
  font-weight: normal;
  cursor: pointer;
  &:hover{
    @include common_Alpha;
  }
}

.m-recruit-btn_w{
  width: 200px;
}

.l-recruit-deleteBg{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: $width_all;
  height: $width_all;
  margin: auto;
  background: rgba(30, 30, 30, 0.7);
  z-index: 100;
}

.l-recruit-deleteBox{
  width: 500px;
  height: 200px;
  text-align: center;
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 50px 0 0;
  box-sizing: border-box;
  p{
    margin: 0 auto 15px;
    width: 85%;
    line-height: 1.4;
  }
}

.l-recruit-resumeBg{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: $width_all;
  height: $width_all;
  margin: auto;
  background: rgba(30, 30, 30, 0.7);
  z-index: 100;
}

.l-recruit-resumeArea{
  width: 800px;
  height: 85%;
  background: #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 70px 20px 30px;
  overflow: hidden;
  box-sizing: border-box;
}

.l-recruit-resumeBox{
  overflow-y: auto;
  height: 100%;
  dl{
    width: 90%;
    margin: 0 auto;
    padding: 10px 15px;
    border-top: solid 1px #D8D8D8;
    &:last-child{
      border-bottom: solid 1px #D8D8D8;
    }
    dt{
      display: inline-block;
      width: 130px;
      font-weight: bold;
      vertical-align: middle;
    }
    dd{
      display: inline-block;
      margin: 0;
      width: 520px;
      line-height: 1.4;
      vertical-align: middle;
    }
  }
}

.l-recruit-resumeBtnBox{
  position: absolute;
  top: 25px;
  left: 42px;
  select{
    margin: 0 10px 0 0;
  }
  ul{
    overflow: hidden;
    display: inline-block;
    margin: 0 0 0 30px;
    li{
      float: left;
      margin: 0 2px;
    }
  }
}

.l-recruit-pager_back{
  display: inline-block;
  @include common_Height(28px);
  // background: url(../img/recruit_pager_back.png) $color_gray2 no-repeat left center;
  background-size: 15px auto;
  color: #ffffff;
  padding: 0 20px 0 30px;
  border-radius: 2px;
  font-size: 12px;
}

.l-recruit-pager_next{
  display: inline-block;
  @include common_Height(28px);
  // background: url(../img/recruit_pager_next.png) $color_gray2 no-repeat right center;
  background-size: 15px auto;
  color: #ffffff;
  padding: 0 30px 0 20px;
  border-radius: 2px;
  font-size: 12px;
}

.m-recruit-btnDelete{
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  @include common_Height(22px);
  width: 22px;
  font-size: 20px;
  background: $color_gray3;
  border: none;
  position: absolute;
  top: 8px;
  right: 8px;
  span{
    position: relative;
    top: -2px;
  }
}

.m-recruit-btnClose{
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  @include common_Height(22px);
  background: $color_gray3;
  border: none;
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 0 5px;
}

.l-recruit-notes{
  li{
    font-size: 12px;
    color: $color_red1;
    margin: 3px 0;
    line-height: 1.4;
  }
}

.m-recruit-textarea {
  width: 100%;
  box-sizing: border-box;
}

.m-recruit-textarea_hs {
  height: 50px;
}

.m-recruit-textarea_hm {
  height: 150px;
}

.m-recruit-textarea_hl {
  height: 300px;
}

.m-recruit-textarea_hll {
  height: 500px;
}

/*** お問い合わせ ***/

.TelContactText{
  text-align: center;
}

span.TelContactNumber{
  font-size: 24px;
  font-family: 'Lato', sans-serif;
  margin-bottom: 2px;
  line-height: 100%;
  color: blue;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-recruit-l{
    width: $width_sp;
    margin: 0 auto;
    float: none;
  }

  .l-recruit-r{
    width: $width_sp;
    margin: 40px auto 0;
    float: none;
  }

  .l-recruit-menu_sp{
    width: $width_all;
    position: absolute;
    margin: 0 0 10px;
    z-index: 2;
    ul{
      overflow: hidden;
      background: #DADADA;
      li{
        background: $color_base;
        box-sizing: border-box;
        position: relative;
        font-size: 13px;
        width: $width_all / 2;
        float: left;
        border-bottom: solid 1px #ffffff;
        border-right: solid 1px #ffffff;
        &:nth-child(even){
          border-right: none;
        }&:last-child{
          border-bottom: none;
        }
        a{
          display: block;
          padding: 0 10px;
          @include common_Height(51px);
          color:$color_gray1;
          background: #DADADA;
        }
      }
    }
  }

  .m-recruit-menuBtn_sp{
    // background: $color_base url(../img/common_arrow_bg.png) no-repeat 10px center;
    background-size: auto 20%;
    display: block;
    @include common_Height(40px);
    color: #ffffff;
    font-size: 15px;
    padding: 0 15px;
    text-indent: 20px;
    &:hover{
      @include common_Alpha($value: 1.0);
    }
  }

  .l-message-baseArea {
    overflow: visible;
  }

  .l-contents-message {
    margin: 50px 0 0;
  }

  .l-recruit-formBg{
    padding: 15px 0;
    dl{
      padding: 10px;
      dt{
        width: 160px;
        font-size: 13px;
      }
      dd{
        width: 65%;
      }
    }
    .l-recruit-termsLink{
      margin: 15px 0 25px;
    }
  }

  .l-recruit-top_deleteBox{
    width: 75%;
    height: 160px;
  }

  .m-recruit-btn_w{
    width: 200px;
  }

  .l-recruit-deleteBox{
    width: 90%;
  }

  .l-recruit-resumeArea{
    width: 87%;
    height: 87%;
    padding: 90px 10px 20px;
  }

  .l-recruit-resumeBox{
    dl{
      width: 90%;
      padding: 5px;
      dt{
        width: 100%;
      }
      dd{
        width: 100%;
      }
    }
  }

  .l-recruit-resumeBtnBox{
    position: absolute;
    top: 35px;
    left: 30px;
    input{
      margin: 6px 0;
    }
    select{
      margin: 6px 10px 6px 0;
    }
    ul{
      margin: 6px 0 6px 10px;
      li{
        float: left;
        margin: 0 2px;
      }
    }
  }

  .m-recruit-textarea {
    width: 100%;
    height: 100px;
  }

  .m-recruit-textarea_hm {
    height: 100px;
  }

  .m-recruit-textarea_hl {
    height: 200px;
  }

  .m-recruit-textarea_hll {
    height: 400px;
  }

  .m-recruit-menu_ico_sp{
    position: absolute;
    top: 0;
    right: 8px;
    height: 51px;
    display: table;
    div{
      text-align: right;
      display: table-cell;
      vertical-align: middle;
    }
    p{
      font-size: 10px;
      color: $color_gray2;
      height: 16px;
      margin: 3px 0;
      line-height: 1;
    }
    span{
      margin: 0 0 0 3px;
    }
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-recruiters-style{
    margin: 0 auto;
  }

  .l-recruit-alert{
    margin: 0 0 15px;
  }

  .l-recruit-baseArea{
    margin: 0 0 20px;
  }

  .l-recruit-formBg{
    dl{
      padding: 10px 15px;
      dt{
        margin: 0 0 3px;
        width: auto;
      }
      dd{
        width: 100%;
      }
    }
    h2{
      font-size: 15px;
      margin: 15px 0 8px;
    }
    small{
      font-size: 10px;
    }
  }

  .l-recruit-baseBox{
    margin: 0 auto;
    dl{
      background: #ffffff;
      margin: 4px 0;
    }
    p{
      padding: 10px 0 0;
      font-size: 12px;
    }
  }

  .m-recruit-btn_w{
    width: 140px;
  }

  .l-recruit-deleteBox{
    width: 90%;
    text-align: left;
    font-size: 12px;
  }

  .l-recruit-delete_btnBox{
    text-align: center;
  }

  .l-recruit-resumeArea{
    width: 90%;
    height: 90%;
    padding: 120px 10px 20px;
  }

  .l-recruit-resumeBox{
    dl{
      width: 90%;
      padding: 5px;
      font-size: 12px;
    }
  }

  .l-recruit-resumeBtnBox{
    position: absolute;
    top: 35px;
    left: 20px;
    input{
      margin: 6px 0;
    }
    select{
      margin: 6px 10px 6px 0;
    }
    ul{
      margin: 6px 0 6px 0;
      li{
        float: left;
        margin: 0 2px;
      }
    }
  }

}

/***************************************
 管理画面（トップページ）
***************************************/

.l-recruit_indexBox{
  margin: 0 0 20px;
  border: solid 1px $color_gray1;
  border-radius: 4px;
  background: #EFEFEF;
  h2{
    background: $color_gray1;
    color: #ffffff;
    padding: 10px;
    font-size: 17px;
    font-weight: normal;
    line-height: 1.5;
  }
  ul{
    padding: 18px 15px;
    overflow: hidden;
    font-weight: bold;
    li{
      display: inline-block;
      margin: 0 30px 0 0;
      line-height: 1.3;
      span{
        font-size: 22px;
      }
    }
  }
}

.m-recruit-icon_jobCategory {
  display: inline-block;
  background: #ffffff;
  border-radius: 3px;
  color: $color_base;
  font-weight: bold;
  font-size: 14px;
  padding: 0 6px;
  margin: 0 6px 0 0;
  @include common_Height(20px);
 }

 /* ############ 599px以下 ############ */
 @media (max-width: 599px) {

 .l-recruit_indexBox{
   margin: 0 0 15px;
   h2{
     padding: 6px;
     font-size: 15px;
   }
   ul{
     padding: 15px 10px;
     li{
       font-size: 12px;
       margin: 0 20px 0 0;
       span{
         font-size: 18px;
       }
     }
   }
 }

 .m-recruit-icon_jobCategory {
   font-size: 12px;
   padding: 0 5px;
   margin: 0 5px 0 0;
   @include common_Height(18px);
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

.l-recruit_indexBox{
  h2{
    font-size: 13px;
  }
}

.m-recruit-icon_jobCategory {
  font-size: 11px;
  @include common_Height(16px);
 }

}

/***************************************
 管理画面（メッセージ）
***************************************/

.l-contents-message{
  padding: 0;
}

.l-recruit-message_l{
  width: 280px;
  height: 566px;
  float: left;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 0 6px 0 0;
}

.l-recruit-message_r{
  width:  552px;
  min-height: 620px;
  float: right;
  box-sizing: border-box;
}

.l-recruit-messageTitle_sp{
  @include common_Height(40px);
  background: $color_base;
  color: #ffffff;
  text-align: center;
  font-size: 13px;
  width: 100%;
  position: absolute;
  margin: 0 0 10px;
  z-index: 2;
  img{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
    height: 40%;
    padding: 10px 15px 10px 2px;
  }
}

.l-recruit-searchBox{
  margin: 0 0 15px;
  input[type="text"]{
    @include common_Height(42px);
    border: solid 1px $color_gray3;
    border-right: none;
    border-radius: 3px 0 0 3px;
    box-sizing: border-box;
    vertical-align: top;
    padding: 0 8px;
    display: inline-block;
    width: 206px;
    font-size: 14px;
  }
  input[type="submit"]{
    border: 0px;
    // background: url(../img/common_icon_search.png) $color_gray1 no-repeat center;
    background-size: 20px auto;
    @include common_Height(42px);
    width: 50px;
    border-radius: 0 3px 3px 0;
    display: inline-block;
  }
}

.l-recruit-message-listTab{
  overflow: hidden;
  display: inline-block;
  margin: 0 auto;
  li{
    float: left;
    text-align: center;
    font-size: 13px;
    position: relative;
    img{
      width: 10px;
      display: block;
      margin: 0 auto;
    }
    span{
      display: block;
      @include common_Height(38px);
      background: #C3C3C3;
      color: $color_gray1;
      padding: 0 14px;
      border-right: solid 1px #ffffff;
      &.recruit-message-listTab-selected {
        background: $color_base;
        color: #ffffff;
      }
      &:hover {
        cursor: pointer;
      }
    }
    a{
      display: block;
      @include common_Height(38px);
      background: #C3C3C3;
      color: $color_gray1;
      padding: 0 14px;
      border-right: solid 1px #ffffff;
    }
  }
}

.l-recruit-message_list{
  position: relative;
  display: block;
  width: 260px;
  border-top: dotted 1px $color_gray3;
  overflow: hidden;
  padding: 10px 10px 10px 14px;
  box-sizing: border-box;
  &:hover{
    opacity: 0.7;
  }
  &:last-child{
    border-bottom: dotted 1px $color_gray3;
  }
}

.l-recruit-message_listImg{
  width: 46px;
  position: relative;
  float: left;
  margin: 0 8px 8px 0;
  text-align: center;
  img{
    width: 100%;
  }
}

.l-recruit-message_listName{
  float: left;
  width: 180px;
  span{
    font-size: 10px;
    color: $color_gray2;
    display: block;
    line-height: 1;
    margin: 0 0 2px;
    text-align: right;
  }
  h3{
    line-height: 1.5;
    color: $color_gray1;
    font-size: 14px;
  }
  p{
    margin: 0 0 4px;
    line-height: 1.4;
    color: $color_gray2;
    font-size: 10px;
  }
}

.l-recruit-message_userDetail{
  font-size: 11px;
  line-height: 1.4;
  clear: both;
  color: $color_gray1;
  margin: 0;
}

.l-recruit-message-ico_notyetTab{
  font-size: 11px;
  position: absolute;
  top: -1px;
  left: 3px;
  color: $color_green1;
  font-style: normal;
}

.l-recruit-message-ico_unreadS{
  font-size: 11px;
  position: absolute;
  top: 6px;
  left: 0;
  color: $color_red1;
}

.l-recruit-message-ico_notyetS{
  font-size: 11px;
  position: absolute;
  top: 21px;
  left: 0;
  color: $color_green1;
}

.l-recruit-message_chatArea{
  overflow-y: scroll;
  height: 400px;
  box-sizing: border-box;
  margin: 0 0 8px;
  padding: 0 7px 0 0;
}

.l-recruit-message_chat{
  position: relative;
  margin: 0 0 10px;
  width: 75%;
  // background: url(../img/message_balloon_l.png) no-repeat 38px bottom;
  background-size: 18px auto;
  section{
    margin: 0 0 0 55px;
    background: $color_gray4;
    padding: 12px;
    border-radius: 6px;
    word-wrap: break-word;
  }
}

.l-recruit-message_chat_self{
  position: relative;
  padding: 0 18px 0 0;
  margin: 0 0 10px 0;
  // background: url(../img/message_balloon_r.png) no-repeat right bottom;
  background-size: 18px auto;
  overflow: hidden;
  section{
    float: right;
    background: $color_gray4;
    overflow: hidden;
    padding: 12px;
    border-radius: 6px;
    width: 75%;
  }
}

.l-recruit-message_chatImg{
  width: 35px;
  height: 35px;
  background: #ffffff;
  position: absolute;
  left: 0;
  bottom: 0;
  img{
    width: 100%;
  }
}

.l-recruit-message_chatBox{
  word-wrap: break-word;
  small{
    margin: 0 0 0 10px;
  }
  span{
    font-size: 11px;
    color: $color_gray2;
    display: block;
    line-height: 1;
    margin: 5px 0 0;
    text-align: right;
  }
  h3{
    line-height: 1.5;
    color: $color_gray1;
    font-size: 12px;
    margin: 0 0 3px;
  }
  p{
    margin: 0;
    line-height: 1.5;
    color: $color_gray1;
    font-size: 14px;
  }
}

.l-recruit-message_textArea_pc{
  text-align: center;
  padding: 15px 0;
  background: $color_gray4;
  overflow: hidden;
  textarea{
    font-size: 14px;
    width: 95%;
    height: 150px;
    padding: 5px;
    resize: none;
    outline:none;
    &:placeholder-shown {
        color: #555;
    }
    &::-webkit-input-placeholder {
        color:#555;
    }
    &:-moz-placeholder {
        color:#555;
        opacity: 1;
    }
    &::-moz-placeholder {
        color:#555;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color:#555;
    }
  }
  .m-recruit-employment_btn{
    float: right;
  }
  .m-recruit-notAdopted_btn{
    float: right;
  }
  .m-recruit-btn_arrival_date{
    float: right;
  }
  .m-recruit-btn_arrival_confirm{
    float: right;
  }
  .m-recruit-arrivalBox{
    float: right;
  }
}

.l-recruit-btnBox{
  width: 95%;
  margin: 5px auto 0;
  text-align: center;
  position: relative;
  p{
    font-weight: bold;
    font-size: 12px;
    color: $color_red1;
    margin: 0 auto 10px;
    line-height: 1.4;
  }
}

.l-recruit-rejectBtnBox{
  width: 95%;
  margin: 5px auto 0;
  text-align: right;
  position: relative;
  p{
    font-weight: bold;
    font-size: 12px;
    color: $color_red1;
    margin: 0 auto 10px;
    line-height: 1.4;
  }
}

@mixin message_btn {
  display: inline-block;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  @include common_Height(28px);
  font-size: 14px;
}

.m-recruit-message_btn{
  @include message_btn;
  background: $color_green1;
  border-bottom: solid 2px $color_green2;
  float: left;
  width: 220px;
  text-align: center;
}

.m-recruit-employment_btn{
  @include message_btn;
  background: $color_red1;
  border-bottom: solid 2px $color_red2;
  margin: 0 0 0 5px;
  width: 140px;
  text-align: center;
}

.m-recruit-btnClose_under{
  @include message_btn;
  color: #ffffff;
  text-align: center;
  background: $color_gray3;
  border: none;
  width: 80px;
}

.m-recruit-rejectSend_btn{
  @include message_btn;
  background: $color_green1;
  border-bottom: solid 2px $color_green2;
  margin: 0 0 0 5px;
  width: 220px;
  text-align: center;
  display: inline-block;

}

.m-recruit-rejectNoSend_btn{
  @include message_btn;
  background: $color_red1;
  border-bottom: solid 2px $color_red2;
  margin: 0px 7% 0px 5px;
  width: 80px;
  text-align: center;
}

.m-recruit-rejectCancel_btn{
  @include message_btn;
  background: $color_blue1;
  border-bottom: solid 2px $color_blue2;
  margin: 0 0 0 5px;
  width: 80px;
  text-align: center;
}

.templateSelect{
  width: 100%;
}

.templateContent{
  width: 100%;
  height: 250px;
}

.templateTitle{
  width: 100%;
}

.m-recruit-notAdopted_btn{
  @include message_btn;
  background: $color_blue1;
  border-bottom: solid 2px $color_blue2;
  margin: 0 0 0 5px;
  width: 80px;
  text-align: center;
}

.m-recruit-definition{
  font-size: 12px;
  margin: 0 0 0 10px;
  text-decoration: underline;
  vertical-align: bottom;
  color: $color_base;
}

.m-recruit-list_btn{
  display: inline-block;
  padding: 0 10px;
  margin: 0 1px;
  background: $color_gray1;
  color: #ffffff;
  border: none;
  border-bottom: solid 2px #6F6F6F;
  border-radius: 3px;
  @include common_Height(28px);
  vertical-align: top;
}

.m-recruit-btn_normal{
  display: inline-block;
  background: #E67064;
  border: none;
  border-bottom: solid 2px #CA5549;
  color: #ffffff;
  text-align: center;
  border-radius: 3px;
  padding: 0 15px;
  @include common_Height(30px);
}

.m-form-btn_preview{
  display: inline-block;
  background: $color_gray1;
  color: #ffffff;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
  border: none;
  padding: 0 20px;
  @include common_Height(47px);
  position: absolute;
  top: 0;
  right: 0;
}

.m-recruit-btn_facebook{
  display: inline-block;
  background: #AFAFAF;
  border: none;
  border-bottom: solid 3px #9A9A9A;
  color: #464646;
  border-radius: 3px;
  padding: 0 15px;
  @include common_Height(30px);
}

/* 未読時 */
.l-recruit-message_unread{
  .l-recruit-message_listName{
    span{
      color: $color_base;
      font-weight: bold;
    }
    h3{
      color: $color_base;
    }
    p{
      color: $color_base;
      font-weight: bold;
    }
  }
  .l-recruit-message_userDetail{
    color: $color_base;
    font-weight: bold;
  }
}

/* 選択時 */
.l-recruit-message_selected{
  background: $color_gray1;
  .l-recruit-message_listName{
    span{
      color: #ffffff;
    }
    h3{
      color: #ffffff;
    }
    p{
      color: #ffffff;
    }
  }
  .l-recruit-message_userDetail{
    color: #ffffff;
  }
}


.l-recruit-message_topbtnArea{
  text-align: left;
  display: flex;
  padding: 10px 15px;
  margin: 0 0 10px;
  background: $color_gray4;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  .m-recruit-employment_btn{
    order: 1;
  }
  .m-recruit-notAdopted_btn{
    order: 2;
  }
  .m-recruit-definition{
    order: 3;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    margin: auto;
    @include common_Height(16px);
  }
}

.m-recruit-arrivalBox{
  display: inline-block;
  vertical-align: top;
  span{
    position: relative;
    top: 3px;
    font-size: 13px;
    font-weight: bold;
  }
}

.m-recruit-arrivalNote{
  font-size: 10px;
  display: inline-block;
  line-height: 1.4;
  margin: 0 0 0 5px;
}

.m-recruit-arrivalNote_before{
  font-weight: bold;
  color: $color_red1;
}

.m-recruit-userUniversity{
  display: inline-block;
  max-width: 116px;
  overflow: hidden;
  vertical-align: bottom;
  white-space: nowrap;
}

.m-recruit-userGrade{
  display: inline-block;
  max-width: 82px;
  overflow: hidden;
  vertical-align: bottom;
  white-space: nowrap;
}

.m-recruit-userUndergraduate{
  font-size: 10px;
  display: inline-block;
  max-width: 47%;
  overflow: hidden;
  vertical-align: bottom;
  white-space: nowrap;
}

.m-recruit-userSubject{
  font-size: 10px;
  display: inline-block;
  max-width: 47%;
  overflow: hidden;
  vertical-align: bottom;
  white-space: nowrap;
}

.m-recruit-jobTitle{
  font-size: 10px;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  vertical-align: bottom;
  white-space: nowrap;
  text-overflow: ellipsis;
}


/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-recruit-message_l{
    width: $width_all;
    overflow-y: auto;
    height: auto;
    float: none;
    margin: 0;
    padding: 0;
  }

  .l-recruit-message_r{
    width: $width_all;
    background: #ffffff;
    padding: 0 0 196px;
    max-height: none;
    float: none;
  }

  .l-recruit-messageTitle_sp{
    @include common_Height(40px);
    background: $color_base;
    color: #ffffff;
    text-align: center;
    font-size: 13px;
    width: 100%;
    position: absolute;
    margin: 0 0 10px;
    z-index: 2;
    img{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto 0;
      height: 40%;
      padding: 10px 15px 10px 2px;
    }
  }

  .l-recruit-searchBox{
    text-align: center;
    input[type="text"]{
      width: 400px;
    }
  }

  .l-recruit-message-listTab{
    li{
      font-size: 14px;
      span{
        @include common_Height(40px);
        padding: 0 18px;
      }
      a{
        @include common_Height(40px);
        padding: 0 18px;
      }
    }
  }

  .l-recruit-message_list{
    width: $width_all;
  }

  .l-recruit-message_listImg{
    width: 60px;
  }

  .l-recruit-message_listName{
    width: 87%;
    span{
      font-size: 14px;
    }
    h3{
      font-size: 18px;
    }
    p{
      font-size: 14px;
    }
  }

  .l-recruit-message_chatArea{
    overflow-y: hidden;
    height: auto;
    padding: 0;
  }

  .l-recruit-message_chat{
    // background: url(../img/message_balloon_l.png) no-repeat 52px bottom;
    background-size: 16px auto;
    width: 75%;
    section{
      margin: 0 0 0 68px;
    }
  }

  .l-recruit-message_chat_self{
    // background: url(../img/message_balloon_r.png) no-repeat right bottom;
    background-size: 16px auto;
    padding: 0 16px 0 0;
    margin: 0 0 10px 0;
    section{
      padding: 12px;
      width: 68%;
    }
  }

  .l-recruit-message_chatImg{
    width: 50px;
    height: 50px;
  }

  .l-recruit-message_chatBox{
    span{
      font-size: 12px;
    }
    h3{
      font-size: 16px;
    }
    p{
      font-size: 14px;
    }
  }

  .l-recruit-message_textArea_pc{
    textarea{
	  height: 80px;
	}
  }

  .l-recruit-message_textArea_sp{
    background: $color_gray4;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding: 15px 0;
    border-top: solid 2px #ffffff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    textarea{
      font-size: 14px;
      padding: 5px;
      width: 95%;
    }
  }

  .l-recruit-message_textAreaFixing{
    background: $color_gray4;
    width: 100%;
    margin: 0 auto;
    padding: 10px 0;
    position: absolute;
    bottom: 202px;
    left: 0;
    right: 0;
    text-align: center;
    textarea{
      width: 90%;
      height: 80px;
      padding: 5px;
      resize: none;
      outline:none;
    }
  }

  .m-form-btn_preview{
    @include common_Height(45px);
    font-size: 14px;
    padding: 0 20px;
    position: relative;
    top: 0;
    right: 0;
  }

  .m-recruit-message_btn{
    float: none;
    width: 60%;
    margin: 5px auto 10px;
    display: block;
    @include common_Height(34px);
    position: relative;
    left: 0;
  }

  .m-recruit-rejectSend_btn{
    float: none;
    width: 40%;
    margin: 5px auto 10px;
	@include common_Height(34px);
  }

  .m-recruit-rejectNoSend_btn{
    float: none;
    width: 20%;
    margin: 0px 8% 0px 2px;
	@include common_Height(34px);
  }

  .m-recruit-rejectCancel_btn{
    float: none;
    width: 20%;
    margin: 0px 3% 0px 2px;
    @include common_Height(34px);
  }

  .m-recruit-employment_btn{
    float: none;
    width: 28%;
    margin: 0 2px;
    @include common_Height(34px);
  }

  .m-recruit-notAdopted_btn{
    float: none;
    width: 15%;
    margin: 0 2px;
    @include common_Height(34px);
  }

  .l-recruit-message_topbtnArea{
    display: none;
  }

  .m-recruit-arrivalBox{
    span{
      position: relative;
      top: 6px;
      font-size: 13px;
    }
  }

  .m-recruit-definition{
    margin: 0 0 0 10px;
    vertical-align: middle;
    position: relative;
    top: 4px;
	display:inline-block;
  }

  .m-recruit-userUniversity{
    max-width: 300px;
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-recruit-message_r{
    background: #ffffff;
    padding: 0 0 216px;
  }

  .l-recruit-searchBox{
    input[type="text"]{
      width: 260px;
    }
  }

  .l-recruit-message_textArea_sp{
    textarea{
      font-size: 12px;
    }
  }

  .l-recruit-message_chat{
    // background: url(../img/message_balloon_l.png) no-repeat 30px bottom;
    background-size: 16px auto;
    width: 80%;
    section{
      margin: 0 0 0 45px;
    }
  }

  .l-recruit-message_listName{
    width: 77%;
    span{
      font-size: 11px;
    }
    h3{
      font-size: 16px;
    }
    p{
      font-size: 14px;
    }
  }

  .l-recruit-message_chatImg{
    width: 25px;
    height: 25px;
  }

  .l-recruit-message_chatBox{
    span{
      font-size: 11px;
    }
    h3{
      font-size: 12px;
    }
    p{
      font-size: 13px;
    }
  }

  .l-recruit-message_textArea_pc{
    textarea{
	  height: 60px;
	}
  }

  .l-recruit-btnBox{
    p{
      span:before{
        content: "\A";
        white-space: pre;
      }
    }
  }

  .l-recruit-rejectBtnBox{
    p{
      span:before{
        content: "\A";
        white-space: pre;
      }
    }
  }

  .m-recruit-message_btn{
    width: 94%;
  }

  .m-recruit-rejectSend_btn{
    width: 94%;
	display: block;
    left: 0;
  }

  .m-recruit-rejectNoSend_btn{
    width: 50%;
	margin: 0px 7% 0px 0px;
  }

  .m-recruit-rejectCancel_btn{
    width: 34%;
  }

  .m-recruit-employment_btn{
    width: 35%;
  }

  .m-recruit-notAdopted_btn{
    width: 24%;
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .l-recruit-searchBox{
    input[type="text"]{
      width: 220px;
    }
  }

  .l-recruit-message_chat{
    // background: url(../img/message_balloon_l.png) no-repeat 32px bottom;
    background-size: 16px auto;
    width: 85%;
    section{
      margin: 0 0 0 48px;
    }
  }

  .l-recruit-message_chatImg{
    width: 32px;
    height: 32px;
  }

  .l-recruit-message_listImg{
    width: 50px;
  }

  .l-recruit-message_listName{
    width: 78%;
    p{
      font-size: 11px;
    }
  }

  .l-recruit-message-ico_unreadS{
    font-size: 10px;
  }

  .l-recruit-message-ico_notyetS{
    font-size: 10px;
  }

  .l-recruit-message_chatImg{
    width: 35px;
    height: 35px;
    margin: 0 15px 0 0;
  }

  .l-recruit-message_chatBox{
    span{
      font-size: 11px;
    }
    h3{
      font-size: 11px;
      margin: 0 0 2px;
    }
    p{
      font-size: 12px;
    }
  }
  .m-recruit-userUniversity{
    max-width: 155px;
  }

}

/***************************************
 管理画面（アカウント設定）
***************************************/

.l-account-listArea{
  padding: 0 5px 0 0;
    box-sizing: border-box;
  input[type="button"]{
    font-size: 12px;
  }
}

.l-account-list_title{
  padding: 10px 15px;
  display: table;
  width: $width_all;
  box-sizing: border-box;
  background: $color_gray3;
  li{
    font-weight: bold;
    display: table-cell;
    line-height: 1.3;
    box-sizing: border-box;
    word-wrap: break-word;
    vertical-align: middle;
  }
}

.m-account-list_name{
  width: 170px;
}

.m-account-list_mail{
  width: 300px;
}

.l-account-list{
  padding: 14px 15px;
  display: table;
  width: $width_all;
  background: #ffffff;
  box-sizing: border-box;
  border: solid 1px $color_gray3;
  border-top: none;
  li{
    display: table-cell;
    word-break: break-all;
    line-height: 1.3;
    box-sizing: border-box;
    word-wrap: break-word;
    vertical-align: middle;
  }
}

.l-account-list_self{
  background: #F0F5FF;
}

.l-account-list_self_form{
  background: #F0F5FF;
  text-align: center;
  input[type="email"]{
    display: block;
    margin: 5px auto;
  }
  input[type="password"]{
    display: block;
    margin: 5px auto;
  }
  input[type="button"]{
    display: block;
    margin: 8px auto;
    background-color: $color_blue1;
    border-bottom: solid 2px $color_blue2;
  }
  span{
    display: block;
    color: $color_red1;
    font-weight: bold;
    line-height: 1;
    margin: 0 0 8px;
  }
}

.m-account-list-btnBox{
  text-align: right;
}

.m-account-btn_gray{
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  vertical-align: top;
  @include common_Height(28px);
  border: none;
  padding: 0 10px 0 24px;
  // background: $color_gray1 url(../img/common_arrow_account.png) no-repeat 8px center;
  background-size: auto 30%;
  font-size: 12px;
}

.l-account_addBox{
  margin: 30px 0 0;
  border: solid 1px $color_gray3;
  h3{
    background: $color_gray3;
    @include common_Height(36px);
    padding: 0 10px;
  }
  div{
    margin: 20px;
    text-align: center;
    span{
      margin: 0 0 10px;
      display: block;
      line-height: 1.4;
      font-size: 12px;
    }
  }
}

.m-account-list_btn{
  display: inline-block;
  padding: 0 12px;
  margin: 0 1px;
  background: $color_blue1;
  color: #ffffff;
  border: none;
  border-bottom: solid 2px $color_blue2;
  border-radius: 3px;
  @include common_Height(36px);
  vertical-align: top;
  font-size: 14px;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-account-list_title{
    display: none;
  }

  .l-account-list_self{
    border-top: solid 1px $color_gray3;
  }

  .l-account-list{
    padding: 10px 5px;
    display: inline-block;
    li{
      padding: 3px 5px;
      margin: 0 15px 0 0;
      display: inline-block;
      line-height: 1.4;
      width: 100%;
    }
    .m-account-list-btnBox{
      display: block;
      text-align: right;
      margin: 0;
    }
  }

  .l-account_addBox{
    span{
      text-align: left;
    }
  }

  .m-account-list_btn{
    display: inline-block;
    padding: 0 12px;
    margin: 10px 1px 0;
    width: 50%;
    background: $color_blue1;
    color: #ffffff;
    border: none;
    border-bottom: solid 2px $color_blue2;
    border-radius: 3px;
    @include common_Height(36px);
    vertical-align: top;
    font-size: 14px;
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-account-list{
    .m-account-list-btnBox{
      a{
        display: block;
        margin: 0 0 5px;
      }
    }
  }

}

/***************************************
 管理画面（原稿内容管理）
***************************************/

.l-recruit-header{
  overflow: hidden;
  margin: 0 0 20px;
  position: relative;
}

.l-recruit-listTab{
  overflow: hidden;
  li{
    float: left;
    text-align: center;
    font-size: 15px;
    img{
      width: 15px;
      display: block;
      margin: 0 auto;
    }
    span{
      display: block;
      @include common_Height(46px);
      background: $color_base;
      color: #ffffff;
      padding: 0 30px;
      border-right: solid 1px #ffffff;
    }
    a{
      display: block;
      @include common_Height(46px);
      background: #C3C3C3;
      color: $color_gray1;
      padding: 0 30px;
      border-right: solid 1px #ffffff;
    }
  }
}

.l-recruit-entryComments{
  float:right;
  padding: 2px;
  border: solid 2px red;
  border-radius: 7px;
  li{
    font-size: 12px;
  }
}

.l-recruit-list-searchBox{
  position: absolute;
  top: 0;
  right: 0;
}

.l-recruit-listBack{
  display: inline-block;
  @include common_Height(40px);
  background: #C3C3C3;
  color: $color_gray1;
  padding: 0 20px 0 35px;
  // background: url(../img/common_arrow_back.png) $color_gray1 no-repeat 15px center;
  background-size: auto 35%;
  color: #ffffff;
}

.l-recruit-top_jobArea{
  overflow: hidden;
}

.l-recruit-top_jobBox{
  margin: 0 0 15px;
  padding: 15px;
  overflow: hidden;
  background: $color_gray4;
  border-radius: 4px;
  border: solid 1px #DEDEDE;
  box-sizing: border-box;
  position: relative;
  float: left;
  width: $width_all / 2 - 1;
  &:nth-child(even){
    float: right;
  }
  h3{
    font-size: 14px;
    line-height: 1.4;
    margin: 5px 0;
    color: $color_base;
  }
  &.l-recruit-createNew{
    position: relative;
    display: table;
    margin: 0 auto;
    border: dashed 1px #D4D4D4;
    div{
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      span{
        font-size: 38px;
        color: $color_gray3;
      }
    }
  }
}

.m-recruit-top_img{
  position: relative;
  width: 150px;
  height: 113px;
  background: #cccccc;
  float: left;
  margin: 0 15px 0 0;
  overflow: hidden;
  img{
    width: 100%;
  }
}
.m-recruit-top_cover{
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 110%;
}
.m-recruit-top_title{
  width: 196px;
  float: left;
  color: $color_base;
  margin: -4px 0 0;
}

.m-recruit-imgPosition{
  display: block;
  width: 90px;
  margin: 8px 0 0;
}

.l-recruit-imgDlBg{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: $width_all;
  height: $width_all;
  margin: auto;
  background: rgba(30, 30, 30, 0.7);
  z-index: 100;
}

.l-recruit-imgDlArea{
  width: 800px;
  height: 80%;
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  overflow: auto;
  padding: 50px 25px 30px;
  box-sizing: border-box;
  text-align: center;
}

.l-recruit-imgDlBox{
  overflow: hidden;
  display: inline-block;
  li{
    position: relative;
    width: 22%;
    margin: 10px 10px 20px;
    text-align: center;
    float: left;
    div{
      height: 116px;
      margin: 0 0 15px;
    }
    img{
      width: 100%;
    }
    .m-recruit-btnDelete{
      position: absolute;
      top: -10px;
      right: -10px;
      border-radius: 11px;
      border: solid 1px $color_gray1;;
      background: #ffffff;
      span{
        color: $color_gray1;
      }
      &:hover{
        opacity: 1;
      }
    }
  }
}

.l-recruit-imgDl_dl{
  text-align: center;
  padding: 10px 20px 20px;
  margin: 10px 0 0;
  background: $color_gray4;
  p{
    width: 100%;
    font-size: 12px;
    margin: 12px auto;
    line-height: 1.4;
  }
  a{
    margin: 5px 0 0;
  }
}

.m-recruit-selectImg{
  width: 200px;
  display: block;
  margin: 0 0 10px;
}

.m-recruit-selectLogo{
  width: 120px;
  display: block;
  margin: 0 0 10px;
}

.l-recruit-notesBox{
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  margin: 0 auto 20px;
  text-align: left;
  overflow: hidden;
  background: $color_gray4;
  border-radius: 4px;
  border: solid 1px #DEDEDE;
  line-height: 1.4;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-recruit-listTab{
    li{
      img{
        width: 10px;
      }
      span{
        @include common_Height(40px);
        padding: 0 15px;
      }
      a{
        @include common_Height(40px);
        padding: 0 15px;
      }
    }
  }

  .l-recruit-entryComments{
    li{
	  width: 270px;
	  font-size: 12px;
	}
  }

  .l-recruit-top_jobBox{
    padding: 10px;
    float: none;
    width: $width_all;
    &:nth-child(even){
      float: none;
    }
  }

  .m-recruit-top_img{
    width: 130px;
    height: 98px;
  }

  .m-recruit-top_title{
    width: 70%;
  }

  .m-recruit-imgPosition{
    display: block;
    width: 60px;
    margin: 5px 0;
  }

  .l-recruit-imgDlArea{
    width: 86%;
    height: 86%;
    padding: 40px 15px 15px;
  }

  .l-recruit-imgDlBox{
    li{
      width: 120px;
      div{
        height: 90px;
        margin: 0 0 10px;
      }
    }
  }

  .l-recruit-imgDl_dl{
    p{
      text-align: left;
    }
  }

  .l-recruit-list-searchBox{
    position: relative;
    top: 0;
    left: 0;
    display: block;
    text-align: left;
    margin: 15px 0 0;
  }

  .l-recruit-notesBox{
    padding: 20px;
    text-align: left;
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-recruit-header{
    margin: 0 0 15px;
  }

  .l-recruit-listTab{
    li{
      font-size: 14px;
    }
  }

  .l-recruit-entryComments{
    li{
	  width: 130px;
	  font-size: 10px;
	}
  }

  .l-recruit-top_jobBox{
    margin: 0 0 10px;
    h3{
      font-size: 13px;
    }
  }

  .m-recruit-top_img{
    width: 110px;
    height: 83px;
    margin: 0 10px 0 0;
  }

  .m-recruit-top_title{
    width: 64%;
  }

  .l-recruit-imgDlArea{
    width: 86%;
    height: 86%;
    padding: 45px 15px 15px;
  }

  .l-recruit-imgDlBox{
    li{
      margin: 10px;
      div{
        margin: 0 0 7px;
      }
    }
  }

  .l-recruit-imgDl_dl{
    padding: 2px 15px 10px;
    margin: 5px 0 0;
    background: $color_gray4;
    p{
      width: 100%;
      font-size: 11px;
      margin: 12px auto;
      text-align: left;
    }
    a{
      margin: 5px 0 0;
    }
  }

  .m-recruit-selectImg{
    width: 150px;
    display: block;
    margin: 0 0 10px;
  }

  .m-recruit-selectLogo{
    width: 100px;
    display: block;
    margin: 0 0 10px;
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .l-recruit-header{
    margin: 0 0 10px;
  }

  .l-recruit-listTab{
    li{
      font-size: 11px;
      span{
        @include common_Height(38px);
        padding: 0 10px;
      }
      a{
        @include common_Height(38px);
        padding: 0 12px;
      }
    }
  }

  .l-recruit-entryComments{
    li{
	  width: 100px;
	  font-size: 9px;
	}
  }

  .l-recruit-top_jobBox{
    h3{
      font-size: 12px;
    }
  }

  .m-recruit-top_img{
    width: 90px;
    height: 68px;
  }

  .m-recruit-top_title{
    width: 63%;
  }

  .l-recruit-imgDlArea{
    width: 90%;
    height: 90%;
    padding: 45px 15px 15px;
  }

  .l-recruit-imgDlBox{
    li{
      width: 100px;
      margin: 14px;
      div{
        margin: 0 0 7px;
        height: 75px;
      }
    }
  }

  .l-recruit-notesBox{
    padding: 15px;
    font-size: 12px;
  }

}

/***************************************
 管理画面（応募履歴）
***************************************/

.l-recruit-history_jobBox{
  margin: 0 0 15px;
  padding: 18px 15px 15px;
  overflow: hidden;
  background: $color_gray4;
  border-radius: 4px;
  border: solid 1px #DEDEDE;
  box-sizing: border-box;
  position: relative;
  h3{
    font-size: 16px;
    line-height: 1.4;
    color: $color_gray1;
    margin: 8px 0 0;
  }
  h4{
    font-size: 18px;
    line-height: 1.4;
    padding: 8px 0 0;
    color: $color_base;
  }
}

.m-recruit-history_date{
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  padding: 0 10px 0 0;
}

.l-recruit-history_icoBox{
  display: inline-block;
}

.m-recruit-history_img{
  width: 115px;
  height: auto;
  background: #cccccc;
  float: left;
  margin: 0 15px 0 0;
  overflow: hidden;
  img{
    width: 100%;
  }
}
.m-recruit-history_title{
  width: 78%;
  float: left;
  color: $color_base;
  margin: -4px 0 0;
}

.l-recruit-history_btnBox{
  position: absolute;
  top: 15px;
  right: 15px;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-recruit-history_jobBox{
    padding: 10px;
    float: none;
    width: $width_all;
    &:nth-child(even){
      float: none;
    }
    h3{
      font-size: 14px;
    }
    h4{
      font-size: 15px;
      padding: 5px 0;
    }
  }

  .l-recruit-history_icoBox{
    display: block;
  }

  .m-recruit-history_img{
    width: 150px;
    margin: 0 15px 10px 0;
  }

  .m-recruit-history_title{
    width: 68%;
  }

  .l-recruit-history_btnBox{
    position: relative;
    top: 0;
    left: 0;
    padding: 10px 0 0;
    clear: both;
    border-top: solid 1px $color_gray3;
    text-align: center;
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-recruit-history_jobArea{
    margin: 0 0 20px;
  }

  .l-recruit-history_jobBox{
    margin: 0 0 10px;
    h3{
      font-size: 11px;
    }
    h4{
      font-size: 12px;
      padding: 5px 0 10px;
    }
  }

  .m-recruit-history_img{
    width: 110px;
    margin: 0 10px 0 0;
  }

  .m-recruit-history_title{
    width: 62%;
  }

  .m-recruit-history_date{
    font-size: 12px;
  }

  .l-recruit-history_btnBox{
    a{
      margin: 0 0 8px;
    }
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .l-recruit-history_jobBox{
    h3{
      font-size: 11px;
    }
    h4{
      font-size: 12px;
    }
  }

  .m-recruit-history_img{
    width: 58px;
  }

  .m-recruit-history_date{
    font-size: 10px;
  }

  .l-recruit-history_icoBox{
    span{
      font-size: 9px;
    }
  }

  .m-recruit-history_title{
    width: 75%;
  }

}

/***************************************
 管理画面（推薦状）
***************************************/

.l-reference-summaryArea{
  width: 80%;
  margin: 0 auto 20px;
  h3{
    margin: 0 0 20px;
    font-size: 22px;
    line-height: 1.5;
    text-align: center;
    span:before{
      content: "\A";
      white-space: pre;
    }
  }
  p{
    span{
      color: $color_red1;
      font-weight: bold;
    }
  }
  small{
    display: block;
    line-height: 1.4;
    margin: 6px 0 0;
    font-size: 11px;
  }
}

.l-recruit-referenceBox{
  width: 92%;
  margin: 0 auto;
  p{
    margin: 0 0 10px;
    line-height: 1.4;
  }
}

.l-recruit-formBg .l-recruit-reference_title{
  line-height: 1.4;
  width: 100%;
  margin: 0 0 10px;
}

.l-recruit-referenceForm{
  text-align: center;
  p{
    text-align: left;
  }
  .m-recruit-textarea{
    width: 100%;
  }
  input{
    margin: 0 auto;
    font-size: 12px;
  }
}

.l-recruit-referenceTextArea{
  margin: 30px 0;
  border: solid 1px $color_gray1;
  border-radius: 4px;
  overflow: hidden;
  h2{
    background: $color_gray1;
    color: #ffffff;
    font-size: 16px;
    @include common_Height(42px);
    padding: 0 15px;
    font-weight: normal;
  }
  section{
    padding: 12px 15px;
    border-bottom: dotted 1px $color_gray3;
    &:last-child{
      border: none;
    }
    h3{
      margin: 0 0 5px;
      font-size: 14px;
    }
    p{
      margin: 0;
      font-size: 12px;
    }
    span{
      display: block;
      text-align: right;
      font-size: 11px;
    }
  }
}

.m-reference-send_btn{
  display: inline-block;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  @include common_Height(28px);
  font-size: 14px;
  background: $color_green1;
  border-bottom: solid 2px $color_green2;
  margin: 0 0 0 5px;
  width: 125px;
  text-align: center;
}

.m-reference-notAdopted_btn{
  display: inline-block;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  @include common_Height(28px);
  font-size: 14px;
  background: $color_blue1;
  border-bottom: solid 2px $color_blue2;
  margin: 0 0 0 5px;
  width: 100px;
  text-align: center;
}

.m-reference-edit_btn{
  display: inline-block;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  @include common_Height(28px);
  font-size: 14px;
  background: $color_blue1;
  border-bottom: solid 2px $color_blue2;
  margin: 0 0 0 5px;
  width: 100px;
  text-align: center;
}

.m-reference-delete_btn{
  display: inline-block;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  @include common_Height(28px);
  font-size: 14px;
  background: $color_gray1;
  border-bottom: solid 2px #6f6f6f;
  margin: 0 0 0 5px;
  width: 80px;
  text-align: center;
}

.m-reference-deleteAll_btn{
  display: inline-block;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  @include common_Height(28px);
  font-size: 14px;
  background: $color_gray1;
  border-bottom: solid 2px #6f6f6f;
  margin: 0 0 0 5px;
  width: 100px;
  text-align: center;
}

.m-reference-save_btn{
  display: inline-block;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  @include common_Height(28px);
  font-size: 14px;
  background: $color_blue1;
  border-bottom: solid 2px $color_blue2;
  margin: 0 0 0 5px;
  width: 100px;
  text-align: center;
}

.l-reference-listBg{
  background: $color_gray4;
  border-radius: 4px;
  padding: 10px 0;
  margin: 0 auto 15px;
  overflow: hidden;
  width: 80%;
}

.l-reference_list{
  width: 90%;
  margin: 0 auto;
  padding: 0;
  li{
    overflow: hidden;
    padding: 10px 5px 8px;
    border-bottom: dotted 1px $color_gray3;
    &:last-child{
      border: none;
    }
    label{
      font-weight: bold;
      margin: 0 10px 10px 0;
    }
    div{
      display: inline-block;
      float: right;
    }
  }
}

.l-reference-deleteAllBox{
  width: 80%;
  margin: 0 auto;
  text-align: right;
  img{
    width: 11px;
    vertical-align: middle;
    position: relative;
    top: -1px;
    margin: 0 0 0 5px;
  }
}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-reference-summaryArea{
    width: 100%;
    h3{
      margin: 0 0 15px;
      font-size: 12px;
      text-align: left;
      span:before{
        content: normal;
      }
    }
    p{
      font-size: 12px;
      small{
        font-size: 10px;
      }
    }
  }

  .l-recruit-referenceBox{
    p{
      padding: 10px 0 0;
      font-size: 12px;
    }
  }

  .l-recruit-formBg .l-recruit-reference_title{
    font-size: 13px;
    margin: 0 0 5px;
    padding: 0 0 5px;
  }

  .l-recruit-referenceTextArea{
    margin: 20px 0;
    h2{
      font-weight: normal;
      @include common_Height(40px);
      padding: 0 10px;
    }
  }

  .l-reference-listBg{
    padding: 5px 0;
    margin: 0 auto 10px;
    width: 100%;
  }

  .l-reference_list{
    width: 95%;
    li{
      font-size: 12px;
      overflow: hidden;
      padding: 9px 2px 5px;
      border-bottom: dotted 1px $color_gray3;
      &:last-child{
        border: none;
      }
      label{
        font-weight: bold;
      }
      div{
        display: inline-block;
        float: right;
      }
    }
  }

  .m-reference-edit_btn{
    font-size: 12px;
    @include common_Height(22px);
    width: 55px;
  }

  .m-reference-delete_btn{
    font-size: 12px;
    @include common_Height(22px);
    width: 45px;
  }

  .m-reference-deleteAll_btn{
    @include common_Height(22px);
    font-size: 12px;
    width: 70px;
    margin: 0 0 0 3px;
  }

  .l-reference-deleteAllBox{
    width: 100%;
    font-size: 12px;
    img{
      width: 9px;
      position: relative;
      top: -2px;
    }
  }

}

/***************************************
 管理画面（一覧）
***************************************/

.l-user-list_title{
  padding: 0 5px 10px;
  display: table;
  width: $width_all;
  box-sizing: border-box;
  li{
    padding: 0 8px;
    font-size: 12px;
    font-weight: bold;
    display: table-cell;
    line-height: 1.3;
    box-sizing: border-box;
    word-wrap: break-word;
    vertical-align: middle;
  }
}

.l-user-listArea{
  overflow-y: auto;
  max-height: 500px;
  ul{
    &:nth-child(odd){
      background: $color_gray4;
    }
  }
}

.l-user-list{
  padding: 15px 5px;
  display: table;
  width: $width_all;
  box-sizing: border-box;
  li{
    padding: 0 8px;
    font-size: 12px;
    display: table-cell;
    word-break: break-all;

    line-height: 1.3;
    box-sizing: border-box;
    word-wrap: break-word;
    vertical-align: middle;
  }
}

.m-user-list40{
  width: 40px;
}

.m-user-list60{
  width: 60px;
}

.m-user-list80{
  width: 80px;
}

.m-user-list100{
  width: 100px;
}

.m-user-list120{
  width: 120px;
}

.m-user-list150{
  width: 150px;
}

.m-user-list180{
  width: 180px;
}

.m-user-list-btnBox{
  text-align: right;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-user-list_title{
    display: none;
  }

  .l-user-list{
    padding: 10px 5px;
    display: inline-block;
    li{
      padding: 3px 5px;
      margin: 0 15px 0 0;
      display: inline-block;
      line-height: 1.4;
    }
    .m-user-list-btnBox{
      display: block;
      margin: 0;
    }
  }

  .m-user-list40{
    width: auto;
  }

  .m-user-list60{
    width: auto;
  }

  .m-user-list100{
    width: auto;
  }

  .m-user-list120{
    width: auto;
  }

  .m-user-list150{
    width: auto;
  }

  .m-user-list180{
    width: auto;
  }

}

/***************************************
 管理画面（お気に入り登録）
***************************************/

.l-recruit-favoriteArea{
  width: 85%;
  margin: 0 auto;
}

.l-recruit-catchSelect{
  margin: 25px 0;
  select{
    width: 100%;
    height: 42px;
    border: solid 1px #C6D2E6;
    // background: #F0F5FF url(../img/common_arrow_catch.png) right center no-repeat;
    background-size: auto 30%;
    color: $color_blue1;
  }
}

.m-user-list-number{
  margin: 0 0 8px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.4;
  span{
    font-size: 16px;
    padding: 0 3px;
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-recruit-favoriteArea{
    width: 100%;
  }

  .l-recruit-catchSelect{
    select{
      font-size: 12px;
    }
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-recruit-catchSelect{
    margin: 15px 0;
    select{
      font-size: 11px;
      height: 38px;
    }
  }

  .m-user-list-number{
    font-size: 10px;
    font-weight: bold;
    span{
      font-size: 16px;
      padding: 0 3px;
    }
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .l-recruit-favoriteSelect{
    select{
      font-size: 9px;
    }
  }

}

/***************************************
 管理画面（友達紹介）
***************************************/

.l-friend_addBox{
  border: solid 1px $color_gray3;
  h3{
    background: $color_gray3;
    @include common_Height(36px);
    padding: 0 10px;
  }
  div{
    margin: 20px;
    text-align: center;
    span{
      margin: 0 0 10px;
      display: block;
      line-height: 1.4;
      font-size: 12px;
    }
  }
}

.m-friend-list_btn{
  display: inline-block;
  padding: 0 12px;
  margin: 0 1px;
  background: $color_blue1;
  color: #ffffff;
  border: none;
  border-bottom: solid 2px $color_blue2;
  border-radius: 3px;
  @include common_Height(36px);
  vertical-align: top;
  font-size: 14px;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-friend_addBox{
    span{
      text-align: left;
    }
  }

  .m-friend-list_btn{
    display: inline-block;
    padding: 0 12px;
    margin: 10px 1px 0;
    width: 50%;
    background: $color_blue1;
    color: #ffffff;
    border: none;
    border-bottom: solid 2px $color_blue2;
    border-radius: 3px;
    @include common_Height(36px);
    vertical-align: top;
    font-size: 14px;
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-friend-summary{
    font-size: 12px;
  }

}


/***************************************
 マスター画面（コラム・インタビュー一覧）
***************************************/

.l-column-listArea{
  overflow-y: auto;
  max-height: 500px;
  ul{
    &:nth-child(odd){
      background: $color_gray4;
    }
  }
}

.l-column-list{
  padding: 15px 5px;
  display: table;
  width: $width_all;
  box-sizing: border-box;
  li{
    font-size: 13px;
    padding: 0 8px;
    display: table-cell;
    word-break: break-all;
    line-height: 1.3;
    box-sizing: border-box;
    word-wrap: break-word;
    vertical-align: middle;
  }
}

.l-common-side-searchBox{
  input[type="text"].l-column-searchBox{
    width: 155px;
  }
}

.m-column-list70{
  width: 70px;
}

.m-column-list160{
  width: 160px;
}

.m-column-list460{
  width: 460px;
}

.m-column-list-btnBox{
  text-align: right;
}

@mixin l-column-listIco($ico_color) {
  display: inline-block;
  background: #ffffff;
  padding: 0 10px;
  @include common_Height(25px);
  color: $ico_color;
  border: solid 1px $ico_color;
}

.l-column-listIco_reserve{
  @include l-column-listIco($color_green1);
}

.l-column-listIco_publish{
  @include l-column-listIco($color_red1);
}

.l-column-listIco_hide{
  @include l-column-listIco($color_gray1);
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-column-list_title{
    display: none;
  }

  .l-column-list{
    padding: 10px 5px;
    display: inline-block;
    li{
      padding: 3px 5px;
      margin: 0 15px 0 0;
      display: inline-block;
      line-height: 1.4;
    }
    .m-column-list-btnBox{
      display: block;
      margin: 0;
    }
  }


  .m-column-list70{
    width: auto;
  }

  .m-column-list160{
    width: auto;
  }

  .m-column-list460{
    width: auto;
  }

}

/***************************************
 マスター画面（コラム・インタビュー編集）
***************************************/

.l-recruit-formBg.l-column-formBg dl dt{
  width: 100px;
}

.l-recruit-formBg.l-column-formBg dl dd{
  width: 600px;
  textarea{
    font-size: 14px;
    padding: 3px 5px;
  }
}

.l-column-editBtnArea{
  margin: 0 0 20px;
  overflow: hidden;
}

.l-column-editBtnBox{
  float: right;
}

.m-column-btn_gray{
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  vertical-align: top;
  @include common_Height(36px);
  background: $color_gray1;
  border: none;
  padding: 0 10px;
  font-weight: normal;
}

.m-column-btn_red{
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  vertical-align: top;
  @include common_Height(36px);
  background: $color_red1;
  border: none;
  padding: 0 10px;
  font-weight: normal;
}

.m-column-btn_new{
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  vertical-align: top;
  @include common_Height(38px);
  background: $color_red1;
  border: none;
  padding: 0 10px;
  font-weight: normal;
  font-size: 14px;
  margin: 0 0 0 10px;
}

input.m-recruit-btn_arrival{
  display: inline-block;
  border-radius: 3px;
  border: none;
  border-bottom: solid 2px #C54B38;
  color: #ffffff;
  text-align: center;
  vertical-align: top;
  @include common_Height(30px);
  background: $color_red1;
  font-size: 13px;
  padding: 0 10px;
  font-weight: normal;
  cursor: pointer;
  width: 120px;
  &:hover{
    @include common_Alpha;
  }
}

input.m-recruit-btn_arrival_date{
  display: inline-block;
  border-radius: 3px;
  border: none;
  border-bottom: solid 2px #C54B38;
  color: #ffffff;
  text-align: center;
  vertical-align: top;
  @include common_Height(30px);
  background: $color_red1;
  font-size: 14px;
  padding: 0 8px;
  font-weight: normal;
  cursor: pointer;
  &:hover{
    @include common_Alpha;
  }
}

input.m-recruit-btn_arrival_confirm{
  display: inline-block;
  border-radius: 3px;
  border: solid 1px #EAABA2;
  color: #DA705F;
  text-align: center;
  vertical-align: top;
  @include common_Height(30px);
  background: #FFDAD4;
  font-size: 14px;
  padding: 0 8px;
  font-weight: normal;
  &:hover{
    opacity: 1;
  }
  &:placeholder-shown {
      color: #DA705F;
  }
  &::-webkit-input-placeholder {
      color: #DA705F;
  }
  &:-moz-placeholder {
      color: #DA705F;
      opacity: 1;
  }
  &::-moz-placeholder {
      color: #DA705F;
      opacity: 1;
  }
  &:-ms-input-placeholder {
      color: #DA705F;
  }
}

.picker__box__description {
  padding: 10px 10px;
  font-size: 16px;
  line-height: 1.5;
  > span {
    font-weight: bold;
    color: #DA705F;
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-recruit-formBg.l-column-formBg dl dt{
    width: 100%;
  }

  .l-recruit-formBg.l-column-formBg dl dd{
    width: 100%;
  }

  input.m-recruit-btn_arrival_date{
    @include common_Height(36px);
  }

  input.m-recruit-btn_arrival_confirm{
    @include common_Height(36px);
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-column-editBtnBox{
    margin: 10px 0 0;
    float: none;
  }

}
