.i-top-content {

	&__aside-internships-slider {
		margin-top: 15px;

		&.hide {

			@include media ('<768px') {
				padding-left: 15px;
				padding-right: 15px;
			}

			.i-top-content__aside-internships-swiper {
				display: none;
			}

			.swiper-pagination {
				display: none;
			}

			.i-top-content__aside-slide-one {
				display: block;
			}
		}

		.swiper-pagination {
			display: flex;
			justify-content: center;
			position: relative;
			bottom: 0;
			margin-top: 20px;

			@include media ('>767px') {
				display: none;
			}

			.swiper-pagination-bullet {
				background-color: #CECECE;
				opacity: 1;

				&.swiper-pagination-bullet-active {
					background-color: #848484;
				}
			}
		}
	}

	&__aside-internships-swiper {

		.swiper-wrapper {
			padding-bottom: 2px;

			@include media ('>767px') {
				display: block;
			}

			.swiper-slide {

				@include media ('<768px') {
					height: 100%;
				}

				&:not(:first-child) {
					margin-top: 12px;

					@include media ('<768px') {
						margin-top: 0;
					}
				}
			}
		}
	}

	&__aside-slide {
		display: block;
		background-color: #FFFFFF;
		box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
		border-radius: 4px;
		padding: 10px 15px;

		@include media ('<768px') {
			height: 100%;
			padding: 20px;
		}

		.i-subtitle {
			display: block;
			font-size: 10px;
			line-height: 15px;
			color: #555555;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.i-block-title {
			margin-top: 10px;
			font-weight: 700;
			font-size: 15px;
			line-height: 20px;
			color: #1E85A9;
		}
	}

	&__aside-slide-one {
		display: none;
		width: 100%;
		background: rgba(255, 255, 255, 0.75);
		border-radius: 4px;
		padding: 22px 20px;

		@include media ('<768px') {
			max-width: 260px;
			margin-left: auto;
			margin-right: auto;
			padding: 23px 15px;
		}

		.i-block-title {
			font-size: 14px;
			line-height: 25px;
			font-weight: 700;
			text-align: center;

			@include media ('<768px') {
				font-size: 12px;
				line-height: 20px;
			}
		}

		.i-text {
			margin: 0;
			font-size: 11px;
			line-height: 20px;
			font-weight: 400;
			text-align: center;

			@include media ('<768px') {
				font-size: 10px;
				line-height: 17px;
			}
		}
	}
}
