/* text align */

.i-text-center {
	text-align: center;
}

/* text weight */

.i-text-bold {
	font-weight: 700;
}

/* text colors */

.i-text-yellow {
	color: #F7A300;
}

.i-text-red {
	color: #F55E46;
}

.i-text-gray {
	color: #848484;
}
.i-text-gray_second {
	color: #AEAEAE;
}

.i-text-blue {
	color: #1E85A9;
}

.i-text-black {
	color: #555555;
}
