.i-search {
	padding-top: 40px;

	@include media ('<768px') {
		padding-top: 0;
	}

	.i-container {
		display: block;
		width: auto;
		max-width: 1020px;
		margin-left: auto;
		margin-right: auto;

		@include media ('>767px') {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	&__inner {
		display: flex;
	}

	&__body {
		flex-grow: 1;
		padding-right: 55px;

		@include media ('<768px') {
			padding-right: 0;
		}
	}

	&__station {
		background-color: #FFFFFF;
		box-shadow: 0 1px 3px 2px rgba(0, 0, 0, 0.15);
		border-radius: 4px;

		@include media ('<768px') {
			box-shadow: none;
			border-radius: 0;
		}

		&.i-mh580 {
			min-height: 580px;
		}
	}

	&__station-row {
		position: relative;
		border-bottom: 1px solid #D3D3D3;
		padding: 15px 25px;

		@include media ('<768px') {
			padding: 15px;
		}

		&.i-last {
			border-bottom: none;
			padding-bottom: 0;
		}
	}

	&__station-back {
		display: flex;
		align-items: center;
		width: max-content;
	}

	&__station-back-arrow {
		display: block;
		width: 12px;
		height: 13px;
		margin-right: 7px;

		svg, path {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	&__station-back-text {
		display: block;
		font-size: 14px;
		line-height: 21px;
		color: #1E85A9;
	}

	&__station-back-text--line {
		text-decoration: underline;
	}

	&__station-find {
		width: 100%;
		max-width: 380px;
		margin-left: auto;
		margin-right: auto;

		@include media ('<768px') {
			max-width: 100%;
			padding-left: 30px;
			padding-right: 30px;
		}

		.i-find {

			&__form {
				margin-top: 6px;

				@include media ('<768px') {
					padding-left: 0;
					padding-right: 0;
				}
			}

			&__form-group {
				max-width: 100%;
			}

			&__input {
				input {
					@include media ('<768px') {
						background-color: #EFEFEF;
					}
				}
			}
		}
	}

	&__station-find-title {
		font-weight: 700;
		font-size: 14px;
		line-height: 21px;
		color: #555555;
	}

	&__station-find-subtitle {
		font-weight: 700;
		font-size: 14px;
		line-height: 21px;
		color: #555555;
		margin: 15px 0 0 0;

		@include media ('<768px') {
			font-size: 12px;
			line-height: 18px;
		}

		.i-red {
			font-size: 20px;
			line-height: 30px;
			color: #FF594F;
		}
	}

	&__station-next {
		display: flex;
		align-items: center;
		justify-content: space-between;
		column-gap: 7px;
	}

	&__station-next-text {
		display: block;
		font-size: 16px;
		line-height: 24px;
		color: #555555;
	}

	&__station-next-arrow {
		display: block;
		width: 15px;
		height: 13px;

		svg,path {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	&__station-title-wrap {
		margin-top: 50px;
		padding-left: 25px;
		padding-right: 25px;

		@include media ('<768px') {
			margin-top: 30px;
		}
	}

	&__station-title {
		font-weight: 700;
		font-size: 20px;
		line-height: 23px;
		text-align: center;
		color: #555555;

		@include media ('<768px') {
			font-size: 16px;
			line-height: 17px;
		}
	}

	&__station-subtitle {
		margin-top: 15px;
		font-size: 16px;
		line-height: 17px;
		text-align: center;
		color: #555555;

		@include media ('<768px') {
			margin-top: 10px;
			font-size: 12px;
			line-height: 17px;
		}
	}

	&__aside {
		width: 300px;
		flex-shrink: 0;

		@include media ('<768px') {
			display: none;
		}
	}

	&__aside-list {

	}

	&__aside-item {
		&:not(:first-child) {
			margin-top: 8px;
		}
	}

	&__aside-link {
		display: flex;
		align-items: center;
		height: 70px;
		background-color: #E0E0E0;
		border-radius: 5px;
		padding: 17px 25px;

		@at-root :root.hover &:hover {
			background-color: #666666;

			.i-search__aside-link-icon {
				svg,path {
					fill: #ffffff;
				}
			}

			.i-search__aside-link-text {
				color: #FFFFFF;
			}
		}

		&.active {
			background-color: #666666;
			pointer-events: none;

			.i-search__aside-link-icon {
				svg,path,rect {
					fill: #ffffff;
				}
			}

			.i-search__aside-link-text {
				color: #FFFFFF;
			}
		}
	}

	&__aside-link-icon {
		display: block;
		width: 30px;
		height: 100%;
		max-height: 36px;
		flex-shrink: 0;

		svg,path {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	&__aside-link-text {
		display: block;
		flex-grow: 1;
		padding-left: 15px;
		font-size: 18px;
		line-height: 27px;
		font-weight: 700;
		color: #AEAEAE;
	}
}
