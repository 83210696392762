.i-internship {
	margin-bottom: 0;
}
.i-internship-title {
	font-size: 12px;
	line-height: 18px;
	font-weight: 300;
}
.i-internship-list {
	box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	margin-top: 12px;
	overflow: hidden;
}
.i-internship-item {
	display: flex;
	border-bottom: 1px solid #D3D3D3;
	min-height: 52px;

	&:nth-child(3) {
		.i-internship-left-img {
			max-width: 12px;
		}
	}
	&:nth-child(4) {
		.i-internship-left-img {
			max-width: 14px;
		}
	}
	&:nth-child(5) {
		.i-internship-left-img {
			max-width: 18px;
		}
	}
}
.i-internship-left {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 70px;
	background-color: #1E85A9;
	padding: 7px 3px;
	text-align: center;
}
.i-internship-left-img {
	display: block;
	width: 100%;
	max-width: 16px;
	margin-right: auto;
	margin-bottom: 3px;
	margin-left: auto;
	img {
		display: block;
		width: 100%;
	}
}
.i-internship-left-title {
	font-size: 11px;
	line-height: 16px;
	font-weight: 300;
	color: #fff;
	margin: 0;

}
.i-internship-right {
	width: calc(100% - 70px);
}
.i-internship-right-link {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 12px;
	background-color: transparent;
	width: 100%;
	height: 100%;
	border: none;

	@media(max-width: 767px) {
		padding: 5px 7px;
	}

	&.active {
		pointer-events: none;
		font-weight: 600;
		color: #555555;
	}
}
.i-internship-right-form {
	width: 100%;
}
.i-internship-right-label {
	display: block;
	width: 100%;
}
.i-internship-right-input {
	display: block;
	width: 100%;
	background-color: #EFEFEF;
	border-radius: 4px;
	padding: 10px 12px;
	border: 0;
	box-shadow: none;
	font-size: 14px;
	line-height: 21px;
	font-weight: 300;
	box-sizing: border-box !important;
	&::placeholder {
		color: #AEAEAE;
	}
}
.i-internship-right-search {
	display: flex;

	padding: 0;
	height: 22px;
	width: 22px;
	border: 0;
	border-radius: 0;
	background-color: transparent;

	transition: 0.2s ease;
	// defaults
	cursor: pointer;
	outline: none;

	position: absolute;
	top: 50%;
	right: 25px;
	z-index: 1;
	transform: translateY(-50%);

	img {
		display: block;
		width: 100%;
	}
}
.i-internship-right-text {
	font-size: 14px;
	line-height: 21px;
	font-weight: 300;
	color: #AEAEAE;
	white-space: nowrap;
	@media(max-width: 767px) {
		font-size: 13px;
		line-height: 19px;
	}
}
.i-internship-right-btn {
	display: block;
	padding: 0;
	width: 100%;
	max-width: 42px;

	position: relative;
	text-align: left;
	white-space: nowrap;

	font-style: normal;
	font-weight: 300;
	font-size: 14px;
	line-height: 21px;
	color: #1E85A9;

	transition: 0.2s ease;
	// defaults
	cursor: pointer;
	outline: none;
	@media(max-width: 767px) {
		font-size: 12px;
		line-height: 18px;
	}
	&:after {
		content: "";
		display: block;
		width: 8px;
		height: 13px;
		position: absolute;
		top: 50%;
		right: 0;
		z-index: 1;
		transform: translateY(-50%);
		background-image: url("../img/arrow-right-blue.svg");
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
	}
}

.i-internship-content {
	padding-top: 30px;
	padding-bottom: 30px;
	text-align: center;
}
.i-internship-maintitle {
	font-size: 20px;
	line-height: 23px;
	font-weight: 600;
}
.i-internship-subtitle {
	font-size: 16px;
	line-height: 17px;
	font-weight: 300;
}

.i-internship-search {
	margin-bottom: 20px;
}

@media (max-width: 767px) {
	.i-internship-search {
		margin-bottom: 0;
	}

	.i-internship-content {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.i-internship-maintitle {
		font-size: 18px;
		line-height: 21px;
	}
	.i-internship-subtitle {
		font-size: 14px;
		line-height: 15px;
		margin: 12px 0;
	}
}
