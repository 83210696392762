/* app */

.app {
	display: block;
	width: 100%;
	max-width: 100%;
	min-height: 1px;
}

.dot {
	display: inline-block;
	position: relative;

	&:before {
		content: '';
		display: block;
		position: absolute;
		bottom: 100%;
		left: 50%;
		transform: translateX(-50%);
		width: 9px;
		height: 9px;
		border-radius: 100%;
		background-color: #ffffff;

		@include media ('<768px') {
			width: 5px;
			height: 5px;
		}
	}
}

.i-job-list-banner {
	position: relative;
	width: 100%;
	max-width: 400px;
	margin-top: 15px;
	margin-left: auto;
	margin-right: auto;

	@include media ('<768px') {
		padding-left: 10px;
		padding-right: 10px;
	}

	+ .i-job-item {
		margin-top: 15px;
	}

	&__inner {
		display: flex;
		background-color: #E7F0F9;
		column-gap: 22px;
		padding-left: 48px;
		padding-right: 10px;
		max-height: 70px;

		@include media ('<768px') {
			padding-left: 20px;
		}
	}

	&__arrow {
		display: block;
		position: absolute;
		left: 105px;
		bottom: 45px;
		width: auto;
		height: 50px;

		@include media ('<768px') {
			left: 85px;
		}

		svg,path {
			display: block;
			width: auto;
			height: 100%;
		}
	}

	&__icon {
		display: block;
		width: 65px;
		height: 74px;
		margin-top: -5px;

		svg,path {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	&__desc {
		padding-top: 9px;
		padding-bottom: 14px;

		@include media ('<768px') {
			padding-top: 11px;
			padding-bottom: 13px;
		}

		.i-block-title {
			font-weight: 700;
			font-size: 20px;
			line-height: 26px;
			color: #555555;

			@include media ('<768px') {
				font-size: 18px;
				line-height: 26px;
			}
		}

		.i-text {
			margin: 7px 0 0 0;
			font-size: 15px;
			line-height: 18px;
			color: #555555;

			@include media ('<768px') {
				margin-top: 5px;
				font-size: 13px;
				line-height: 15px;
			}
		}
	}
}

*:placeholder-shown {
	color: #000000;
}
