@charset "utf-8";

// ▼Sass設定

@import "_variables.scss";

/***************************************
 リセット
***************************************/

html{
  height: 100%;
}

html > * {
 -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body{
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS P Gothic", Verdana, sans-serif;
  background: #ffffff;
  margin: 0;
  height: 100%;
  line-height: 1.8;
  font-size: 14px;
  color: $color_base;
}

a{
  color: $color_blue1;
  text-decoration: none;
  &:hover{
    @include common_Alpha;
  }
}

input:focus{
  outline: none;
}

textarea:focus{
  outline: none;
}

select:focus{
  outline: none;
}

input[type="submit"]{
  line-height: 1;
  -webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
  &:hover{
    @include common_Alpha;
  }
}

input[type="button"]{
  line-height: 1;
  -webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
  &:hover{
    @include common_Alpha;
  }
}

@mixin form_textbox {
  border: solid 1px #CECECE;
  border-radius: 0;
  padding: 3px 5px;
  vertical-align: top;
  box-sizing: border-box;
  height: 36px;
  -webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

input[type="text"]{
  @include form_textbox;
}

input[type="password"]{
  @include form_textbox;
}

input[type="email"]{
  @include form_textbox;
}

select::-ms-expand{
  display: none;
}

input[type=radio],
input[type=checkbox] {
    display: inline-block;
    margin-right: 6px;
}
input[type=radio] + label,
input[type=checkbox] + label {
    position: relative;
    display: inline-block;
    margin-right: 12px;
    line-height: 30px;
    cursor: pointer;
}

@media (min-width: 1px) {
    input[type=radio],
    input[type=checkbox] {
        display: none;
        margin: 0;
    }
    input[type=radio] + label {
        padding: 0 0 0 20px;
        margin: 0 14px 0 0;
        line-height: 1.0;
    }
    input[type=checkbox] + label {
        padding: 0 0 0 20px;
        margin: 0 14px 14px 0;
        line-height: 1.0;
    }
    input[type=radio] + label::before,
    input[type=checkbox] + label::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
        width: 16px;
        height: 16px;
        margin-top: -8px;
        background: #FFF;
    }
    input[type=radio] + label::before {
        border: 2px solid #ccc;
        border-radius: 30px;
    }
    input[type=checkbox] + label::before {
        border: 2px solid #ccc;
    }
    input[type=radio]:checked + label::after,
    input[type=checkbox]:checked + label::after {
        content: "";
        position: absolute;
        top: 50%;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
    }
    input[type=radio]:checked + label::after {
        left: 3px;
        width: 10px;
        height: 10px;
        margin-top: -5px;
        background: $color_gray2;
        border-radius: 8px;
    }
    input[type=checkbox]:checked + label::after {
        left: 3px;
        width: 13px;
        height: 8px;
        margin-top: -6px;
        border-left: 3px solid $color_gray2;
        border-bottom: 3px solid $color_gray2;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}

h1, h2, h3, h4{
  margin: 0;
}

ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

img{
  vertical-align: top;
}

/***************************************
 要素出し分け
***************************************/

/* ######### 768px以上 ######### */
@media (min-width: 768px) {

  .display-none_pc{
    display: none;
  }

  .display-none_sp{
    display: block;
  }

}

/* ######### 767px以下 ######### */
@media (max-width: 767px) {

  .display-none_pc{
    display: block;
  }

  .display-none_sp{
    display: none;
  }

}

/***************************************
 全体レイアウト
***************************************/

.l-wrap{
  width: $width_all;
  position: relative;
  min-height: 100%;
}

.l-contents{
  padding: 0 0 440px; /* フッターの高さ */
  margin: 30px auto 0;
  overflow: hidden;
  width: $width_base;
  .l-contents-l{
    position: relative;
    width: $width_left;
    float: left;
    margin: 10px 0 0;
  }
  .l-contents-r{
    position: relative;
    width: $width_right;
    float: right;
    margin: 10px 0 0;
  }
  .l-contents-join{
    position: relative;
    width: 900px;
    margin: 10px auto 40px;
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-contents{
    padding: 0 0 400px; /* フッターの高さ */
    margin: 20px auto 0;
    width: $width_all;
    z-index: 1;
    position: relative;
    overflow: visible;
    .l-contents-l{
      width: $width_sp;
      float: none;
      margin: 0 auto 15px;
    }
    .l-contents-r{
      width: $width_sp;
      float: none;
      margin: 0 auto;
    }
    .l-contents-join{
      width: $width_sp;
      margin: 0 auto 40px;
    }
  }


}

/***************************************
 ヘッダー
***************************************/

header{
  border-bottom: solid 1px #dddddd;
  background: #ffffff;
}

.l-common-headerArea{
  width: $width_base;
  margin: 0 auto;
  padding: 0 0 18px;
  position: relative;
}

.m-common-headerTitle{
  display: inline-block;
  font-size: 11px;
  margin: 0 0 10px;
  line-height: 1;
  font-weight: normal;
  color: $color_gray3;
}

.m-common-headerLogo{
  h2{
    display: inline-block;
  }
  a{
    display: block;
    width: 150px;
    height: auto;
    img{
      width: 100%;
    }
    &:hover{
      @include common_Alpha(1);
    }
  }
}

.l-common-headerMenuArea_pc{
  position: absolute;
  top: 0;
  right: 0;
  padding: 25px 0 0;
}
.l-common-headerMenuArea_sp{
  display: none;
}

.l-common-headerNav_pc{
  display: inline-block;
  margin: 0 10px 0 0;
  ul{
    li{
      display: inline-block;
      background: url(../img/common_arrow_rg.png) no-repeat;
      background-size: auto 100%;
      line-height: 1;
      text-indent: 14px;
      font-weight: bold;
      margin: 0 16px 0 0;
      a{
        color: $color_base;
      }
    }
  }
}

.l-common-headerBtn{
  @include common_Height(38px);
  background: $color_red1;
  border-radius: 4px;
  color: #ffffff;
  text-align: center;
  display: inline-block;
  margin: 0 0 0 3px;
  padding: 0 12px;
}

.l-common-headerPulldown_pc{
  display: inline-block;
  position: relative;
  ul{
    position: absolute;
    width: 100%;
    background: $color_blue1;
    z-index: 1;
    li{
      border-bottom: dotted 1px $color_blue3;
      &:last-child{
        border: none;
      }
      a{
        display: block;
        color: #ffffff;
        line-height: 1;
        padding: 12px;
      }
    }
  }
}

.l-common-headerPulldownBtn_pc{
  @include common_Height(38px);
  background: $color_blue3;
  color:  $color_blue1;
  display: inline-block;
  padding: 0 33px 0 12px;
  // background: url(../img/index_arrow_pulldown_03.png) $color_blue3 no-repeat right center;
  min-width: 100px;
}

.l-common-headerSearchArea_sp{
  display: none;
}

.m-common-header_ico{
  display: inline-block;
  background: $color_red1;
  @include common_Height(22px);
  font-size: 12px;
  border-radius: 11px;
  color: #ffffff;
  text-align: center;
  text-indent: 0;
  min-width: 14px;
  padding: 0 4px;
  position: relative;
  top: 0;
  left: -4px;
}

.l-common-headerMenu_display-none {
  display: none;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-common-headerArea{
    width: $width_all;
    margin: 0 auto;
    padding: 6px 0 2px;
  }

  .m-common-headerTitle{
    display: none;
  }

  .m-common-headerLogo{
    a{
      margin: 0 0 0 10px;
      img{
        width: 98px;
        margin: 3px 0 0;
      }
    }
  }

  .l-common-headerMenuArea{
    display: none;
  }

  .l-common-headerMenuArea_pc{
    display: none;
  }
  .l-common-headerMenuArea_sp{
    display: block;
    position: absolute;
    top: 12px;
    right: 10px;
  }

  .l-common-headerBtn{
    @include common_Height(25px);
    background: $color_red1;
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    display: inline-block;
    position: relative;
    top: -2px;
    margin: 0 0 0 2px;
    padding: 0 8px;
    font-size: 12px;
  }

  .l-common-headerBtn_mypage{
    @include common_Height(25px);
    background: $color_blue1;
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    display: inline-block;
    position: relative;
    top: -2px;
    margin: 0 0 0 2px;
    padding: 0 8px;
    font-size: 12px;
  }

  .l-common-headerMenu_sp{
    width: 24px;
    margin: 0 0 0 10px;
  }

  .l-common-headerMenu-list_sp{
    z-index: 3;
    position: absolute;
    right: 0;
    background: $color_gray1;
    width: 80%;
    ul{
      display: block;
      li{
        line-height: 1;
        border-bottom: dotted 1px $color_gray2;
        &:last-child{
          border: none;
        }
        a{
          padding: 16px 12px;
          display: block;
          color: #ffffff;
        }
        .m-common-headerMenu-scout_sp{
          display: #448737;
        }
      }
    }
  }
  .sp_menu_svg {
    margin-right: 5px;
  }

  .l-common-headerSearchArea_sp{
    display: block;
    margin: 0 0 20px;
    -webkit-order:3;
    order: 3;
    input[type="text"]{
      @include common_Height(40px);
      border: solid 1px $color_gray3;
      border-right: none;
      border-radius: 4px 0 0 4px;
      box-sizing: border-box;
      vertical-align: top;
      padding: 0 8px;
      display: inline-block;
      width: 90%;
      font-size: 14px;
      -webkit-appearance: none;
    }
    input[type="submit"]{
      border: 0;
      // background: url(../img/common_icon_search.png) $color_gray1 no-repeat center;
      background-size: 20px auto;
      @include common_Height(40px);
      width: 10%;
      border-radius: 0 4px 4px 0;
      display: inline-block;
    }
  }

  .m-common-header_ico{
    @include common_Height(18px);
    font-size: 10px;
    border-radius: 9px;
    color: #ffffff;
    min-width: 10px;
    position: relative;
    top: -2px;
    left: -4px;
  }

  .m-common-header_ico-message{
    display: inline-block;
    background: #fff;
    font-size: 12px;
    text-indent: 0;
    padding: 0 4px;
    @include common_Height(25px);
    font-size: 12px;
    color: #F55E46;
    min-width: 10px;
    border-bottom: 2px solid #F55E46;
    > i {
      margin-right: 3px;
      > img {
        max-width: 22px;
      }
    }
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-common-headerSearchArea_sp{
    input[type="text"]{
      width: 82%;
    }
    input[type="submit"]{
      width: 18%;
    }
  }

}

/***************************************
 フッター
***************************************/

footer{
  width: $width_all;
  position: absolute;
  bottom: 0;
  overflow: hidden;
  z-index: 2;
}

.l-common-pagetopBox{
  width: $width_base;
  margin: 0 auto 5px;
  text-align: right;
  a{
    display: inline-block;
    @include common_bgImg(42px, 42px);
    border-radius: 4px;
    border: solid 1px #dddddd;
    background: url(../img/common_arrow_pagetop.png) #ffffff no-repeat center;
    background-size: 60%;
  }
}

.l-common-footerBox{
  background: #eeeeee;
  padding: 30px 0 10px;
  text-align: center;
  small{
    color: $color_gray1;
  }
}

.m-common-footerLogo{
  width: 110px;
}

.l-common-footerMenu{
  margin: 20px 0;
  dl{
    text-align: left;
    width: 185px;
    margin: 0;
    display: inline-block;
    vertical-align: top;
    dt{
      font-weight: bold;
      color: $color_gray1;
      font-size: 13px;
      margin: 10px 0 0;
      a{
        color: $color_gray1;
      }
    }
    dd{
      font-size: 12px;
      display: block;
      margin: 0;
      line-height: 2.0;
      a{
        color: $color_gray2;
      }
      img{
        margin: 5px 0 0;
        height: 30px;
      }
    }
  }
}

.l-common-footerFb_pc{
  background: #ffffff;
  box-sizing: border-box;
  width: 370px;
  display: inline-block;
  margin: 15px 0 0;
  .fb-page{
    width: auto;
  }
}

.l-common-footerFb_sp{
  display: none;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-common-pagetopBox{
    width: $width_sp;
    a{
      @include common_bgImg(36px, 36px);
      background-size: 55%;
    }
  }

  .l-common-footerBox{
    padding: 15px 0 5px;
    small{
      font-size: 10px;
    }
  }

  .m-common-footerLogo{
    width: 80px;
  }

  .l-common-footerMenu{
    margin: 10px 0 25px;
    dl{
      text-align: center;
      width: 90%;
      dt{
        margin: 5px 0 0;
        display: inline-block;
        font-size: 10px;
      }
      dd{
        display: inline-block;
        margin: 0 6px;
        img{
          height: 22px;
        }
      }
    }
    .l-common-footerMenu-style_sp{
      display: none;
    }
  }

  .l-common-footerFb_pc{
    display: none;
  }

  .l-common-footerFb_sp{
    width: 93%;
    display: block;
    text-align: center;
    background: #ffffff;
    box-sizing: border-box;
    margin: 15px auto;
    .fb-page{
      width: 100%;
    }
  }

}

/***************************************
共通パーツ
***************************************/

.l-common-baseArea{
  margin: 0 0 30px;
  overflow: hidden;
}

.m-common-h2{
  @include common_Height(42px);
  border-radius: 3px;
  color: #ffffff;
  padding: 0 10px;
  font-size: 17px;
  font-weight: normal;
  background: $color_blue2;
  margin: 0 0 20px;
}

.m-common-h3{
  border-bottom: solid 2px $color_blue2;
  padding: 0 10px 6px;
  margin: 10px 0;
  font-size: 16px;
  line-height: 1.5;
}

.l-common-interview-categoryBox{
  margin: 0 0 20px;
  overflow: hidden;
  text-align: center;
  li{
    display: inline-block;
    margin: 0 22px 0 0;
    text-indent: 11px;
    background: url(../img/common_arrow_rg.png) no-repeat left center;
    background-size: auto 50%;
    font-weight: bold;
    &:last-child{
      margin: 0;
    }
    a{
      color: $color_base;
      position: relative;
      top: 1px;
    }
  }
}

.l-common-column-categoryBox{
  margin: 0 0 20px;
  overflow: hidden;
  text-align: center;
  li{
    display: inline-block;
    margin: 0 22px 0 0;
    text-indent: 11px;
    background: url(../img/common_arrow_rg.png) no-repeat left center;
    background-size: auto 50%;
    font-weight: bold;
    &:last-child{
      margin: 0;
    }
    a{
      color: $color_base;
      position: relative;
      top: 1px;
    }
  }
}

.l-common-interviewPickupArea{
  flex-direction: row wrap;
}

.l-common-interviewPickup{
  width: ($width_left - 15) / 2;
  display: block;
  margin: 15px 0 0;
  &:nth-child(odd){
    float: left;
  }
  &:nth-child(even){
    float: right;
  }
  &:nth-child(-n+2){
    margin: 0;
  }
  div{
    height: 180px;
    overflow: hidden;
    position: relative;
    img{
      width: 100%;
    }
  }
  h3{
    font-size: 16px;
    background: $color_blue4;
    padding: 10px;
    color: $color_base;
    line-height: 1.4;
    text-justify: inter-ideograph;
  }
}

@mixin interview_ico_base {
  display: inline-block;
  color: #ffffff;
  @include common_Height(25px);
  @include common_Alpha(0.8);
  border-radius: 0 0 5px 0;
  width: 150px;
  text-indent: 8px;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

/* 経営者インタビュー（インタビュー） */
.m-common-interview_icoGreen{
  @include interview_ico_base;
  background: #3AAD33;
}

/* 活躍中の社員（インタビュー） */
.m-common-interview_icoRed{
  @include interview_ico_base;
  background: #FF6C9B;
}

/* インターン経験者の声（インタビュー） */
.m-common-interview_icoOrange{
  @include interview_ico_base;
  background: #FF9222;
}

/* 気になるオフィス見学（インタビュー） */
.m-common-interview_icoPurple{
  @include interview_ico_base;
  background: #8F5DF1;
}

.l-common-sideBox_job{
  padding: 15px 0 10px;
  overflow: hidden;
  border-bottom: dotted 1px $color_gray2;
  line-height: 1.6;
  &:hover{
    @include common_Alpha;
  }
  .m-common-sideImg_job{
    width: 126px;
    height: auto;
    background: #cccccc;
    float: left;
    margin: 0 10px 0 0;
    overflow: hidden;
    img{
      width: 100%;
    }
  }
  .m-common-sideTitle_job{
    width: 160px;
    float: left;
    color: $color_base;
    margin: -4px 0 0;
  }
  h3{
    font-size: 12px;
    font-weight: normal;
    line-height: 1.4;
    color: $color_gray1;
    margin: 0 0 3px;
  }
  h4{
    font-size: 13px;
    line-height: 1.4;
    margin: 3px 0 0;
  }
}

.m-common-sideIcn_coat{
  display: inline-block;
  background: $color_blue1;
  color: #ffffff;
  border: solid 1px $color_blue1;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 11px;
  margin: 0 2px 0 0;
  line-height: 1.2;
}

.m-common-sideIcn_frame{
  display: inline-block;
  border: solid 1px $color_gray1;
  color: $color_gray1;
  background: #ffffff;
  border-radius: 3px;
  font-size: 11px;
  margin: 0 2px 0 0;
  padding: 2px 5px;
  line-height: 1.2;
}

.m-common-sideIcn_reference{
  display: inline-block;
  border: solid 1px $color_red1;
  color: $color_red1;
  background: #ffffff;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 11px;
  margin: 0 2px 0 0;
  line-height: 1.2;
}

.m-common-sideIcn_online{
  display: inline-block;
  border: solid 1px $color_gray1;
  color: $color_gray1;
  background: #ffffff;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 11px;
  margin: 0 2px 0 0;
  line-height: 1.2;
}

.l-common-sideBox_co{
  border: solid 1px $color_gray3;
  box-sizing: border-box;
  margin: 15px 0;
  padding: 10px 10px 5px;
  overflow: hidden;
  &:hover{
    @include common_Alpha;
  }
  div{
    position: relative;
    display: block;
    width: 100%;
    height: 150px;
    overflow: hidden;
    margin: 0 0 5px;
  }
  img{
    width: 100%;
    position: relative;
    top: -10px;
  }
  span{
    position: relative;
    top: -1px;
    margin: 0 0 0 10px;
  }
  h3{
    display: inline-block;
    font-size: 14px;
    color: $color_base;
    line-height: 1.5;
  }
}

.l-common-sideBox_interview{
  border: solid 1px $color_gray3;
  box-sizing: border-box;
  margin: 15px 0;
  padding: 10px 10px 8px;
  overflow: hidden;
  &:hover{
    @include common_Alpha;
  }
  div{
    position: relative;
    display: block;
    width: 100%;
    height: 150px;
    overflow: hidden;
    margin: 0 0 6px;
  }
  img{
    width: 100%;
    position: relative;
    top: -10px;
  }
  span{
    display: block;
  }
  h3{
    display: inline-block;
    font-size: 14px;
    color: $color_base;
    line-height: 1.5;
    margin: 0;
  }
}

.l-common-baseBox{
  display: block;
  float: left;
  width: ($width_left - 30) / 3;
  box-sizing: border-box;
  margin: 15px 15px 0 0;
  line-height: 1.6;
  &:hover{
    @include common_Alpha;
  }
  &:nth-child(3n){
    margin-right: 0;
  }
  &:nth-child(-n+3){
    margin-top: 0;
  }
  img{
    width: 100%;
    display: block;
  }
}

.l-common-similarBox{

}

.l-common-baseBox_text{
  h3{
    color: $color_base;
    line-height: 1.4;
    font-size: 16px;
    text-justify: inter-ideograph;
  }
  .m-common-corporation-name {
    color: $color_gray1;
    line-height: 1.4;
    font-size: 13px;
    display: block;
    margin: 8px 0 0;
  }
}

.l-common-joblistBox{
  overflow: hidden;
  border-top: solid 1px $color_gray3;
  margin: 8px 0 0;
  position: relative;
  &:last-child{
    padding: 0 0 8px;
    border-bottom: solid 1px $color_gray3;
  }
  h3{
    font-size: 16px;
    line-height: 1.3;
    padding: 8px 10px;
    margin: 0 0 15px;
    width: 100%;
    background: $color_gray4;
    span{
      display: inline;
      vertical-align: middle;
    }
    .m-common-sideIcn_frame{
      display: inline;
      font-size: 13px;
      font-weight: normal;
      margin: 0 6px 0 0;
      padding: 3px 3px 3px 5px;
    }
  }
  h4{
    font-size: 20px;
    line-height: 1.3;
    margin: 0 0 5px;
    color: $color_base;
    word-wrap: break-word;
    &:hover{
      @include common_Alpha($value: 0.7);
    }
  }
  p{
    font-size: 12px;
    line-height: 1.5;
    color: $color_gray2;
    margin: 16px 0;
  }
}

.l-common-joblist_imgBox{
  width: 40%;
  box-sizing: border-box;
  display: block;
  margin: 0 20px 0 0;
  float: left;
  img{
    width: 100%;
    margin: 3px 0 0;
    display: block;
    &:hover{
      @include common_Alpha($value: 0.7);
    }
  }
}

.l-common-joblist_text{
  box-sizing: border-box;
  width: 56%;
  float: left;
  dl{
    margin: 0 0 8px;
    line-height: 1.4;
    dt{
      display: inline-block;
      width: 62px;
      color: $color_gray1;
      background: #DEDEDE;
      text-align: center;
      padding: 1px 0;
      margin: 0 4px 0 0;
      font-size: 13px;
      vertical-align: top;
    }
    dd{
      margin: 0;
      width: 80%;
      display: inline-block;
      word-wrap: break-word;
    }
  }
}

.l-joblist-btnBox{
  clear: both;
  border-top: dotted 1px #DADADA;
  margin: 10px 0;
  padding: 10px 0 0;
  width: 100%;
  text-align: right;
}

.m-joblist-btn_detail{
  display: inline-block;
  font-size: 15px;
  border-radius: 5px;
  @include common_Height(47px);
  background: $color_blue1;
  border-bottom: solid 4px #136A88;
  width: 320px;
  color: #ffffff;
  margin: 5px 5px 0;
  text-align: center;
}

.m-common-joblist_favbtn{
  display: inline-block;
  font-size: 15px;
  border-radius: 5px;
  @include common_Height(47px);
  background: $color_green1;
  border-bottom: solid 4px $color_green2;
  width: 200px;
  color: #ffffff;
  margin: 5px 5px 0;
  text-align: center;
}

.m-common-joblist_favbtn_selected{
  display: inline-block;
  font-size: 15px;
  border-radius: 5px;
  @include common_Height(51px);
  background: $color_gray3;
  width: 200px;
  color: #ffffff;
  margin: 5px 5px 0;
  text-align: center;
}

.l-common-joblist_ico{
  margin: 0 0 15px;
}

.m-job-list_not_accept_job_pc {
  font-size: 13px;
  font-weight: normal;
  margin: 0 15px 0 3px;
  padding: 3px 5px;
  border: solid 1px #F55E46;
  color: #F55E46;
  background: #ffffff;
  border-radius: 3px;
  line-height: 1.2;
  float: right;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-common-baseArea{
    margin: 0 0 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
  }

  .l-common-baseBox{
    width: ($width_all - 3) / 2;
    margin: 16px 0 0;
    &:nth-child(3n){
      margin-right: 0;
    }
    &:nth-child(-n+3){
      margin-top: 16px;
    }
    &:nth-child(even){
      float: right;
    }
    &:nth-child(-n+2){
      margin-top: 0;
    }
  }

  .l-common-similarBox {
  }

  .l-common-baseBox_text{
    h3{
      font-size: 16px;
    }
  }

  .m-common-h2{
    height: auto;
    padding: 8px 10px;
    font-size: 16px;
    margin: 0 0 15px;
    line-height: 1.4;
  }

  .m-common-h3{
    border-bottom: solid 2px $color_blue2;
    padding: 0 5px 5px;
    margin: 10px 0;
    font-size: 15px;
    line-height: 1.5;
  }

  .l-common-interview-categoryBox{
    margin: 20px 0 10px;
    text-align: left;
    border: solid 1px $color_gray3;
    font-size: 11px;
    -webkit-order:2;
    order: 2;
    li{
      width: $width_all / 2;
      display: block;
      float: left;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      text-indent: 15px;
      border-bottom: dotted 1px $color_gray3;
      border-right: dotted 1px $color_gray3;
      background-size: auto 30%;
      background-position: 10px center;
      &:nth-last-child(-n+2){
        border-bottom: none;
      }
      &:nth-child(even){
        border-right: none;
      }
      a{
        display: block;
        position: relative;
        top: 0;
        padding: 10px 5px;
      }
    }
  }

  .l-common-column-categoryBox{
    margin: 0 0 10px;
    text-align: left;
    border: solid 1px $color_gray3;
    font-size: 11px;
    -webkit-order:2;
    order: 2;
    li{
      width: $width_all / 2;
      display: block;
      float: left;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      text-indent: 15px;
      border-bottom: dotted 1px $color_gray3;
      border-right: dotted 1px $color_gray3;
      background-size: auto 30%;
      background-position: 10px center;
      &:nth-last-child(-n+2){
        border-bottom: none;
      }
      &:nth-child(even){
        border-right: none;
      }
      a{
        display: block;
        position: relative;
        top: 0;
        padding: 10px 5px;
      }
    }
  }

  .l-common-interviewPickupArea{
    -webkit-order:1;
    order: 1;
  }

  .l-common-interviewPickup{
    width: ($width_all - 2) / 2;
    margin: 12px 0 0;
    div{
      height: auto;
    }
    h3{
      font-size: 16px;
      padding: 10px;
    }
  }

  @mixin interview_ico_base {
    display: block;
    @include common_Height(25px);
    border-radius: 0;
    min-width: 100%;
    text-indent: 8px;
    font-size: 13px;
    position: absolute;
    top: inherit;
    bottom: 0;
  }

  .m-common-interview_icoGreen{
    @include interview_ico_base;
  }

  .m-common-interview_icoRed{
    @include interview_ico_base;
  }

  .m-common-interview_icoOrange{
    @include interview_ico_base;
  }

  .m-common-interview_icoPurple{
    @include interview_ico_base;
  }

  .l-common-sideBox_job{
    .m-common-sideImg_job{
      width: 38%;
    }
    .m-common-sideTitle_job{
      width: 60%;
    }
    h3{
      font-size: 14px;
    }
    h4{
      font-size: 17px;
      margin: 10px 0 0;
    }
  }

  .l-common-sideBox_co{
    div{
      height: 300px;
    }
    h3{
      margin: 3px 0;
      font-size: 14px;
    }
  }

  .l-common-sideBox_interview{
    border: none;
    margin: 0;
    padding: 10px 10px 8px 0;
    border-bottom: dotted 1px #a1a1a1;
    div{
      width: 40%;
      height: 130px;
      overflow: hidden;
      margin: 0 0 6px;
      float: left;
      img{
        position: relative;
        top: 0;
      }
    }
    h3{
      box-sizing: border-box;
      font-size: 17px;
      padding: 0 0 0 10px;
      float: left;
      width: 60%;
    }
  }

  .l-common-joblistBox{
    padding: 0 0 5px;
  }

  .l-common-joblist_imgBox{
    width: 24%;
    overflow: hidden;
    position: relative;
    img{
      width: 100%;
      display: block;
      &:hover{
        @include common_Alpha($value: 1);
      }
    }
  }

  .l-common-joblist_text{
    width: 70%;
    position: relative;
    left: 27%;
    min-height: 300px;
    dl{
      dt{
        width: 65px;
      }
    }
  }

  .l-common-joblist_link{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    &:hover{
      @include common_Alpha($value: 1.0);
    }
  }

  .l-common-joblist_fav_sp{
    box-sizing: border-box;
    text-align: center;
    z-index: 3;
  }

  .m-common-joblist_favbtn_sp{
    font-size: 12px;
    display: block;
    color: #ffffff;
    background: $color_green1;
    border-radius: 3px;
    border-bottom: solid 3px $color_green2;
    padding: 2px;
    margin: 15px 0 5px;
    @include common_Height(28px);
  }

  .m-common-joblist_favbtn_selected_sp{
    font-size: 12px;
    display: block;
    color: #ffffff;
    background: $color_gray3;
    border-radius: 3px;
    padding: 2px;
    margin: 15px 0 5px;
    @include common_Height(31px);
  }

  .m-common-joblist_favbtnOff_sp{
    font-size: 11px;
    display: block;
    color: #ffffff;
    background: $color_green1;
    border-radius: 3px;
    border-bottom: solid 3px $color_green2;
    padding: 10px 2px;
    margin: 15px 0 5px;
    line-height: 1.3;
    br{
      display: none;
    }
  }

  .m-common-joblist_favbtnOff_selected_sp{
    font-size: 11px;
    display: block;
    color: #ffffff;
    background: $color_gray3;
    border-radius: 3px;
    padding: 12px 2px 11px;
    margin: 15px 0 5px;
    line-height: 1.3;
    br{
      display: none;
    }
  }


  .l-common-joblist_detail_sp{
    box-sizing: border-box;
    text-align: center;
    z-index: 3;
    margin: 10px 0px 5px;
  }

  .m-common-joblist_detail_sp{
    font-size: 12px;
    display: table-cell;
    color: #ffffff;
    background: $color_blue1;
    border-radius: 3px;
    border-bottom: solid 3px #136A88;
    padding: 2px;
    height: 54px;
    width: 30%;
    text-align: center;
    vertical-align: middle;
  }

  .m-job-list_not_accept_job_sp {
    text-align: center;
    margin: 10px 0px 5px;
    font-size: 13px;
    color: #F55E46;
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-common-baseBox{
    width: ($width_all - 3) / 2;
    margin: 10px 0 0;
    &:nth-child(3n){
      margin-right: 0;
    }
    &:nth-child(-n+3){
      margin-top: 10px;
    }
    &:nth-child(even){
      float: right;
    }
    &:nth-child(-n+2){
      margin-top: 0;
    }
  }

  .l-common-baseBox_text{
    h3{
      font-size: 12px;
    }
    .m-common-corporation-name{
      font-size: 11px;
    }
  }

  .m-common-h3{
    border-bottom: solid 2px $color_blue2;
    padding: 0 5px 3px;
    margin: 10px 0;
    font-size: 14px;
    line-height: 1.5;
  }

  .l-common-interviewPickup{
    width: ($width_all - 3) / 2;
    h3{
      font-size: 13px;
      padding: 10px 8px;
    }
  }

  @mixin interview_ico_base {
    @include common_Height(20px);
    font-size: 11px;
  }

  .m-common-interview_icoGreen{
    @include interview_ico_base;
  }
  .m-common-interview_icoRed{
    @include interview_ico_base;
  }
  .m-common-interview_icoOrange{
    @include interview_ico_base;
  }
  .m-common-interview_icoPurple{
    @include interview_ico_base;
  }

  .l-common-sideBox_job{
    .m-common-sideImg_job{
      width: 40%;
    }
    .m-common-sideTitle_job{
      width: 55%;
    }
    h3{
      font-size: 12px;
    }
    h4{
      font-size: 13px;
      margin: 5px 0 0;
    }
  }

  .l-common-sideBox_co{
    div{
      height: 190px;
    }
  }

  .l-common-sideBox_interview{
    div{
      height: 80px;
      margin: 0 0 6px;
    }
    h3{
      font-size: 13px;
      padding: 0 0 0 10px;
    }
  }

  .m-common-sideIcn_coat{
    white-space: nowrap;
    display: inline;
    font-size: 9px;
    padding: 2px 4px;
    margin: 0 4px 0 0;
  }

  .m-common-sideIcn_frame{
    white-space: nowrap;
    display: inline;
    font-size: 9px;
    padding: 2px 4px;
    margin: 0 4px 0 0;
  }

  .m-common-sideIcn_reference{
    white-space: nowrap;
    display: inline;
    font-size: 9px;
    padding: 2px 4px;
    margin: 0 4px 0 0;
  }

  .m-common-sideIcn_online{
    white-space: nowrap;
    display: inline;
    font-size: 9px;
    padding: 2px 4px;
    margin: 0 4px 0 0;
  }

  .l-common-joblistBox{
    margin: 0;
    h3{
      font-size: 13px;
      padding: 7px 5px;
      margin: 0 0 10px;
      .m-common-sideIcn_frame{
        font-size: 11px;
      }
    }
    h4{
      font-size: 17px;
      margin: 0;
    }
    dl{
      margin: 0 0 5px;
      dt{
        font-size: 11px;
        width: 55px;
        margin: 0 1px 0 0;
        font-weight: normal;
      }
      dd{
        font-size: 12px;
        width: 72%;
      }
    }
  }

  .l-common-joblist_imgBox{
    width: 30%;
    overflow: hidden;
    position: relative;
  }

  .l-common-joblist_text{
    width: 67%;
    position: relative;
    left: 33%;
    min-height: 250px;
  }

  .l-common-joblist_fav_sp{
    font-size: 11px;
  }

  .l-common-joblist_favIcon_sp{
    padding: 3px;
    margin: 15px 0 10px;
  }

  .l-common-joblist_favIcon_settled_sp{
    margin: 15px 0 10px;
  }

  .m-common-joblist_favbtnOff_sp{
    font-size: 10px;
    padding: 4px 2px;
    br{
      display: inline;
    }
  }

  .m-common-joblist_favbtnOff_selected_sp{
    font-size: 10px;
    padding: 6px 2px 5px;
    br{
      display: inline;
    }
  }

  .l-common-joblist_detail_sp{
    font-size: 11px;
  }

  .l-common-joblist_ico{
    margin: 0 0 8px;
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .l-common-interviewPickup{
    width: ($width_all - 3) / 2;
  }

  .l-common-joblistBox{
    h3{
      font-size: 12px;
    }
    h4{
      font-size: 13px;
    }
    dl{
      margin: 0 0 4px;
      dt{
        font-size: 9px;
        width: 47px;
      }
      dd{
        width: 73%;
        font-size: 11px;
      }
    }
  }

  .l-common-joblist_imgBox{
    width: 27%;
    overflow: hidden;
    position: relative;
  }

  .l-common-joblist_text{
    width: 70%;
    position: relative;
    left: 30%;
    min-height: 230px;
  }

  .l-common-joblist_favIcon{
    font-size: 10px;
    padding: 4px 1px;
  }

  .l-common-joblist_favIcon_settled{
    font-size: 10px;
    padding: 4px 1px;
  }

}

/***************************************
 トピックパス
***************************************/

.l-common-topicpass_pc{
  background: $color_gray4;
  ul{
    width: $width_base - 10;
    margin: 0 auto;
    padding: 3px 0;
    color: $color_gray3;
    li{
      display: inline-block;
      font-size: 12px;
      a{
        display: inline-block;
        padding: 0 24px 0 0;
        color: $color_gray1;
        background: url(../img/common_arrow_topicpass.png) no-repeat right center;
        background-size: 8px auto;
        margin: 0 5px 0 0;
      }
    }
    img{
      vertical-align: middle;
    }
  }
}

@media (min-width: 768px) {

  .l-common-topicpass_sp{
    display: none;
  }

}


/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-common-topicpass_pc{
    display: none;
  }

  .l-common-topicpass_sp{
    background: $color_gray4;
    ul{
      overflow: auto;
      white-space: nowrap;
      width: unset;
      margin: 0 auto;
      padding: 3px 6px;
      color: $color_gray3;
      li{
        display: inline-block;
        font-size: 12px;
        a{
          display: inline-block;
          padding: 0 24px 0 0;
          color: $color_gray1;
          background: url(../img/common_arrow_topicpass.png) no-repeat right center;
          background-size: 8px auto;
          margin: 0 5px 0 0;
        }
      }
      img{
        vertical-align: middle;
      }
    }
  }

}

/***************************************
 ローディング
***************************************/

.m-loading{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: $width_all;
  height: $width_all;
  margin: auto;
  background: #F5F5F5;
  z-index: 100;
  img{
    position: absolute;
    top: 260px;
    left: 0;
    right: 0;
    margin: auto;
    width: 50px;
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .m-loading{
    img{
      width: 50px;
    }
  }

}

/***************************************
 ページネーション
***************************************/

.l-common-pagerArea{
  margin: 10px 0 5px;
  span{
    font-size: 11px;
    display: inline-block;
    vertical-align: bottom;
    margin: 0 0 0 5px;
  }
  ul{
    margin: 3px 0 0;
    display: inline-block;
    li{
      display: inline-block;
      position: relative;
      margin: 0 1px 0 0;
      @include common_Height(30px);
      width: 30px;
      overflow: hidden;
      font-size: 11px;
      text-align: center;
      vertical-align: top;
      border-radius: 3px;
      a{
        display: block;
        @include common_Height(30px);
        width: 30px;
      }
    }
  }
}

.m-common-pager_nolink{
  background: $color_gray3;
  color: #ffffff;
  border-radius: 3px;
}

.m-common-pager_link{
  background: #ffffff;
  color: $color_gray2;
  border: solid 1px $color_gray2;
  box-sizing: border-box;
  border-radius: 3px;
  img{
    width: 8px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.m-common-pagerBtn_sp{
  display: none;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {
  .l-common-pagerArea{
    text-align: center;
    ul{
      li{
        @include common_Height(32px);
        width: 32px;
        font-size: 12px;
        a{
          @include common_Height(32px);
          width: 32px;
          img{
            width: 9px;
          }
        }
      }
    }
  }

  .l-common-pagerArea_pc{
    display: none;
  }

  .m-common-pagerBtn_sp{
    display: block;
    border: solid 1px $color_gray3;
    @include common_Height(48px);
    border-radius: 3px;
    color: $color_base;
    margin: 15px auto;
    font-size: 16px;
    width: 254px;
    img{
      height: 16px;
      vertical-align: middle;
      position: relative;
      top: -2px;
    }
  }

}

/***************************************
 モーダルウインドウ
***************************************/

.l-common-deleteBg{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: $width_all;
  height: $width_all;
  margin: auto;
  background: rgba(30, 30, 30, 0.7);
  z-index: 100;
}

.l-common-deleteBox{
  width: 500px;
  height: 180px;
  text-align: center;
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 50px 0 0;
  box-sizing: border-box;
  p{
    margin: 0 auto 15px;
    width: 85%;
    line-height: 1.4;
  }
  br{
    display: none;
  }
}

.m-common-btn_normal{
  display: inline-block;
  background: #E67064;
  border: none;
  border-bottom: solid 2px #CA5549;
  color: #ffffff;
  text-align: center;
  border-radius: 3px;
  padding: 0 15px;
  @include common_Height(30px);
}

.m-common-btnClose{
  display: inline-block;
  border-radius: 2px;
  color: #ffffff;
  text-align: center;
  @include common_Height(22px);
  background: $color_gray3;
  border: none;
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 0 5px;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-common-deleteBox{
    width: 90%;
    br{
      display: block;;
    }
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .l-common-deleteBox{
    width: 90%;
    text-align: left;
    font-size: 12px;
  }

  .l-common-delete_btnBox{
    text-align: center;
  }

}

/***************************************
 右カラム共通
***************************************/

.l-common-sideArea{
  margin: 0 0 25px;
}

.l-common-sideTitle{
  background: $color_gray1;
  color: #ffffff;
  padding: 0 10px;
  font-size: 16px;
  font-weight: normal;
  margin: 0 0 5px;
  @include common_Height(42px);
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-common-sideTitle{
    font-size: 16px;
    margin: 0;
    @include common_Height(38px);
  }

}

/***************************************
 エラーメッセージ
***************************************/

.l-common-searchMessage{
  background: $color_gray4;
  border-radius: 4px;
  padding: 25px;
  text-align: center;
  line-height: 1.5;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 20px;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-common-searchMessage{
    padding: 25px 15px;
    font-size: 14px;
    margin: 0 0 15px;
  }

}

/***************************************
 検索
***************************************/

.l-common-searchArea_pc{
  margin: 0 0 22px;
  display: flex;
  input[type="text"]{
    @include common_Height(42px);
    border: solid 1px $color_gray3;
    border-right: none;
    box-sizing: border-box;
    vertical-align: top;
    padding: 0 8px;
    display: inline-block;
    width: 250px;
  }
  input[type="submit"]{
    border: 0px;
    // background: url(../img/common_icon_search.png) $color_gray1 no-repeat center;
    background-size: 20px auto;
    @include common_Height(42px);
    width: 70px;
    display: inline-block;
  }
}

.l-common-search_box_pc{
  position: relative;
  z-index: 1;
  vertical-align: top;
  a,div{
    display: block;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    border: solid 1px $color_gray3;
    border-right: none;
    // background: url(../img/common_arrow_bg.png) no-repeat right center;
    background-size: 22px auto;
    padding: 0 26px 0 8px;
    @include common_Height(42px);
    color: $color_base;
  }
  ul{
    overflow-y: scroll;
    position: absolute;
    top: 42px;
    min-width: 100%;
    max-height: 460px;
    background: $color_gray4;
    padding: 10px 15px 0;
    border: 1px solid #e5e5e5;
    border-top: none;
    box-sizing: border-box;
    font-size: 12px;
    li{
      padding: 0 3px;
      display: inline-block;
    }
  }
  select {
    position: absolute;
    top: 42px;
    min-width: 100%;
    height: 235px;
    border: none;
    padding: 5px 0;
    background: #fff;
    border: 1px solid #b8b8b8;
    border-top: none;
    font-size: 12px;
    option {
      padding: 5px 10px;
    }
  }
}

.m-common-search_jobCategory_pc{
  width: 139px;
  ul{
    li{
      width: 180px;
    }
  }
}

.m-common-search_business_pc{
  width: 139px;
  ul{
    li{
      width: 180px;
    }
  }
}

.m-common-search_area_pc{
  width: 128px;
  ul{
    li{
      width: 130px;
    }
  }
}

.m-common-search_skill_pc{
  width: 200px;
  ul{
    li{
      width: 290px;
    }
  }
}

.m-common-search_recommend_pc{
  width: 124px;
  ul{
    li{
      width: 90px;
    }
  }
}

.l-common-search_selectArea_pc{
  position: relative;
  top: -20px;
  font-size: 12px;
  margin: 0 0 10px;
  color: $color_gray1;
  dl{
    display: block;
    margin: 0;
    padding: 10px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    overflow: hidden;
    dt{
      display: inline-block;
      font-weight: bold;
      border-left: 1px dotted #b8b8b8;
      padding: 0 0 0 15px;
      &:first-child{
        border: none;
      }
    }
    dd{
      margin: 0 0 0 15px;
      display: inline-block;
      span{
        margin: 0 15px 0 0;
        display: inline-block;
      }
    }
  }
}

.l-common-searchArea_sp{
  display: none;
}

.l-common-side-searchBox_pc{
  margin: 0 0 25px;
  h2{
    font-size: 16px;
    color: $color_gray1;
    line-height: 1;
    margin: 0 0 6px;
  }
  input[type="text"]{
    @include common_Height(42px);
    border: solid 1px $color_gray3;
    border-right: none;
    border-radius: 3px 0 0 3px;
    box-sizing: border-box;
    vertical-align: top;
    padding: 0 8px;
    display: inline-block;
    width: 250px;
    font-size: 14px;
  }
  input[type="submit"]{
    border: 0px;
    // background: url(../img/common_icon_search.png) $color_gray1 no-repeat center;
    background-size: 20px auto;
    @include common_Height(42px);
    width: 50px;
    border-radius: 0 3px 3px 0;
    display: inline-block;
  }
}

.l-search-btnBox{
  text-align: center;
}

.m-search-btn_sp{
  background: $color_base;
  border: none;
  display: inline-block;
  border-radius: 3px;
  color: #ffffff;
  text-align: center;
  width: 200px;
  vertical-align: top;
  @include common_Height(38px);
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-common-searchArea_pc{
    display: none;
  }

  .l-common-searchArea_sp{
    display: block;
    position: relative;
    width: $width_all;
    margin: 0 auto 20px;
    font-size: 13px;
    h2{
      background: $color_gray1;
      color: #ffffff;
      font-size: 16px;
      font-weight: normal;
      padding: 0 10px;
      @include common_Height(38px);
      &.searched {
        position: relative;
        @include common_Height(48px);
      }
      span {
        display: block;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        @include common_Height(48px);
        width: 100%;
        height: 0;
        padding-top: 48px;
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 92%;
          width: 15px;
          height: 10px;
          // background: url(../img/common_arrow_under.png) no-repeat;
          background-size: 15px 10px;
          transform: translateY(-50%);
        }
        &.showSearchArea {
          &::before {
            transform: rotate(180deg) translateY(50%);
          }
        }
      }
    }
    input[type="text"]{
      display: block;
      @include common_Height(44px);
      border: solid 1px $color_gray1;
      background: $color_gray4;
      box-sizing: border-box;
      vertical-align: top;
      margin-bottom: 15px;
      padding: 0 8px;
      display: inline-block;
      width: 100%;
      -webkit-appearance: none;
      border-radius: 0;
    }
    input[type="text"].l-common-search_middle{
      width: 100%;
      border: solid 1px $color_gray1;
    }
    input[type="submit"]{
      display: block;
      border: 0px;
      background-color: $color_blue1;
      color: #fff;
      font-size: 16px;
      @include common_Height(54px);
      width: 100%;
      border-radius: 0;
    }
    select{
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      border-radius: 0;
      border: 0;
      margin: 0;
      padding: 0;
      background: none transparent;
      vertical-align: middle;
      font-size: inherit;
      color: inherit;
      box-sizing: content-box;
      width: 100%;
      height: 40px;
      overflow: hidden;
      position: absolute;
      top: 0;
      opacity: 0;
    }
    ul{
      position: relative;
      display: block;
      z-index: 1;
      vertical-align: top;
      margin: 0 0 10px;
      li{
        position: relative;
        a,div{
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          box-sizing: border-box;
          border: solid 1px $color_gray1;
          border-top: none;
          // background: url(../img/common_arrow_bg.png) no-repeat right center;
          background-size: auto 30%;
          padding: 0 28px 0 8px;
          @include common_Height(40px);
          color: $color_base;
        }
      }
    }
  }

  .l-common-search_detailBox_sp{
    margin: 0 0 10px;
    box-sizing: border-box;
    overflow: hidden;
    border: solid 1px $color_gray1;
    background: $color_gray4;
    section{
      margin: -1px 0 0;
      padding: 0;
      border: none;
    }
    h2{
      background: $color_gray1;
      color: #ffffff;
      font-size: 13px;
      font-weight: normal;
      padding: 0 10px;
      @include common_Height(30px);
    }
    ul{
      margin: 0;
      position: relative;
      top: 1px;
      font-size: 11px;
      li{
        padding: 0 0 0 3px;
        border-bottom: solid 1px $color_gray3;
        background: $color_gray4;
        box-sizing: border-box;
        padding: 8px 5px;
        width: $width_all / 2;
        float: left;
        &:nth-child(odd){
          border-right: solid 1px $color_gray3;
        }
        &.m-common-skill_h{
          min-height: 50px;
        }
        label{
          padding: 0 0 0 19px;
          margin: 0;
          width: 100%;
          box-sizing: border-box;
          line-height: 1.4;
        }
      }
    }
  }

  .l-common-search_box_sp{
    border-top: solid 1px $color_gray1;
    margin: 0 0 15px;
  }


  .l-common-search_selectedBox_sp{
    h2{
      background: $color_gray1;
      color: #ffffff;
      font-size: 13px;
      font-weight: normal;
      padding: 0 10px;
      @include common_Height(30px);
      position: relative;
      margin: 10px 0 0;
      a{
        display: inline-block;
        @include common_Height(20px);
        background: #ffffff;
        color: $color_gray1;
        padding: 0 5px;
        border-radius: 3px;
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 11px;
      }
    }
    ul{
      margin: 5px 0 10px;
      li{
        display: inline-block;
        margin: 0 15px 0 0;
        font-size: 11px;
      }
    }
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .l-common-search_detailBox_sp{
    ul{
      font-size: 11px;
    }
  }

}

/***************************************
 吹き出しメッセージ
***************************************/

.l-common-noticeBalloon{
  margin: 0 0 10px;
  text-align: center;
  div{
    background: #FFA439;
    padding: 15px;
    line-height: 1.5;
    color: #ffffff;
    font-size: 18px;
    border-radius: 8px;
    .m-common-br:before{
      content: "\A" ;white-space: pre ;
    }
  }
  img{
    width: 20px;
  }
}

.l-common-noticeBalloon_blue{
  margin: 0 0 10px;
  text-align: center;
  div{
    background: $color_blue2;
    padding: 15px;
    line-height: 1.5;
    color: #ffffff;
    font-size: 18px;
    border-radius: 8px;
    .m-common-br:before{
      content: "\A" ;white-space: pre ;
    }
  }
  img{
    width: 20px;
  }
}

.l-common-noticeBalloon_color{
  color: #F32D2B;
  font-weight: bold;

}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-common-noticeBalloon{
    div{
      padding: 10px 20px;
      font-size: 16px;
    }
  }

  .l-common-noticeBalloon_blue{
    div{
      padding: 10px 20px;
      font-size: 16px;
    }
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-common-noticeBalloon{
    margin: 0;
    div{
      font-size: 13px;
      text-align: left;
      .m-common-br:before{
        content: normal;
        white-space: normal;
      }
    }
    img{
      width: 16px;
    }
  }

  .l-common-noticeBalloon_blue{
    margin: 0;
    div{
      font-size: 13px;
      text-align: left;
      .m-common-br:before{
        content: normal;
        white-space: normal;
      }
    }
    img{
      width: 16px;
    }
  }

}

/***************************************
 コラム・インタビュー（アイコンパーツ）
***************************************/

/* インターンシップ（コラム） */
/* 経営者（インタビュー） */
.m-column_cGreen{
  color: #3AAD33;
  border-color: #3AAD33;
}

/* 就活（コラム） */
/* インターン経験者（インタビュー） */
.m-column_cOrange{
  color: #FF9222;
  border-color: #FF9222;
}

/* 海外・留学（コラム） */
.m-column_cBlue{
  color: #68B3FF;
  border-color: #68B3FF;
}

/* キャンパス・イベント（コラム） */
.m-column_cYerrow{
  color: #87A900;
  border-color: #87A900;
}

/* ファッション・美容（コラム） */
/* オフィス紹介（インタビュー） */
.m-column_cPurple{
  color: #8F5DF1;
  border-color: #8F5DF1;
}

/* 恋愛（コラム） */
/* 働く人（インタビュー） */
.m-column_cRed{
  color: #FF6C9B;
  border-color: #FF6C9B;
}

/***************************************
 こだわり条件
***************************************/

.l-common-selectedLink{
  margin: 0 0 20px;
  border: solid 1px $color_gray3;
  border-radius: 3px;
  overflow: hidden;
  h2{
    font-size: 14px;
    @include common_Height(32px);
    padding: 0 8px;
    background: #E8E8E8
  }
  ul{
    padding: 8px 12px;
    li{
      display: inline-block;
      margin: 0 20px 0 0;
      font-size: 12px;
      a{
         background: url(../img/common_arrow_menu.png) no-repeat left center;
        background-size: auto 60%;
        padding: 0 0 0 9px;
        color: $color_base;
      }
    }
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-common-selectedLink{
    h2{
      font-size: 13px;
      @include common_Height(32px);
      padding: 0 8px;
      background: #E8E8E8
    }
    ul{
      padding: 0;
      overflow: hidden;
      li{
        display: block;
        float: left;
        margin: 0;
        padding: 0 7px;
        box-sizing: border-box;
        font-size: 14px;
        width: $width_all / 2;
        position: relative;
        top: 1px;
        border-bottom: dotted 1px $color_gray3;
        border-right: dotted 1px $color_gray3;
        &:nth-child(even){
          border-right: none;
        }
        a{
          display: block;
          // background: url(../img/common_arrow_menu.png) no-repeat left center;
          background-size: auto 8px;
          padding: 8px 0 8px 9px;
          color: $color_base;
        }
      }
    }
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-common-selectedLink{
    ul{
      li{
        font-size: 12px;
        a{
          padding: 6px 0 6px 9px;
        }
      }
    }
  }

}

/* ############ 320px以下 ############ */
@media (max-width: 320px) {

  .l-common-selectedLink{
    ul{
      li{
        font-size: 10px;
      }
    }
  }

}

/***************************************
 サイトマップ
***************************************/

.l-common-sitemap{
  margin: 0 0 20px;
  overflow: hidden;
  h2{
    font-size: 14px;
    @include common_Height(32px);
    padding: 0 8px;
    background: #E8E8E8
  }
  ul{
    padding: 8px 12px;
    li{
      display: inline-block;
      margin: 0 20px 0 0;
      font-size: 12px;
      width: 200px;
      a{
        // background: url(../img/common_arrow_menu.png) no-repeat left center;
        background-size: auto 60%;
        padding: 0 0 0 9px;
        color: $color_base;
      }
    }
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-common-sitemap{
    ul{
      padding: 0;
      overflow: hidden;
      li{
        display: block;
        float: left;
        margin: 0;
        padding: 0 7px;
        box-sizing: border-box;
        font-size: 14px;
        width: 200px;
        position: relative;
        top: 1px;
        border-bottom: dotted 1px $color_gray3;
        border-right: dotted 1px $color_gray3;
        &:nth-child(even){
          border-right: none;
        }
        a{
          display: block;
          // background: url(../img/common_arrow_menu.png) no-repeat left center;
          background-size: auto 8px;
          padding: 8px 0 8px 9px;
          color: $color_base;
        }
      }
    }
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-common-sitemap{
    ul{
      li{
        font-size: 12px;
        a{
          padding: 6px 0 6px 9px;
        }
      }
    }
  }

}

/* ############ 320px以下 ############ */
@media (max-width: 320px) {

  .l-common-sitemap{
    ul{
      li{
        font-size: 10px;
      }
    }
  }

}


/***************************************
 ゼロワン新卒用アカウント設定
***************************************/

// ヘッダー

.m-scout-headerLogo{
  h2{
    display: inline-block;
  }
  a:hover{
    @include common_Alpha(1);
  }
  img{
    width: 100%;
    display: inline-block;
    width: 180px;
    position: relative;
    top: 8px;
  }
}

.l-scout-headerArea{
  width: $width_base;
  margin: 0 auto;
  padding: 4px 0 17px;
  position: relative;
}

// フッター

.l-scoutfooterArea{
  height: 124px;
}

.l-scout-footerBox{
  padding: 10px 0;
}

.l-scout-footerMenu{
  margin: 0;
  ul{
    li{
      display: inline-block;
      margin: 0 8px;
      a{
        color: #a1a1a1;
        font-size: 12px;
      }
    }
  }
}

// コンテンツエリア

.l-contents_scout{
  padding: 0 0 124px;
}

.l-mypage-baseArea_scout{
  width: 820px;
  margin: 0 auto;
}

.m-mypage-btn_scout{
  background: $color_blue1;
  border: none;
  border-bottom: solid 4px #136A88;
  display: inline-block;
  border-radius: 5px;
  color: #ffffff;
  font-size: 15px;
  text-align: center;
  margin: 0 2px;
  vertical-align: top;
  @include common_Height(45px);
  width: 300px;
}


/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  // ヘッダー

  .l-scout-headerArea{
    width: 95%;
    margin: 0 auto;
    padding: 0 0 9px;
  }

  .m-scout-headerLogo{
    img{
      width: 130px;
      position: relative;
      top: 6px;
      left: 5px;
    }
  }

  // コンテンツエリア

  .l-contents_scout{
    padding: 0 0 108px;
  }

  .l-mypage-baseArea_scout{
    width: 95%;
    margin: 0 auto;
  }

  .m-mypage-btn_scout{
    @include common_Height(40px);
    width: 240px;
  }

  // フッター

  .l-scoutfooterArea{
    height: 108px;
  }

  .l-scout-footerBox{
    padding: 8px 0 0;
  }

}



/***************************************
 LP（リリース前会員登録用）
***************************************/

.m-common-release_date{
  font-size: 15px;
  font-weight: bold;
  display: inline-block;
  border-radius: 3px;
  padding: 0 15px;
  background: #FDD2D1;
  color: $color_red1;
  @include common_Height(30px);
  vertical-align: top;
  margin: 5px 0 0 15px;
}

.l-common-headerArea_entry{
  padding: 13px 0 2px;
  .m-common-headerLogo{
    display: inline-block;
    img{
      width: 150px;
      height: auto;
    }
  }
}

.l-contents_early{
  padding: 0 0 140px; /* フッターの高さ */
  margin: 30px auto 0;
  overflow: hidden;
  width: $width_base;
  .l-contents-join{
    position: relative;
    width: 900px;
    margin: 10px auto 0;
  }
}

.l-common-footer_early{
  width: $width_all;
  height: 75px;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

.l-common-footerMenu_early{
  margin: 10px 0 5px;;
  ul{
    li{
      font-size: 12px;
      display: inline-block;
      margin: 0 10px;
      line-height: 1.6;
      a{
        color: $color_gray2;
      }
    }
  }
}

.l-common-footerBox_early{
  background: #eeeeee;
  padding: 5px 0 15px;
  text-align: center;
  small{
    color: $color_gray1;
  }
}

.l-index-main_entry{
  .l-index-main_box{
    position: relative;
    top: 30px;
  }
  img{
    margin: 20px 0 0;
    width: 520px;
  }
}

.m-common-gift_bannerNotes{
  font-size: 10px;
  font-weight: normal;
  color: #ffffff;
  display: block;
  @include common_Alpha(0.6);
  margin: 2px auto 0;
  width: 520px;
  text-align: left;
  line-height: 1.4;
}

.m-common-gift_text{
  background: #D8F1D1;
  color: #136F3D;
  font-weight: bold;
  font-size: 16px;
  display: inline-block;
  margin: 10px 0 0;
  padding: 8px 15px;
}

.l-form-termsLink_entry{
  position: relative;
  top: -10px;
}

.l-contents_early .l-form-baseArea{
  margin: 0 0 20px;
}

.l-contents_early .l-common-noticeBalloon{
  margin: 30px auto 0;
  width: 80%;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-common-headerArea_entry{
    height: 33px;
    padding: 10px 0 2px;
    .m-common-headerLogo{
      margin: 0 0 0 10px;
      height: 26px;
      img{
        width: 98px;
        height: auto;
      }
    }
  }

  .l-contents_early{
    padding: 0 0 100px; /* フッターの高さ */
    margin: 10px auto 0;
    width: $width_all;
    .l-contents-join{
      width: $width_sp;
      margin: 10px auto 0;
    }
  }

  .l-common-footer_early{
    height: 82px;
  }

  .l-common-footerBox_early{
    font-size: 10px;
  }

  .l-index-main_entry{
    .l-index-main_box{
      position: relative;
      top: 10px;
    }
    img{
      margin: 8px 0 0;
    }
  }

  .m-common-gift_text{
    font-size: 14px;
  }

  .l-contents_early .l-common-noticeBalloon{
    margin: 10px auto 0;
    font-size: 14px;
    width: 80%;
    div{
      padding: 10px 0;
    }
    .m-common-br:before{
      content: "\A";
      white-space: pre ;
    }
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .m-common-release_date{
    font-size: 11px;
    font-weight: normal;
    border-radius: 3px;
    padding: 0 10px;
    @include common_Height(23px);
    margin: 2px 0 0 6px;
  }

  .l-wrap .l-index-main_entry{
    height: 230px;
    img{
      margin: -10px 0 0;
      width: 80%;
    }
    .l-index-mainCatch{
      font-size: 20px;
      line-height: 1.3;
    }
    .l-index-main_box{
      position: relative;
      top: 6px;
      p{
        position: relative;
        top: -6px;
      }
    }
  }

  .m-common-gift_bannerNotes{
    font-size: 7px;
    margin: 3px auto 0;
    width: 80%;
  }

  .m-common-gift_text{
    font-weight: normal;
    font-size: 10px;
    padding: 8px 10px;
    margin: 5px 0;
    line-height: 1.4;
  }

  .l-form-termsLink_entry{
    position: relative;
    top: -10px;
  }

  .l-contents_early .l-common-noticeBalloon{
    width: 95%;
    div{
      text-align: center;
      font-size: 11px;
    }
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .l-wrap .l-index-main_entry{
    height: 215px;
    img{
      width: 100%;
    }
    .l-index-main_box{
      position: relative;
      top: 10px;
      p{
        position: relative;
        top: -6px;
      }
    }
  }

  .m-common-gift_bannerNotes{
    width: 100%;
  }

}

/***************************************
 表示要素調整（実装後削除）
***************************************/

// 非表示の実装後削除

.l-common-baseArea{
  .display_style{
    display: none;
  }
}

.display_style{
  display: none;
}


/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  // 非表示の実装後削除
  .l-common-baseArea{
    .display_style{
      display: block;
    }
  }

  .display_style{
    display: block;
  }

}

/*********************
相談会バナー
*********************/

.l-consultationBanner {
  text-align: center;
}

.l-consultationBanner img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: auto;
}

/********************************************
インターンシップ体験談
*********************************************/

.interview-article-edit-title {
  background: $color_base;
  font-size: 23px;
  text-indent: 11px;
  color: #ffffff;
  margin-bottom: 15px;
}

.interview-article-title-margin {
  margin-bottom: 56px!important;
}

.interview-article-form-bg {
  padding: 30px 43px;
  background: $color_gray4;
  border-radius: 4px;
}

.interview-article-section {
  background: #FFFFFF;
  margin-bottom: 15px;
  padding: 20px;
}

.interview-article-description {
  margin-bottom: 15px;
}

.interview-article-student-info-flex {
  display: flex;
  flex-flow: wrap;
}

.interview-article-student-info-part {
  display: inline-block;
  padding: 10px 5px;
}

.interview-article-student-info-part p {
  display: block;
}

.interview-article-student-info-flex input {
  display: inline-block;
}

.interview-article-input label {
  width: 80px;
}

.interview-article-input-f-width {
  width: 100%;
}

.interview-article-add-button {
  padding: 0 20px;
  margin-bottom: 15px;
}

.interview-article-content-grid {
  display: grid;
  grid-template-columns: 1fr 7fr;
  grid-gap: 10px;
}

.interview-article-image-preview {
  width: 200px;
  height: 150px;
  padding: 15px;
}

.l-article-title {
  line-height: 1.4;
  background: #E7F0F9;
  border-top: solid 1px #AECAE6;
  border-bottom: solid 1px #AECAE6;
  padding: 16px;
  margin: 0 0 15px;
}

.l-article-titleName {
  font-size: 23px;
}

.l-article-header {
  line-height: 1.4;
  background: #E7F0F9;
  padding: 16px;
  margin: 0 0 15px;
}

.l-article-headerName {
  font-size: 14px;
}

.l-article-content {
  white-space: pre-line;
}

.l-article-content a {
  font-weight: bolder;
}

.l-article-table {
  width: 100%;
  height: 360px;
  border: 1px solid $color_light_gray1;
  text-overflow: ellipsis;
}

.l-article-table th {
  background: $color_light_gray1;
}

.l-article-table td {
  border-bottom: 2px solid $color_light_gray1;
}

.l-article-table tbody {
  vertical-align: baseline;
}

.l-article-table tbody td {
  padding-top: 10px;
  padding-bottom: 8px;
}

.l-article-table tbody td:first-child {
  padding-left:20px;
}

.l-article-table tbody td:last-child {
  padding-right:20px;
}

.inline-form {
  display: inline-block;
}

.l-article-edit-table tbody td:nth-child(2),
.l-article-edit-table tbody td:nth-child(3) {
  text-align: center;
}

.l-article-icon-button {
  background: none!important;
  border: none;
  font-size: 23px;
  color: $color_light_gray1;
  padding-bottom: 15px;
}

/********************************************
 Angular用
*********************************************/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

/***************************************
 Lazy loading
***************************************/

.lazy-image-container {
	overflow: hidden;
}

/*.lazy-image {
  filter: blur(8px);
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  transform: scale(1.08);
}*/
