@charset "utf-8";

// ▼Sass設定

@import "_variables.scss";

/*********************
 フォームテンプレ
*********************/

.l-form-baseArea{
  background: $color_gray4;
  border-radius: 4px;
  padding: 30px 0 20px;
  margin: 20px 0 30px;
  overflow: hidden;
  ul{
    text-align: center;
    li{
      margin: 8px 0;
    }
  }
  dl{
    margin: 2px 0;
    padding: 13px 15px;
    background: $color_gray4;
    overflow: hidden;
    line-height: 1.6;
    dt{
      display: block;
      float: left;
      font-weight: bold;
      width: 200px;
      margin: 0 3px 0 0;
      vertical-align: top;
    }
    dd{
      display: block;
      float: left;
      padding: 0;
      margin: 0;
    }
  }
}

.l-form-baseBox{
  width: 90%;
  margin: 0 auto;
  dl{
    background: #ffffff;
    margin: 4px 0;
    dd{
      width: 550px;
      word-wrap: break-word;
      line-height: 1.4;
    }
  }
}
/* fbログイン廃止 */
.l-form-registBox_l{
  padding: 0 0 10px;
  width: 100%;
  small{
    display: block;
    margin: 5px auto;
    line-height: 1.5;
  }
}

.l-form-registBox_r{
  width: 50%;
  margin: 0 auto;
  float: right;
  border-left: solid 1px #DEDEDE;
}

.l-form-registBox_lr{
  width: 95%;
  margin: 0 auto;
  float: right;
  border-left: solid 1px #DEDEDE;
}


.l-form-registBox{
  width: 70%;
  margin: 0 auto;
}

.l-form-registTitle{
  text-align: center;
  font-size: 17px;
  margin: 0 0 20px;
}

.l-form-termsLink{
  display: block;
  text-align: center;
  margin: 10px 0 0;
}

.l-form-regist_checkArea{
  width: 300px;
  margin: -6px auto 0;
  ul{
    overflow: hidden;
    font-size: 12px;
    li{
      margin: 0;
      text-align: left;
    }
  }
}

.l-form-regist_autoLogin{
}

.l-form-regist_passForgot{
  float: right;
}

.l-form-regist_still{
  text-align: center;
  margin: -15px 0 0;
  a{
    border-bottom: solid 1px $color_blue2;
  }
}

.l-form-password-note {
  width: 300px;
  margin: 0 auto 15px auto !important;
  text-align: left;
  font-size: 12px;
}

@media (min-width: 768px) {
  .l-form-login-item {
    padding: 0 0 13px 70px !important;
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-form-baseArea{
    padding: 20px;
    dl{
      padding: 10px;
      dt{
        width: 160px;
        font-size: 12px;
      }
      dd{
        width: 60%;
      }
    }
    .l-form-termsLink{
      margin: 15px auto;
    }
  }

  .l-form-regist_checkArea{
    width: $width_all;
    margin: -3px auto 0;
  }

  /* fbログイン廃止 */
  .l-form-registBox_l{
    padding: 0 0 10px;
    margin: 0 auto;
    width: 90%;
    float: none;
  }
  /* fbログイン廃止 */
  .l-form-registBox_r{
    width: 70%;
    float: none;
    border-left: none;
  }

  .l-form-registBox_lr{
    width: 70%;
    float: none;
    border-left: none;
  }

  .l-form-registBox{
    width: 95%;
    margin: 0 auto;
  }

  .l-form-registTitle{
    text-align: center;
    font-size: 16px;
    margin: 0 0 15px;
  }

  .l-form-termsLink{
    font-size: 12px;
    width: 90%;
    margin: 0 auto;
    line-height: 1.5;
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-form-baseArea{
    margin: 10px 0 20px;
    padding: 15px 10px 10px;
    dl{
      padding: 10px 15px;
      dt{
        margin: 0 0 3px;
      }
      dd{
        width: 100%;
      }
    }
  }

  .l-form-baseBox{
    width: 96%;
    margin: 0 auto;
    dl{
      background: #ffffff;
      margin: 4px 0;
    }
  }

  /* fbログイン廃止 */
  .l-form-registBox_l{
    small{
      width: 90%;
    }
  }

  /* fbログイン廃止 */
  .l-form-registBox_r{
    width: 95%;
    float: none;
    border-left: none;
  }

  .l-form-registBox_lr{
    width: 95%;
    float: none;
    border-left: none;
  }

  .l-form-registTitle{
    text-align: center;
    font-size: 14px;
    margin: 0 0 6px;
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .l-form-registBox_l{
    small{
      width: 100%;
      font-size: 7px;
    }
  }

}

/*********************
 パーツ
*********************/

.m-form-text_s{
  width: 150px;
}

.m-form-text_sm{
  width: 200px;
}

.m-form-text_m{
  width: 300px;
}

.m-form-text_ml{
  width: 380px;
}

.m-form-text_l{
  width: 500px;
}

textarea{
  border: solid 1px #dcdcdc;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  .m-form-errorBg{
    background: #FFD7D7;
  }/* エラー時に使用する */
}

.m-form-textarea{
  width: 550px;
  height: 100px;
  box-sizing: border-box;
}

select{
  padding: 3px 36px 3px 10px;
  -webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
  // background: url(../img/common_arrow_bg.png) #ffffff right center no-repeat;
  background-size: auto 30%;
  border: solid 1px #CECECE;
  border-radius: 0;
  height: 36px;
  box-sizing: border-box;
}

.m-form-errorBg{
  background-color: #FFD7D7;
}/* エラー時に使用する */

.m-form-grade{
  font-size: 12px;
  padding: 0 0 0 3px;
}

input[type=checkbox] + label.m-common-checkStyle{
  font-size: 12px;
  line-height: 1.3;
  margin: 0;
}

.m-common-check_scoutBox{
  text-align: center;
  padding: 10px 0 0;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .m-form-text_s{
    width: 100%;
  }

  .m-form-text_sm{
    width: 100%;
  }

  .m-form-text_m{
    width: 100%;
  }

  .m-form-text_ml{
    width: 100%;
  }

  .m-form-text_l{
    width: 100%;
  }

  .m-form-grade{
    font-size: 11px;
  }

  .m-form-textarea{
    width: 100%;
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {
  .m-form-grade{
    padding: 0;
  }

  input[type=checkbox] + label.m-common-checkStyle{
    text-align: left;
    font-size: 11px;
    margin: 5px 0 15px;
  }

}

/*********************
 アイコン
*********************/

@mixin form_mustIcon_style($bg_color) {
  @include common_Height(21px);
  display: inline-block;
  font-size: 12px;
  padding: 0 6px;
  background: $bg_color;
  color: #ffffff;
  border-radius: 3px;
  float: right;
  font-weight: normal;
  position: relative;
  top: 0;
  right: 5px;
}

.m-form-mustIcon{
  @include form_mustIcon_style(#FF9B29);
}

.m-form-anyIcon{
  @include form_mustIcon_style(#bababa);
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  @mixin form_mustIcon_style($bg_color) {
    @include common_Height(18px);
    font-size: 11px;
    padding: 0 4px;
  }

  .m-form-mustIcon{
    @include form_mustIcon_style(#FF9B29);
  }

  .m-form-anyIcon{
    @include form_mustIcon_style(#bababa);
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  @mixin form_mustIcon_style($bg_color) {
    @include common_Height(16px);
    background: none;
    font-weight: bold;
    float: none;
    position: relative;
    top: 0;
    right: 0;
    &:before{
      content: " [ "
    }
    &:after{
      content: " ] "
    }
  }

  .m-form-mustIcon{
    @include form_mustIcon_style(none);
    color: #FF9B29;
  }

  .m-form-anyIcon{
    @include form_mustIcon_style(none);
    color: #bababa;
  }

}

/*********************
 メッセージ
*********************/

.l-form-messageBox{
  margin: 0 30px;
  text-align: center;
  h2{
    font-size: 36px;
    margin: 0 0 10px;
    color: $color_gray1;
    line-height: 1.4;
  }
  h3{
    font-size: 20px;
    line-height: 1.4;
  }
  p{
    margin: 10px 0;
    line-height: 1.6;
  }
  small{
    display: block;
    color: #FF5858;
  }
}

.l-form-descriptionArea{
  margin: 0 0 20px;
  p{
    text-align: center;
    font-size: 22px;
    line-height: 1.5;
    font-weight: bold;
  }
}

.l-form-entlySubject{
  border: solid 1px $color_gray3;
  border-radius: 4px;
  padding: 20px;
  margin: 0 0 20px;
  h3{
    border-bottom: dotted 1px $color_gray3;
    padding: 0 0 10px;
    margin: 0 0 16px;
    line-height: 1.5;
  }
  span{
    display: inline-block;
    font-weight: bold;
    margin: 0 10px 0 0;
    color: $color_gray1;
  }
  dl{
    display: inline-block;
    margin: 0;
    color: $color_gray1;
    dt{
      display: inline-block;
      background: $color_gray4;
      border-radius: 3px;
      padding: 0 10px;
      margin: 0 5px 0 0;
    }
    dd{
      display: inline-block;
      margin: 0 20px 0 0;
    }
  }
}

.l-not-complete-entry-message {
    color: #FF6363;
    text-align: center;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-form-messageBox{
    margin: 0;
    h2{
      font-size: 28px;
      margin: 10px 0;
      text-align: left;
    }
    h3{
      font-size: 18px;
      text-align: left;
    }
    p{
      text-align: left;
    }
  }

  .l-form-descriptionArea{
    p{
      font-size: 18px;
    }
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-form-messageBox{
    margin: 0 15px;
    h3{
      font-size: 15px;
      text-align: left;
    }
    p{
      font-size: 11px;
      text-align: left;
    }
  }

  .l-form-descriptionArea{
    margin: 0;
    p{
      font-size: 16px;
    }
  }

  .l-form-entlySubject{
    padding: 8px 12px 5px;
    h3{
      font-size: 12px;
      margin: 0 0 5px;
    }
    span{
      font-size: 11px;
    }
    dl{
      font-size: 11px;
      display: block;
      dt{
        margin: 0 5px 0 0;
        padding: 0 4px;
      }
      dd{
        margin: 0 10px 0 0;
      }
    }
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .l-form-messageBox{
    margin: 0 10px;
  }

  .l-form-descriptionArea{
    p{
      font-size: 13px;
    }
  }

}

/*********************
 エラー
*********************/

.m-form-error{
  font-size: 12px;
  color: #FF6363;
  display: block;
  line-height: 1;
  margin: 5px 0 0;
  font-weight: bold;
}

/*********************
 ボタン
*********************/

.l-form-btnBox{
  margin: 15px 0;
  text-align: center;
  span{
    display: block;
    margin: 0 0 8px;
    a{
      text-decoration: underline;
    }
  }
}

.l-form-btnBox_complete{
  margin: 30px 0;
  text-align: center;
  span{
    display: block;
    margin: 0 0 8px;
    a{
      text-decoration: underline;
    }
  }
}

@mixin form_btn_style($size) {
  display: inline-block;
  border-radius: 5px;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  margin: 0 2px;
  vertical-align: top;
  box-sizing: border-box;
  @include common_Height($size);
}

.m-form-btn_red{
  background: $color_red1;
  border: none;
  border-bottom: solid 4px $color_red2;
  padding: 0;
  @include form_btn_style(44px);
}

.m-form-btn_fb{
  background: #455c95;
  border: none;
  border-bottom: solid 4px #2B427B;
  @include form_btn_style(44px);
  margin: 0 10px 0 0;
}

.m-form-btn_entry{
  background: $color_red1;
  border: none;
  border-bottom: solid 4px $color_red2;
  @include form_btn_style(47px);
}


.m-form-btn_entry_start{
  display: inline-block;
  border-radius: 5px;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  margin: 0 2px;
  vertical-align: top;
  box-sizing: border-box;
  background: $color_red1;
  border: none;
  border-bottom: solid 4px $color_red2;
  height: 47px;
}

.m-form-btn_gray{
  background: $color_gray1;
  border: none;
  border-bottom: solid 4px #6F6F6F;
  @include form_btn_style(47px);
}

.m-form-btn_blue{
  background: $color_blue1;
  border: none;
  border-bottom: solid 4px #136A88;
  @include form_btn_style(47px);
}
.m-form-btn_xs{
  width: 60px;
}

.m-form-btn_ss{
  width: 120px;
}

.m-form-btn_s{
  width: 150px;
}

.m-form-btn_m{
  width: 200px;
}

.m-form-btn_l{
  width: 300px;
}

.m-form-btn_sl{
  width: 300px;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-form-btnBox{
    margin: 10px 0;
    text-align: center;
    span{
      display: block;
      margin: 0 0 8px;
      a{
        text-decoration: underline;
      }
    }
  }

  .l-form-btnBox_complete{
    margin: 25px 0;
    text-align: center;
    span{
      display: block;
      margin: 0 0 8px;
      a{
        text-decoration: underline;
      }
    }
  }

  @mixin form_btn_style($size) {
    display: inline-block;
    border-radius: 5px;
    color: #ffffff;
    font-size: 13px;
    text-align: center;
    margin: 0 1px;
    vertical-align: top;
    @include common_Height($size);
  }

  .m-form-btn_red{
    padding: 0;
    @include form_btn_style(42px);
  }

  .m-form-btn_fb{
    @include form_btn_style(42px);
  }

  .m-form-btn_entry{
    @include form_btn_style(45px);
  }

  .m-form-btn_gray{
    @include form_btn_style(45px);
  }

  .m-form-btn_blue{
    @include form_btn_style(45px);
  }
  .m-form-btn_xs{
    width: 10%;
  }

  .m-form-btn_ss{
    width: 20%;
  }

  .m-form-btn_s{
    width: 40%;
  }

  .m-form-btn_m{
    width: 50%;
  }

  .m-form-btn_l{
    width: 60%;
  }

  .m-form-btn_sl{
    width: 65%;
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px){
  .m-form-btn_xs{
    width: 17%;
  }

  .m-form-btn_ss{
    width: 34%;
  }

  .m-form-btn_s{
    width: 80%;
  }

  .m-form-btn_m{
    width: 80%;
  }

  .m-form-btn_l{
    width: 92%;
  }

  .m-form-btn_sl{
    width: 60%;
  }

}

/***************************************
 フォームフロー
***************************************/

.l-form-flowNav3{
  margin: 0 0 15px;
  overflow: hidden;
  li{
    @include common_Height(42px);
    float: left;
    padding: 0 15px;
    width: $width_all / 3;
    box-sizing: border-box;
    text-align: center;
    text-indent: -5px;
  }
}

.l-form-flowNav4{
  margin: 0 0 15px;
  overflow: hidden;
  li{
    @include common_Height(42px);
    float: left;
    padding: 0 15px;
    width: $width_all / 4;
    box-sizing: border-box;
    text-align: center;
    text-indent: -5px;
  }
}

.l-form-flowNav_bgl{
  // background: #cdcdcd url(../img/form_navBg_l.png) no-repeat left;
  background-size: auto 100%;
  color: #848484;
}

.l-form-flowNav_bgn{
  // background: #cdcdcd url(../img/form_navBg_n.png) no-repeat left;
  background-size: auto 100%;
  color: #848484;
}

.l-form-flowNav_bgr{
  // background: #cdcdcd url(../img/form_navBg_r.png) no-repeat left;
  background-size: auto 100%;
  color: #848484;
}

.l-form-flowNav_bgrOn{
  // background: #848484 url(../img/form_navBg_r.png) no-repeat left;
  background-size: auto 100%;
  color: #ffffff;
}

.l-form-flowNav_off{
  background: #cdcdcd;
  color: #848484;
}

.l-form-flowNav_on{
  background: #848484;
  color: #ffffff;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-form-flowNav3{
    margin: 0 0 10px;
    font-size: 13px;
    li{
      @include common_Height(37px);
      padding: 0 5px;
      text-indent: -2px;
    }
  }

  .l-form-flowNav4{
    margin: 0 0 10px;
    font-size: 13px;
    li{
      @include common_Height(37px);
      padding: 0 5px;
      text-indent: -2px;
    }
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-form-flowNav3{
    margin: 0 0 10px;
    font-size: 10px;
    li{
      @include common_Height(30px);
      padding: 0 5px;
      text-indent: 0;
    }
  }

  .l-form-flowNav4{
    margin: 0 0 10px;
    font-size: 8px;
    li{
      @include common_Height(30px);
      padding: 0 5px;
      text-indent: 5px;
    }
  }

}

/***************************************
 応募完了画面
***************************************/

.l-form-stepArea{
  border: solid 1px $color_blue2;
  border-radius: 3px;
  overflow: hidden;
  margin: 0 0 40px;
  h2{
    background: $color_blue2;
    @include common_Height(42px);
    color: #ffffff;
    font-size: 17px;
    font-weight: normal;
    padding: 0 10px;
  }
  h3{
    font-size: 18px;
    margin: 0 0 10px 0;
    background: $color_gray4;
    padding: 2px 12px;
    border-radius: 3px;
  }
  dl{
    margin: 0 30px;
    border-bottom: dotted 1px $color_gray2;
    padding: 20px 0;
    overflow: hidden;
    &:last-child{
      border-bottom: none;
    }
    dt{
      float: left;
      width: 10%;
      font-size: 20px;
      font-weight: bold;
      vertical-align: top;
      line-height: 1.4;
      span{
        font-size: 27px;
      }
    }
    dd{
      float: left;
      width: 86%;
      margin: 0 0 0 25px;
      padding: 4px 0;
      line-height: 1.5;
    }
  }
  ul{
    list-style: disc outside;
    li{
      margin: 4px 0 4px 22px;
    }
  }
}

.l-form-step_textRed{
  color: $color_red1;
}

.l-form-similarArea{
  margin: 0 0 40px;
  overflow: hidden;
  h2{
    background: $color_gray2;
    border-radius: 3px;
    color: #ffffff;
    padding: 10px;
    font-size: 16px;
    font-weight: normal;
    margin: 0 0 20px;
    line-height: 1.4;
  }
}

.l-form-similarBox{
  display: block;
  float: left;
  width: ($width_all - 5) / 4;
  box-sizing: border-box;
  margin: 0 15px 0 0;
  &:hover{
    @include common_Alpha;
  }
  &:nth-child(4n){
    margin-right: 0;
  }
  img{
    width: 100%;
    display: block;
  }
}

.l-form-similarBox_text{
  h3{
    color: $color_base;
    line-height: 1.4;
    font-size: 16px;
    margin: 8px 0 0;
    text-justify: inter-ideograph;
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-form-stepArea{
    margin: 0 0 30px;
    h2{
      font-size: 16px;
      @include common_Height(40px);
    }
    h3{
      font-size: 16px;
    }
    dl{
      margin: 0 15px;
      padding: 12px 0;
      dt{
        width: 10%;
        font-size: 18px;
        margin: 4px 0 0;
        span{
          font-size: 22px;
        }
      }
      dd{
        float: left;
        width: 84%;
        margin: 0 0 0 25px;
      }
    }
  }

  .l-form-similarArea{
    margin: 0 0 30px;
    overflow: hidden;
  }

  .l-form-similarBox{
    width: ($width_all - 3) / 2;
    margin: 16px 0 0;
    &:nth-child(2n){
      margin-right: 0;
    }
    &:nth-child(-n+2){
      margin-top: 0;
    }
    &:nth-child(even){
      float: right;
    }
    &:nth-child(-n+2){
      margin-top: 0;
    }
  }

  .l-form-similarBox_text{
    h3{
      font-size: 16px;
    }
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-form-stepArea{
    margin: 0 0 30px;
    h3{
      font-size: 14px;
    }
    dl{
      margin: 0 15px;
      padding: 12px 0;
      dt{
        font-size: 14px;
        width: 11%;
        span{
          font-size: 16px;
        }
      }
      dd{
        float: left;
        width: 78%;
        margin: 0 0 0 25px;
        font-size: 12px;
      }
    }
  }

  .l-form-similarArea{
    margin: 0 0 40px;
    overflow: hidden;
    h2{
      background: $color_gray2;
      border-radius: 3px;
      color: #ffffff;
      padding: 9px 10px;
      font-size: 15px;
      font-weight: normal;
      margin: 0 0 15px;
      line-height: 1.4;
    }
  }

  .l-form-similarBox{
    width: ($width_all - 3) / 2;
    margin: 10px 0 0;
    &:nth-child(3n){
      margin-right: 0;
    }
    &:nth-child(-n+3){
      margin-top: 10px;
    }
    &:nth-child(even){
      float: right;
    }
    &:nth-child(-n+2){
      margin-top: 0;
    }
  }

  .l-form-similarBox_text{
    h3{
      font-size: 12px;
      margin: 8px 0 0;
    }
  }

}

/***************************************
 応募完了画面
***************************************/

.l-form-issue_notes{
  display: block;
  text-align: center;
  margin: 5px 0 0;
}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-form-issue_notes{
    text-align: left;
    margin: 5px 15px 0;
    line-height: 1.6;
  }

}
