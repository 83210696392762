@charset "utf-8";

// ▼Sass設定

@import "_variables.scss";

/***************************************
 リセット
***************************************/

html{
  height: 100%;
}

html > * {
 -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body{
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS P Gothic", Verdana, sans-serif;
  background: #ffffff;
  margin: 0;
  height: 100%;
  line-height: 1.8;
  font-size: 14px;
  color: $color_base;
}

a{
  color: $color_orange1;
  text-decoration: none;
  &:hover{
    @include common_Alpha;
  }
}

input:focus{
  outline: none;
}

textarea:focus{
  outline: none;
}

select:focus{
  outline: none;
}

input[type="submit"]{
  line-height: 1;
  -webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
  &:hover{
    @include common_Alpha;
  }
}

input[type="button"]{
  line-height: 1;
  -webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
  &:hover{
    @include common_Alpha;
  }
}

@mixin form_textbox {
  border: solid 1px #CECECE;
  border-radius: 0;
  padding: 3px 5px;
  vertical-align: top;
  box-sizing: border-box;
  height: 36px;
  -webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
  font-size: 14px;
}

input[type="text"]{
  @include form_textbox;
}

input[type="password"]{
  @include form_textbox;
}

input[type="email"]{
  @include form_textbox;
}

select::-ms-expand{
  display: none;
}

input[type=radio],
input[type=checkbox] {
    display: inline-block;
    margin-right: 6px;
}
input[type=radio] + label,
input[type=checkbox] + label {
    position: relative;
    display: inline-block;
    margin-right: 12px;
    line-height: 30px;
    cursor: pointer;
}

@media (min-width: 1px) {
    input[type=radio],
    input[type=checkbox] {
        display: none;
        margin: 0;
    }
    input[type=radio] + label {
        padding: 0 0 0 20px;
        margin: 0 14px 0 0;
        line-height: 1.0;
    }
    input[type=checkbox] + label {
        padding: 0 0 0 20px;
        margin: 0 14px 14px 0;
        line-height: 1.0;
    }
    input[type=radio] + label::before,
    input[type=checkbox] + label::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
        width: 16px;
        height: 16px;
        margin-top: -8px;
        background: #FFF;
    }
    input[type=radio] + label::before {
        border: 2px solid #ccc;
        border-radius: 30px;
    }
    input[type=checkbox] + label::before {
        border: 2px solid #ccc;
    }
    input[type=radio]:checked + label::after,
    input[type=checkbox]:checked + label::after {
        content: "";
        position: absolute;
        top: 50%;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: block;
    }
    input[type=radio]:checked + label::after {
        left: 3px;
        width: 10px;
        height: 10px;
        margin-top: -5px;
        background: $color_gray2;
        border-radius: 8px;
    }
    input[type=checkbox]:checked + label::after {
        left: 3px;
        width: 13px;
        height: 8px;
        margin-top: -6px;
        border-left: 3px solid $color_gray2;
        border-bottom: 3px solid $color_gray2;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}

h1, h2, h3, h4{
  margin: 0;
}

ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

img{
  vertical-align: top;
}

/***************************************
 要素出し分け
***************************************/

/* ######### 768px以上 ######### */
@media (min-width: 768px) {

  .display-none_pc{
    display: none;
  }

  .display-none_sp{
    display: block;
  }

}

/* ######### 767px以下 ######### */
@media (max-width: 767px) {

  .display-none_pc{
    display: block;
  }

  .display-none_sp{
    display: none;
  }

}

/***************************************
 全体レイアウト
***************************************/

.l-wrap{
  width: $width_all;
  position: relative;
  min-height: 100%;
}

.l-contents{
  padding: 0 0 150px; /* フッターの高さ */
  margin: 30px auto 0;
  overflow: hidden;
  width: $width_base;
  .l-contents-l{
    position: relative;
    width: $width_left;
    float: left;
    margin: 10px 0 0;
  }
  .l-contents-r{
    position: relative;
    width: $width_right;
    float: right;
    margin: 10px 0 0;
  }
  .l-contents-join{
    position: relative;
    width: 900px;
    margin: 10px auto 40px;
  }
}

.l-content-master{
  padding: 0 0 110px; /* フッターの高さ */
}

.l-common-fixing{
  position: fixed;
  top: 0;
  width: $width_all;
  z-index: 100;
}

.l-common-fixing_style{
  margin: 93px auto 0;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-contents{
    padding: 0 0 130px; /* フッターの高さ */
    margin: 20px auto 0;
    width: $width_all;
    z-index: 1;
    position: relative;
    overflow: visible;
    .l-contents-l{
      width: $width_sp;
      float: none;
      margin: 0 auto 15px;
    }
    .l-contents-r{
      width: $width_sp;
      float: none;
      margin: 0 auto;
    }
    .l-contents-join{
      width: $width_sp;
      margin: 0 auto 40px;
    }
  }

  .l-content-master{
    padding: 0 0 100px; /* フッターの高さ */
  }

  .l-common-fixing_style{
    margin: 67px auto 0;
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-contents{
    padding: 0 0 145px; /* フッターの高さ */
  }

}

/***************************************
 ヘッダー
***************************************/

header{
  border-bottom: solid 1px #C6D2E6;
  background: $color_blue3;
}

.l-master-header{
  border-bottom: solid 1px #D4E0C7;
  background: #F1F7EB;
}

.l-common-headerArea{
  width: $width_base;
  margin: 0 auto;
  padding: 10px 0 12px;
  position: relative;
}

.m-common-headerTitle{
  display: inline-block;
  font-size: 11px;
  margin: 0 0 10px;
  line-height: 1;
  font-weight: normal;
  color: $color_gray3;
}

.m-common-headerLogo{
  a{
    display: block;
    @include common_bgImg(40px, 230px);
    // background: url(../img/recruiter_common_headerLogo.png) no-repeat;
    background-size: cover;
    &:hover{
      @include common_Alpha(1);
    }
  }
}

.m-common-headerLogoAdmin{
  a{
    display: block;
    @include common_bgImg(40px, 230px);
    // background: url(../img/common_headerLogoAdmin.png) no-repeat;
    background-size: cover;
    &:hover{
      @include common_Alpha(1);
    }
  }
}

.m-common-headerLogoMaster{
  a{
    display: block;
    @include common_bgImg(40px, 258px);
    // background: url(../img/common_headerLogoMaster.png) no-repeat;
    background-size: cover;
    &:hover{
      @include common_Alpha(1);
    }
  }
}

.l-common-headerMenuArea_pc{
  position: absolute;
  top: 0;
  right: 0;
  padding: 13px 0 0;
}

.l-common-headerMenuSimple_pc{
  position: absolute;
  top: 0;
  right: 0;
  padding: 19px 0 0;
}

.l-common-headerMenuArea_sp{
  display: none;
}

.l-common-headerNav_pc{
  display: inline-block;
  margin: 0 10px 0 0;
  ul{
    li{
      display: inline-block;
      // background: url(../img/common_arrow_rg.png) no-repeat;
      background-size: auto 100%;
      line-height: 1;
      text-indent: 14px;
      font-weight: bold;
      margin: 0 16px 0 0;
      a{
        color: $color_base;
      }
    }
  }
}

.l-common-headerBtn{
  @include common_Height(36px);
  background: $color_base;
  border-radius: 4px;
  color: #ffffff;
  text-align: center;
  display: inline-block;
  margin: 0 0 0 3px;
  padding: 0 12px;
}

.l-common-headerPulldown_pc{
  display: inline-block;
  position: relative;
  ul{
    position: absolute;
    width: 100%;
    background: #C5D6E8;
    z-index: 1;
    li{
      border-bottom: dotted 1px $color_blue3;
      &:last-child{
        border: none;
      }
      a{
        display: block;
        color: $color_blue2;
        line-height: 1;
        padding: 12px;
      }
    }
  }
}

.l-common-headerPulldownBtn_pc{
  @include common_Height(34px);
  color:  $color_base;
  font-weight: bold;
  display: inline-block;
  padding: 0 33px 0 12px;
  // background: url(../img/index_arrow_pulldown_03.png) #ffffff no-repeat right center;
  border-radius: 0px;
  border: solid 1px  $color_gray3;
  min-width: 100px;
}

.l-common-headerSearchArea_sp{
  display: none;
}

.m-recruit-header_ico_pc{
  position: absolute;
  top: 0;
  right: 10px;
  height: 62px;
  display: table;
  div{
    text-align: right;
    display: table-cell;
    vertical-align: middle;
  }
  p{
    font-size: 10px;
    color: $color_gray2;
    height: 16px;
    margin: 2px 0;
    line-height: 1;
  }
  span{
    margin: 0 0 0 3px;
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-common-headerArea{
    width: $width_all;
    margin: 0 auto;
    padding: 10px 0 2px;
  }

  .m-common-headerTitle{
    display: none;
  }

  .m-common-headerLogo{
    display: inline-block;
    a{
      @include common_bgImg(26px, 150px);
      margin: 0 0 0 10px;
    }
  }

  .m-common-headerLogoAdmin{
    display: inline-block;
    a{
      @include common_bgImg(26px, 150px);
      margin: 0 0 0 10px;
    }
  }

  .m-common-headerLogoMaster{
    display: inline-block;
    a{
      @include common_bgImg(26px, 168px);
      margin: 0 0 0 10px;
    }
  }

  .l-common-headerMenuArea{
    display: none;
  }

  .l-common-headerMenuArea_pc{
    display: none;
  }
  .l-common-headerMenuArea_sp{
    display: block;
    position: absolute;
    top: 12px;
    right: 10px;
  }

  .l-common-headerBtn{
    @include common_Height(25px);
    background: $color_red1;
    border-radius: 4px;
    color: #ffffff;
    text-align: center;
    display: inline-block;
    position: relative;
    top: -2px;
    margin: 0 0 0 2px;
    padding: 0 8px;
    font-size: 12px;
  }

  .l-common-headerMenu_sp{
    width: 24px;
    margin: 0 0 0 10px;
  }

  .l-common-headerMenu-list_sp{
    z-index: 3;
    position: absolute;
    right: 0;
    background: $color_gray1;
    width: 60%;
    ul{
      display: block;
      li{
        line-height: 1;
        border-bottom: dotted 1px $color_gray2;
        &:last-child{
          border: none;
        }
        a{
          padding: 18px 12px;
          display: block;
          color: #ffffff;
        }
      }
    }
  }

  .l-common-headerSearchArea_sp{
    display: block;
    margin: 0 0 20px;
    order: 3;
    input[type="text"]{
      @include common_Height(40px);
      border: solid 1px $color_gray3;
      border-right: none;
      border-radius: 4px 0 0 4px;
      box-sizing: border-box;
      vertical-align: top;
      padding: 0 8px;
      display: inline-block;
      width: 90%;
      font-size: 14px;
      -webkit-appearance: none;
    }
    input[type="submit"]{
      border: 0;
      // background: url(../img/common_icon_search.png) $color_gray1 no-repeat center;
      background-size: 20px auto;
      @include common_Height(40px);
      width: 10%;
      border-radius: 0 4px 4px 0;
      display: inline-block;
    }
  }

  .m-recruit-header_ico_sp{
    position: absolute;
    top: 0;
    right: 45px;
    height: 48px;
    display: table;
    div{
      text-align: right;
      display: table-cell;
      vertical-align: middle;
    }
    p{
      font-size: 10px;
      color: $color_gray2;
      height: 16px;
      margin: 2px 0;
      line-height: 1;
    }
    span{
      margin: 0 0 0 3px;
    }
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-common-headerSearchArea_sp{
    input[type="text"]{
      width: 82%;
    }
    input[type="submit"]{
      width: 18%;
    }
  }

}

/***************************************
 フッター
***************************************/

footer{
  width: $width_all;
  height: 130px;
  position: absolute;
  bottom: 0;
  overflow: hidden;
}

.l-footer-master{
  height: 95px;
}

.l-common-pagetopBox{
  width: $width_base;
  margin: 0 auto 5px;
  text-align: right;
  a{
    display: inline-block;
    @include common_bgImg(42px, 42px);
    border-radius: 4px;
    border: solid 1px #dddddd;
    background: url(../img/common_arrow_pagetop.png) #ffffff no-repeat center;
    background-size: 60%;
  }
}

.l-common-footerBox{
  background: #eeeeee;
  padding: 10px 0 10px;
  text-align: center;
  min-height: 75px;
  small{
    display: block;
    margin: 0 auto;
    width: $width_base;
    color: $color_gray1;
  }
}

.l-common-footerMenu{
  margin: 5px auto;
  width: $width_base;
  ul{
    text-align: center;
    margin: 0;
    display: inline-block;
    vertical-align: top;
    li{
      font-size: 12px;
      margin: 0 10px;
      line-height: 1.6;
      display: inline-block;
      a{
        color: $color_gray2;
      }
    }
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  footer{
    height: 124px;
  }

  .l-footer-master{
    height: 88px;
  }

  .l-common-pagetopBox{
    width: $width_sp;
    a{
      @include common_bgImg(36px, 36px);
      background-size: 55%;
    }
  }

  .l-common-footerBox{
    small{
      width: $width_all;
      font-size: 10px;
    }
  }

  .l-common-footerMenu{
    width: $width_all;
    ul{
      li{
        margin: 0 5px;
      }
    }
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  footer{
    height: 143px;
  }

  .l-common-footerMenu{
    ul{
      li{
        margin: 0 5px;
        font-size: 11px;
      }
    }
  }

}

/***************************************
共通パーツ
***************************************/

.l-common-baseArea{
  margin: 0 0 30px;
  overflow: hidden;
}

.m-common-h2{
  @include common_Height(42px);
  border-radius: 3px;
  color: #ffffff;
  padding: 0 10px;
  font-size: 17px;
  font-weight: normal;
  background: $color_base;
  margin: 0 0 20px;
}

.m-common-h3{
  border-bottom: solid 2px $color_blue2;
  padding: 0 10px 6px;
  margin: 10px 0;
  font-size: 16px;
  line-height: 1.5;
}

.l-common-sideBox_job{
  &:hover{
    @include common_Alpha;
  }
  padding: 15px 0 10px;
  overflow: hidden;
  border-bottom: dotted 1px $color_gray2;
  .m-common-sideImg_job{
    width: 126px;
    height: auto;
    background: #cccccc;
    float: left;
    margin: 0 10px 0 0;
    overflow: hidden;
    img{
      width: 100%;
    }
  }
  .m-common-sideTitle_job{
    width: 160px;
    float: left;
    color: $color_base;
    margin: -4px 0 0;
  }
  h3{
    font-size: 12px;
    font-weight: normal;
    line-height: 1.4;
    color: $color_gray1;
    margin: 0 0 3px;
  }
  h4{
    font-size: 13px;
    line-height: 1.4;
    margin: 3px 0 0;
  }
}

.m-common-sideIcn_coat{
  display: inline-block;
  background: $color_blue1;
  color: #ffffff;
  @include common_Height(18px);
  border-radius: 3px;
  padding: 0 5px;
  font-size: 11px;
  margin: 0 3px 0 0;
  vertical-align: middle;
}

.m-common-sideIcn_frame{
  display: inline-block;
  border: solid 1px $color_gray2;
  color: $color_gray2;
  background: #ffffff;
  border-radius: 3px;
  padding: 0 5px 1px;
  font-size: 11px;
  margin: 0 3px 0 0;
  vertical-align: middle;
}

.l-common-sideBox_co{
  border: solid 1px $color_gray3;
  box-sizing: border-box;
  margin: 15px 0;
  padding: 10px 10px 5px;
  overflow: hidden;
  &:hover{
    @include common_Alpha;
  }
  div{
    position: relative;
    display: block;
    width: 100%;
    height: 150px;
    overflow: hidden;
    margin: 0 0 5px;
  }
  img{
    width: 100%;
    position: relative;
    top: -10px;
  }
  span{
    position: relative;
    top: -1px;
    margin: 0 0 0 10px;
  }
  h3{
    display: inline-block;
    font-size: 14px;
    color: $color_base;
    line-height: 1.5;
  }
}

.l-common-sideBox_interview{
  border: solid 1px $color_gray3;
  box-sizing: border-box;
  margin: 15px 0;
  padding: 10px 10px 8px;
  overflow: hidden;
  &:hover{
    @include common_Alpha;
  }
  div{
    position: relative;
    display: block;
    width: 100%;
    height: 150px;
    overflow: hidden;
    margin: 0 0 6px;
  }
  img{
    width: 100%;
    position: relative;
    top: -10px;
  }
  span{
    display: block;
  }
  h3{
    display: inline-block;
    font-size: 14px;
    color: $color_base;
    line-height: 1.5;
    margin: 0;
  }
}

.l-common-baseBox{
  display: block;
  float: left;
  width: ($width_left - 30) / 3;
  box-sizing: border-box;
  margin: 15px 15px 0 0;
  &:hover{
    @include common_Alpha;
  }
  &:nth-child(3n){
    margin-right: 0;
  }
  &:nth-child(-n+3){
    margin-top: 0;
  }
  img{
    width: 100%;
    display: block;
  }
}

.l-common-baseBox_text{
  h3{
    color: $color_base;
    line-height: 1.4;
    font-size: 16px;
    margin: 8px 0 0;
    text-justify: inter-ideograph;
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-common-baseArea{
    margin: 0 0 20px;
    display: flex;
    flex-flow: column wrap;
  }

  .l-common-baseBox{
    width: ($width_all - 3) / 2;
    margin: 16px 0 0;
    &:nth-child(3n){
      margin-right: 0;
    }
    &:nth-child(-n+3){
      margin-top: 16px;
    }
    &:nth-child(even){
      float: right;
    }
    &:nth-child(-n+2){
      margin-top: 0;
    }
  }

  .l-common-baseBox_text{
    h3{
      font-size: 16px;
    }
  }

  .m-common-h2{
    height: auto;
    padding: 8px 10px;
    font-size: 16px;
    margin: 0 0 15px;
    line-height: 1.4;
  }

  .m-common-h3{
    border-bottom: solid 2px $color_blue2;
    padding: 0 5px 5px;
    margin: 10px 0;
    font-size: 15px;
    line-height: 1.5;
  }

  .l-common-interview-categoryBox{
    margin: 20px 0 10px;
    text-align: left;
    border: solid 1px $color_gray3;
    font-size: 13px;
    order: 2;
    li{
      width: $width_all / 2;
      display: block;
      float: left;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      text-indent: 12px;
      border-bottom: dotted 1px $color_gray3;
      border-right: dotted 1px $color_gray3;
      background-size: auto 30%;
      background-position: 10px center;
      &:nth-last-child(-n+2){
        border-bottom: none;
      }
      &:nth-child(even){
        border-right: none;
      }
      a{
        display: block;
        position: relative;
        top: 0;
        padding: 10px;
      }
    }
  }

  .l-common-interviewPickupArea{
    order: 1;
  }

  .l-common-interviewPickup{
    width: ($width_all - 2) / 2;
    margin: 12px 0 0;
    div{
      height: 160px;
      img{
        position: relative;
        top: -5px;
      }
    }
    h3{
      font-size: 16px;
      padding: 10px;
    }
  }

  @mixin interview_ico_base {
    display: block;
    @include common_Height(22px);
    border-radius: 0;
    min-width: 100%;
    text-indent: 8px;
    font-size: 12px;
    position: absolute;
    top: inherit;
    bottom: 0;
  }

  .m-common-interview_icoGreen{
    @include interview_ico_base;
  }

  .m-common-interview_icoRed{
    @include interview_ico_base;
  }

  .m-common-interview_icoOrange{
    @include interview_ico_base;
  }

  .m-common-interview_icoPurple{
    @include interview_ico_base;
  }

  .l-common-sideBox_job{
    .m-common-sideImg_job{
      width: 38%;
    }
    .m-common-sideTitle_job{
      width: 60%;
    }
    h3{
      font-size: 14px;
    }
    h4{
      font-size: 17px;
      margin: 10px 0 0;
    }
  }

  .l-common-sideBox_co{
    div{
      height: 300px;
    }
    h3{
      margin: 3px 0;
      font-size: 14px;
    }
  }

  .l-common-sideBox_interview{
    border: none;
    margin: 0;
    padding: 10px 10px 8px 0;
    border-bottom: dotted 1px #a1a1a1;
    div{
      width: 40%;
      height: 130px;
      overflow: hidden;
      margin: 0 0 6px;
      float: left;
      img{
        position: relative;
        top: 0;
      }
    }
    h3{
      box-sizing: border-box;
      font-size: 17px;
      padding: 0 0 0 10px;
      float: left;
      width: 60%;
    }
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-common-baseBox{
    width: ($width_all - 3) / 2;
    margin: 10px 0 0;
    &:nth-child(3n){
      margin-right: 0;
    }
    &:nth-child(-n+3){
      margin-top: 10px;
    }
    &:nth-child(even){
      float: right;
    }
    &:nth-child(-n+2){
      margin-top: 0;
    }
  }

  .l-common-baseBox_text{
    h3{
      font-size: 12px;
      margin: 8px 0 0;
    }
  }

  .m-common-h3{
    border-bottom: solid 2px $color_blue2;
    padding: 0 5px 3px;
    margin: 10px 0;
    font-size: 14px;
    line-height: 1.5;
  }

  .l-common-interviewPickup{
    width: ($width_all - 3) / 2;
    div{
      height: auto;
      img{
        position: relative;
        top: 0;
      }
    }
    h3{
      font-size: 13px;
      padding: 10px 8px;
    }
  }

  @mixin interview_ico_base {
    @include common_Height(18px);
    font-size: 10px;
  }

  .m-common-interview_icoGreen{
    @include interview_ico_base;
  }
  .m-common-interview_icoRed{
    @include interview_ico_base;
  }
  .m-common-interview_icoOrange{
    @include interview_ico_base;
  }
  .m-common-interview_icoPurple{
    @include interview_ico_base;
  }

  .l-common-sideBox_job{
    .m-common-sideImg_job{
      width: 40%;
    }
    .m-common-sideTitle_job{
      width: 55%;
    }
    h3{
      font-size: 12px;
    }
    h4{
      font-size: 13px;
      margin: 5px 0 0;
    }
  }

  .l-common-sideBox_co{
    div{
      height: 190px;
    }
  }

  .l-common-sideBox_interview{
    div{
      height: 80px;
      margin: 0 0 6px;
    }
    h3{
      font-size: 13px;
      padding: 0 0 0 10px;
    }
  }

}

/***************************************
 ローディング
***************************************/

.m-loading{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: $width_all;
  height: $width_all;
  margin: auto;
  background: #F5F5F5;
  z-index: 100;
  img{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 50px;
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .m-loading{
    img{
      width: 50px;
    }
  }

}

/***************************************
 ページネーション
***************************************/

.l-common-pagerArea{
  margin: 10px 0;
  span{
    font-size: 12px;
  }
  ul{
    margin: 3px 0 0;
    li{
      display: inline-block;
      position: relative;
      margin: 0 1px 0 0;
      @include common_Height(32px);
      width: 32px;
      overflow: hidden;
      font-size: 12px;
      text-align: center;
      vertical-align: top;
      border-radius: 3px;
      a{
        display: block;
        @include common_Height(32px);
        width: 32px;
      }
    }
  }
}

.m-common-pager_nolink{
  background: $color_gray3;
  color: #ffffff;
  border-radius: 3px;
}

.m-common-pager_link{
  background: #ffffff;
  color: $color_gray2;
  border: solid 1px $color_gray2;
  box-sizing: border-box;
  border-radius: 3px;
  img{
    width: 8px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.m-common-pagerBtn_sp{
  display: none;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {
  .l-common-pagerArea{
    text-align: center;
    ul{
      li{
        @include common_Height(36px);
        width: 36px;
        font-size: 12px;
        a{
          @include common_Height(36px);
          width: 36px;
          img{
            width: 9px;
          }
        }
      }
    }
  }

  .l-common-pagerArea_pc{
    display: none;
  }

  .m-common-pagerBtn_sp{
    display: block;
    border: solid 1px $color_gray3;
    @include common_Height(48px);
    border-radius: 3px;
    color: $color_base;
    margin: 15px auto;
    font-size: 16px;
    width: 254px;
    img{
      height: 16px;
      vertical-align: middle;
      position: relative;
      top: -2px;
    }
  }

}

/***************************************
 エラーメッセージ
***************************************/

.l-common-searchMessage{
  background: $color_gray4;
  border-radius: 4px;
  padding: 25px;
  text-align: center;
  line-height: 1.5;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 20px;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-common-searchMessage{
    padding: 25px 15px;
    font-size: 14px;
    margin: 0 0 15px;
  }

}

/***************************************
 検索
***************************************/

.l-common-searchArea_pc{
  letter-spacing: -0.4em;
  margin: 0 0 20px;
  input[type="text"]{
    @include common_Height(42px);
    border: solid 1px $color_gray3;
    border-right: none;
    box-sizing: border-box;
    vertical-align: top;
    padding: 0 8px;
    display: inline-block;
    width: 300px;
  }
  input[type="submit"]{
    border: 0px;
    // background: url(../img/common_icon_search.png) $color_gray1 no-repeat center;
    background-size: 20px auto;
    @include common_Height(42px);
    width: 70px;
    border-radius: 0 3px 3px 0;
    display: inline-block;
  }
}

.l-common-search_box_pc{
  position: relative;
  z-index: 1;
  vertical-align: top;
  a{
    display: block;
    box-sizing: border-box;
    border: solid 1px $color_gray3;
    border-right: none;
    // background: url(../img/common_arrow_bg.png) no-repeat right center;
    background-size: 22px auto;
    padding: 0 8px;
    @include common_Height(42px);
    color: $color_base;
  }
  ul{
    position: absolute;
    top: 42px;
    background: $color_gray4;
    padding: 10px 15px;
    border: 1px solid #e5e5e5;
    border-top: none;
    box-sizing: border-box;
    li{
      padding: 0 3px;
      display: inline-block;
    }
  }
}

.m-common-search_jobCategory_pc{
  a{
    width: 120px;
  }
  ul{
    width: 570px;
    li{
      width: 120px;
    }
  }
}

.m-common-search_business_pc{
  a{
    width: 120px;
  }
  ul{
    width: 570px;
    li{
      width: 120px;
    }
  }
}

.m-common-search_area_pc{
  a{
    width: 130px;
  }
  ul{
    width: 600px;
    li{
      width: 130px;
    }
  }
}

.m-common-search_skill_pc{
  a{
    width: 190px;
  }
  ul{
    width: 500px;
    li{
      width: 140px;
    }
  }
}

.m-common-search_recommend_pc{
  a{
    width: 120px;
  }
  ul{
    width: 121px;
    li{
      width: 90px;
    }
  }
}

.l-common-search_selectArea_pc{
  position: relative;
  top: -15px;
  font-size: 12px;
  margin: 0 0 10px;
  color: $color_gray1;
  dl{
    display: block;
    margin: 0;
    padding: 10px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    overflow: hidden;
    dt{
      display: inline-block;
      font-weight: bold;
      border-left: 1px dotted $color_gray3;
      padding: 0 0 0 15px;
      &:first-child{
        border: none;
      }
    }
    dd{
      margin: 0 0 0 15px;
      display: inline-block;
      span{
        margin: 0 15px 0 0;
        display: inline-block;
      }
    }
  }
}

.l-common-searchArea_sp{
  display: none;
}

.l-common-side-searchBox{
  margin: 0 0 20px;
  h2{
    font-size: 16px;
    color: $color_gray1;
    line-height: 1;
    margin: 0 0 6px;
  }
  input[type="text"]{
    @include common_Height(38px);
    border: solid 1px $color_gray3;
    border-right: none;
    border-radius: 3px 0 0 3px;
    box-sizing: border-box;
    vertical-align: top;
    padding: 0 8px;
    display: inline-block;
    width: 250px;
    font-size: 14px;
  }
  input[type="submit"]{
    border: 0px;
    // background: url(../img/common_icon_search.png) $color_gray1 no-repeat center;
    background-size: 20px auto;
    @include common_Height(38px);
    width: 50px;
    border-radius: 0 3px 3px 0;
    display: inline-block;
  }
}

.l-search-btnBox{
  text-align: center;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-common-side-searchBox{
    margin: 0 0 15px;
    text-align: center;
    h2{
      font-size: 16px;
      color: $color_gray1;
      line-height: 1;
      margin: 0 0 6px;
    }
    input[type="text"]{
      @include common_Height(38px);
      border: solid 1px $color_gray3;
      border-right: none;
      border-radius: 3px 0 0 3px;
      box-sizing: border-box;
      vertical-align: top;
      padding: 0 8px;
      display: inline-block;
      width: 230px;
      font-size: 14px;
    }
  }

}

/***************************************
 吹き出しメッセージ
***************************************/

.l-common-noticeBalloon{
  text-align: center;
  div{
    background: #FFA439;
    padding: 15px;
    line-height: 1.5;
    color: #ffffff;
    font-size: 18px;
    border-radius: 8px;
    .m-common-br:before{
      content: "\A" ;white-space: pre ;
    }
  }
  img{
    width: 20px;
  }
}

.l-common-noticeBalloon_color{
  color: #F32D2B;
  font-weight: bold;

}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-common-noticeBalloon{
    div{
      padding: 10px 20px;
      font-size: 16px;
    }
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-common-noticeBalloon{
    margin: 0;
    div{
      font-size: 13px;
      text-align: left;
      .m-common-br:before{
        content: normal;
        white-space: normal;
      }
    }
    img{
      width: 16px;
    }
  }

}


/***************************************
 ゼロワン新卒用アカウント設定
***************************************/

// ヘッダー

.l-scout-headerStyle{
  background: #ffffff;
  border-bottom: solid 1px #d2d2d2;
}

.m-scout-headerLogo{
  padding: 10px 0 6px;
  a:hover{
    @include common_Alpha(1);
  }
  img{
    width: 100%;
    display: inline-block;
    width: 180px;
    position: relative;
    top: 2px;
  }
}

// コンテンツエリア

.l-contents_scout{
  padding: 0 0 124px;
}

.l-recruit-baseArea_scout{
  width: 820px;
  margin: 0 auto;
}

.l-recruit-btnBox {
    margin: 15px auto 0;
    text-align: center;
}

.m-recruit-btn_scout{
  background: $color_blue1;
  border: none;
  border-bottom: solid 4px $color_blue2;
  display: inline-block;
  border-radius: 5px;
  color: #ffffff;
  font-size: 15px;
  text-align: center;
  margin: 0 2px;
  vertical-align: top;
  @include common_Height(45px);
  width: 300px;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  // ヘッダー

  .m-scout-headerLogo{
    img{
      width: 130px;
      position: relative;
      top: -3px;
      left: 10px;
    }
  }

  // コンテンツエリア

  .l-contents_scout{
    padding: 0 0 108px;
  }

  .l-recruit-baseArea_scout{
    width: 95%;
    margin: 0 auto;
  }

  .m-recruit-btn_scout{
    @include common_Height(40px);
    width: 240px;
  }

}



/***************************************
 表示要素調整（実装後削除）
***************************************/

// 非表示の実装後削除

.l-common-baseArea{
  .display_style{
    display: none;
  }
}

.display_style{
  display: none;
}


/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  // 非表示の実装後削除
  .l-common-baseArea{
    .display_style{
      display: block;
    }
  }

  .display_style{
    display: block;
  }

}
