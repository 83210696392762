@import "./normalize";
/* reset */

/* set border-box for all */
*,
*:before,
*:after {
	box-sizing: border-box;
	-webkit-text-size-adjust: none;
}
/* cross-browser styles for root */
:root {
	-webkit-text-size-adjust: none;
	-ms-text-size-adjust: 100%;
}
/* default class */
.wrapper {
	display: flex;
	flex-flow: column nowrap;
	max-width: 100%;
	min-height: 100vh;
	overflow: hidden;
	position: relative;
}
