@charset "utf-8";

// ▼Sass設定

@import "_variables.scss";

/*********************
 アイキャッチ
*********************/

@mixin pc {
  @media screen and (min-width:768px) {
    @content;
  }
}

@mixin sp {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin hover {
  @media screen and (min-width: 768px) {
    &:hover {
      @content;
    }
  }
}

@media (min-width: 768px) {
  .sp-only {
    display: none !important;
  }
}

@include sp {
  .pc-only {
    display: none !important;
  }
}

.l-index-main {
  width: $width_all;
  // background: url(../img/index-main.jpg) no-repeat center;
  background-size: cover;
  height: 434px;
  text-align: center;
  color: #ffffff;
  position: relative;
}

.l-index-main_box {
  position: relative;
  top: 65px;
  width: $width_base;
  margin: 0 auto;
}

.l-index-mainCatch {
  font-size: 35px;
  line-height: 1.5;
  padding: 47px 25px 0 53px;
  display: inline-block;
  text-indent: -25px;
  letter-spacing: 0.05em;
  // background: url(../img/index_main_balloon.png) no-repeat;

  .l-index-main_margin {
    margin: 0 -12px 0 0;
  }
}

.l-index-mainSubCatch {
  font-size: 19px;
  font-weight: bold;
  line-height: 1.5;

  span {
    border-bottom: 1px solid #fff;
  }
}

.l-index-main_search {
  @include pc {
    position: absolute;
    top: 360px;
    left: 0;
    right: 0;
  }

  @include sp {
    border: 1px solid #dedede;
    position: relative;
    background: #f6f6f6;
    margin: -40px 10px 0;
    border-radius: 6px;
    padding: 15px 10px 20px;
    box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.2);
  }
}

.l-index-merit_pc {
  background: rgba(67, 187, 221, 0.25);
  overflow: hidden;
  position: absolute;
  bottom: 0;
  width: $width_all;

  article {
    text-align: left;
    width: $width_base;
    margin: 0 auto;
  }

  section {
    width: $width_base / 3;
    box-sizing: border-box;
    float: left;
    padding: 0 8px;
    font-size: 14px;
    line-height: 1.6;
    margin: 15px 0 10px;

    h3 {
      font-size: 21px;
      letter-spacing: 0.05em;
    }

    p {
      margin: 10px 0;
      text-align: justify;
      text-justify: inter-ideograph;
    }

    img {
      display: block;
      margin: 0 auto 10px;
      width: 40%;
    }
  }
}

.l-index-merit_sp {
  display: none;
}

.l-contents.top-page-contents {
  margin-top: 15px;
}

.l-special-content-area h2 {
  border: solid 1px #5589AD;
  border-radius: 3px;
  color: #5589AD;
  padding: 10px;
  margin: 28px 0 20px;
  font-size: 18px;
  line-height: 1.5;
}

.l-special-content-sub-area {
  margin-bottom: 28px;

  h3 {
    border-bottom: solid 1px #5589AD;
    padding-bottom: 6px;
  }
}

.l-sub-job-listArea{
  margin: 0 0 30px;
  overflow: hidden;
}

/* ############ 1099px以下 ############ */
@media (max-width: 1099px) {

  .l-index-main {
    // background: url(../img/index-main.jpg) no-repeat 0 0;
    background-size: cover;
  }

  .l-index-mainSubCatch {
    font-size: 18px;
    line-height: 1.5;
  }

}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-index-main {
    // background: url(../img/index-main.jpg) no-repeat 0 -30px;
    background-size: cover;
    height: 360px;
  }

  .l-index-main_box {
    position: relative;
    top: 60px;
    width: $width_sp;
    margin: 0 auto;
  }

  .l-index-mainCatch {
    font-size: 32px;
    padding: 39px 50px 0;
    text-indent: -25px;
    // background: url(../img/index_main_balloon.png) no-repeat;
    background-size: 72px auto;
  }

  .l-index-mainSubCatch {
    font-size: 17px;
  }

  .l-index-main_br:before {
    content: "\A";
    white-space: pre;
  }

  .l-index-merit_pc {
    display: none;
  }

  .l-index-merit_sp {
    display: block;
    margin: 0 auto;

    section {
      overflow: hidden;
      border-bottom: solid 1px #e5e5e5;
    }

    img {
      width: 32%;
      float: left;
      margin: 0 15px 0;
    }

    div {
      padding: 22px 30px 22px 22px;
    }

    h3 {
      font-size: 22px;
    }

    p {
      font-size: 16px;
      line-height: 1.6;
      text-align: justify;
      color: $color_gray2;
    }
  }

  .l-contents.top-page-contents {
    margin-top: 20px;
  }

  .l-special-content-area {
    padding: 0 15px;
  }

  .l-special-content-area h2 {
    padding: 10px;
    margin: 26px 0 15px;
    font-size: 15px;
  }

  .l-special-content-sub-area {
    margin-bottom: 26px;
  }

  .l-sub-job-listArea{
    margin: 0 0 20px;
    -webkit-flex-flow: column wrap;
    flex-flow: column wrap;
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-index-main {
    // background: url(../img/index-main.jpg) no-repeat 0 -60px;
    background-size: cover;
    height: 200px;
  }

  .l-index-main_box {
    position: relative;
    top: 28px;
    width: $width_sp;
    margin: 0 auto;
  }

  .l-index-mainCatch {
    font-size: 21px;
    padding: 22px 12px 0;
    text-indent: 0;
    // background: url(../img/index_main_balloon.png) no-repeat;
    background-size: 40px auto;
  }

  .l-index-mainSubCatch {
    font-size: 11px;
  }

  .l-index-merit_sp {
    section {
      padding: 0 0 5px;
    }

    img {
      width: 24%;
      margin: 12px 5px 0;
    }

    div {
      padding: 10px 10px 10px 0;
    }

    h3 {
      font-size: 16px;
    }

    p {
      font-size: 12px;
      margin: 5px 0 0;
      line-height: 1.5;
    }
  }

  .l-special-content-area {
    font-size: 13px;
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .l-index-main {
    // background: url(../img/index-main.jpg) no-repeat 0 -30px;
    background-size: cover;
    height: 205px;
  }

  .l-index-mainCatch {
    font-size: 19px;
    padding: 17px 14px 0;
    text-indent: -5px;
    // background: url(../img/index_main_balloon.png) no-repeat;
    background-size: auto 30%;
  }

  .l-index-mainSubCatch {
    font-size: 10px;
    font-weight: normal;
  }

  .l-index-merit_sp {
    div {
      padding: 10px;
    }

    h3 {
      font-size: 15px;
    }

    p {
      font-size: 10px;
      margin: 0;
    }
  }

}

/*********************
 登録ボタン
*********************/

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-form-btnBox {
    margin: 0 0 25px;
    text-align: center;

    span {
      display: block;
      margin: 0 0 8px;

      a {
        text-decoration: underline;
      }
    }
  }

  .m-index-btn_register {
    display: inline-block;
    background: $color_red1;
    border-radius: 4px;
    border: none;
    border-bottom: solid 4px $color_red2;
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    width: 60%;
    @include common_Height(62px);
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .m-index-btn_register {
    font-size: 16px;
    width: 80%;
    @include common_Height(54px);
  }

}

/*********************
 サマリー
*********************/

.l-index-topSummary {
  border: solid 1px $color_gray3;
  margin: 35px 0;
  display: table;
  color: $color_gray1;
}

.l-index-topSummary_sp {
  display: none;
}

.l-index-topSummary_logo {
  width: 180px;
  display: table-cell;
  position: relative;
  vertical-align: middle;

  img {
    height: 36px;
    width: auto;
    display: block;
    position: relative;
    margin: auto;
  }
}

.l-index-topSummary_text {
  display: table-cell;
  border-left: solid 1px $color_gray3;
  padding: 12px 15px;

  h2 {
    font-size: 12px;
    margin: 0 0 4px;
  }

  p {
    font-size: 11px;
    margin: 0 0 10px;
    line-height: 1.7;
    text-align: justify;
    text-justify: inter-ideograph;
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-index-topSummary {
    display: none;
  }

  .l-index-topSummary_sp {
    border: none;
    display: table;
    color: $color_gray1;
  }

  .l-index-topSummary_text_sp {
    display: table-cell;
    border-left: none;
    padding: 25px 15px;

    h2 {
      font-size: 12px;
      margin: 0 0 4px;
    }

    p {
      font-size: 11px;
      margin: 0 0 10px;
      line-height: 1.7;
      text-align: justify;
      text-justify: inter-ideograph;
    }
  }
}


.c-form {
  width: 940px;
  margin: 0 auto;
  text-align: left;
  $root: &;

  @include sp {
    width: 100%;
  }

  &__title {
    font-size: 16px;
    margin-bottom: 2px;
    color: #fff;
    font-weight: bold;

    @include sp {
      text-align: center;
      color: #156a87;
      font-weight: normal;
      margin-bottom: 5px;
    }
  }

  &__box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    border-radius: 4px;

    @include sp {
      justify-content: space-between;
      background: none;
    }
  }



  &__key {
    width: 180px !important;
    padding: 0 !important;
    margin: 0 !important;
    background: none !important;
    border: none !important;
    padding: 0 18px !important;
    color: #555;
    background: #fff !important;
    height: 64px !important;
    font-weight: bold;
    font-size: 15px;

    &::placeholder {
      color: #ccc;
      opacity: 1;
    }

    @include sp {
      width: 100% !important;
      height: 44px !important;
      border-radius: 6px !important;
      border: 1px solid #dddddd !important;
    }
  }

  &__anchor {
    @include sp {
      width: 100% !important;
    }
  }

  &__submit {
    background: #f65e47;
    border: none;
    padding: 0;
    margin: 0;
    width: 68px;
    height: 64px;
    border-radius: 0 4px 4px 0;

    @include hover {
      opacity: 0.8;
    }

    @include sp {
      width: 100%;
      border-radius: 6px;
      margin-top: 12px;
      background: #1e85a8;
      height: 50px;
    }

    >* {
      color: #fff;
      font-size: 18px;
      vertical-align: middle;
      width: 18px;
    }
  }

  &__checkbox {
    width: 172px;
    border-right: 1px solid #dddddd;
    position: relative;

    @include sp {
      width: calc(50% - 3px);
      border: none;
      margin-bottom: 6px;
      text-align: left;
    }

    &:first-child {
      a {
        @include pc {
          border-radius: 4px 0 0 4px;
        }
      }

      #{$root}__val {
        @include pc {
          grid-template-columns: auto auto auto auto auto;
        }

        @include sp {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          li {
            width: 50%;
          }
        }
      }
    }

    &:nth-child(4) {
      #{$root}__val {
        @include pc {
          right: -100%;
          left: inherit;
          grid-template-columns: auto auto auto;
        }
      }

      a {
        @include sp {
          padding-left: 8px;
        }
      }
    }

    &:nth-child(2n+2) {
      #{$root}__val {
        @include sp {
          right: 0;
          left: inherit;
        }
      }
    }

    a {
      color: #555555;
      font-weight: bold;
      font-size: 15px;
      line-height: 64px;
      display: block;
      padding-right: 15px;
      position: relative;
      text-align: center;
      opacity: 1 !important;
      cursor: pointer;
      background: #fff;

      @include sp {
        text-align: left;
        padding-right: 0;
        padding-left: 10px;
        line-height: 42px;
        white-space: nowrap;
        border: 1px solid #dddddd;
        border-radius: 6px;
      }

      @include hover {
        background: #f1f9fb;
      }

      &.active {
        background: #f1f9fb;

        &::after {
          content: '';
          position: absolute;
          left: calc(50% - 12px);
          bottom: -38px;
          width: 20px;
          height: 20px;
          background: #f1f9fb;
          z-index: 99;
          border-left: 1px solid #dddddd;
          border-top: 1px solid #dddddd;
          transform: rotate(45deg);

          @include sp {
            bottom: -31px;

          }
        }
      }

      &.selecting {
        background: #f1f9fb;
        color: #1e85a9;
      }

      &::before {
        content: '';
        width: 12px;
        height: 8px;
        position: absolute;
        top: calc(50% - 4px);
        right: 14px;
        transition: .3s;
        // background: url(/assets/img/common/common_arrow_bg.png) no-repeat center bottom;
        background-size: 12px 8px;

        @include sp {
          right: 7px;
        }
      }
    }

    i {
      color: #1782a6;
      font-size: 21px;
      margin-right: 7px;
      display: inline-block;
      vertical-align: middle;
    }

    svg {
      color: $color_blue1;
      vertical-align: middle;
    }
  }

  .fa-map-marker-alt {
    width: 16px;
  }
  .fa-briefcase {
    width: 20px;
  }
  .fa-building {
    width: 18px;
  }
  .fa-star {
    width: 21px;
  }

  &__val {
    position: absolute;
    top: calc(100% + 27px);
    left: 0;
    background: #f1f9fb;
    color: #000;
    z-index: 9;
    border: 1px solid #cdd2d5;
    border-radius: 6px;
    white-space: nowrap;
    overflow: hidden;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2);

    @include pc {
      display: inline-grid;
      grid-template-columns: auto auto;
    }

    @include sp {
      top: calc(100% + 19px);
      width: calc(100vw - 42px);
    }

    li {
      line-height: 54px;
      border-bottom: 1px solid #cdd2d5;
      border-right: 1px solid #cdd2d5;
      margin: 0 -1px -1px 0;
      padding: 0 30px 0 60px;
      box-sizing: border-box;

      @include sp {
        line-height: 40px;
        font-size: 12px;
        padding-left: 40px;
      }

      input[type=checkbox] {
        &+label {
          margin: 0;
          padding: 0;

          &:before {
            border: 2px solid #1a81a2;
            width: 22px;
            height: 22px;
            left: -30px;
            top: 3px;
            border-radius: 4px;
          }

        }

        &:checked+label {
          &::before {
            background: #1a81a2;
          }

          &::after {
            border-left: 3px solid #fff;
            border-bottom: 3px solid #fff;
            left: -25px;
            top: 0;
            margin: 0;
          }
        }
      }
    }
  }

  &__select {
    @include pc {
      display: none !important;
    }

    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
}

[v-cloak] {
  display: none;
}
