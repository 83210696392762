@charset "utf-8";

// ▼Sass設定

@import "_variables.scss";

/***************************************
 コラムナビゲーション
***************************************/

.l-column-navi_pc{
  ul{
    width: $width_base;
    margin: 0 auto 35px;
    overflow: hidden;
    li{
      float: left;
      &:last-child{
        border: none;
      }
      a{
        background: $color_base;
        display: inline-block;
        width: $width_base / 6 - 1;
        @include common_Height(42px);
        color: #ffffff;
        text-align: center;
        border-right: solid 1px $color_gray1;
        &:hover{
          @include common_Alpha(1.0);
          background: $color_blue1;
        }
      }
    }
  }
}

/***************************************
 コラムタグ
***************************************/

.l-column-tagArea{
  margin: 0 0 40px;
  overflow: hidden;
  h2{
    background: $color_gray1;
    color: #ffffff;
    padding: 0 10px;
    font-size: 16px;
    font-weight: normal;
    margin: 0 0 15px;
    @include common_Height(42px);
  }
}

.l-column-tagIcon{
  margin: 10px 0 0;
  overflow: hidden;
  li{
    display: inline-block;
    a{
      display: inline-block;
      // background: $color_blue4 url(../img/column_tagIcon.png) no-repeat 5px center;
      background-size: 10px auto;
      border: solid 1px #C1DEE8;
      margin: 0 2px 10px 0;
      padding: 0 7px 0 18px;
      @include common_Height(20px);
      font-size: 11px;
      color: $color_blue1;
    }
  }
}

.l-column-tagIcon_list{
  overflow: hidden;
  li{
    display: inline-block;
    a{
      display: inline-block;
      // background: $color_blue4 url(../img/column_tagIcon.png) no-repeat 5px center;
      background-size: 10px auto;
      border: solid 1px #C1DEE8;
      margin: 0 2px 10px 0;
      padding: 0 7px 0 18px;
      @include common_Height(20px);
      font-size: 11px;
      color: $color_blue1;
    }
  }
}

.l-column-tag_more{
  display: inline-block;
  float: right;
  font-size: 11px;
  color: #ffffff;
  // background: $color_blue2 url(../img/common_arrow_white.png) no-repeat 10px center;
  background-size: 8px auto;
  padding: 2px 10px 2px 20px;
  border-radius: 3px;
  margin: 10px 0 0;
}

.l-column-tagArea{
  margin: 0 0 30px;
  .l-column-tagIcon{
    margin: 0;
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-column-tagArea{
    margin: 0 0 30px;
    h2{
      background: $color_gray2;
      font-size: 16px;
      @include common_Height(38px);
    }
  }

  .l-column-tagIcon{
    margin: 10px 0;
    li{
      a{
        margin: 0 4px 10px 0;
        padding: 0 7px 0 18px;
      }
    }
  }

  .l-column-tag_more{
    margin: 5px 0 0;
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-column-tagIcon_list{
    overflow: hidden;
    li{
      width: $width_all /2 - 1;
      vertical-align: top;
      display: inline-block;
      a{
        display: inline-block;
        // // background: url(../img/column_tagIcon.png) no-repeat 5px center;
        border: 0;
        background-size: 10px auto;
        margin: 0 4px 10px 0;
        padding: 2px 7px 2px 18px;
        font-size: 11px;
        color: $color_blue1;
      }
    }
  }

}

/***************************************
 コラム・インタビュー（トップ/一覧）
***************************************/

.l-column-listArea{
  overflow: hidden;
}

.l-column-baseBox{
  display: block;
  float: left;
  width: ($width_left - 30) / 3;
  box-sizing: border-box;
  margin: 15px 15px 0 0;
  &:hover{
    @include common_Alpha;
  }
  &:nth-child(3n){
    margin-right: 0;
  }
  &:nth-child(-n+3){
    margin-top: 0;
  }
  img{
    width: 100%;
    display: block;
  }
}

.l-column-adjustBox:nth-child(4){
  display: none;
}

.l-column-baseBox_text{
  h3{
    color: $color_base;
    line-height: 1.4;
    font-size: 15px;
    margin: 8px 0 0;
    text-justify: inter-ideograph;
  }
}

.m-column-baseBox_category{
  font-size: 12px;
  font-weight: bold;
  display: block;
}

.l-column-categoryBox{
  margin: 0 0 30px;
  overflow: hidden;
  border: solid 1px $color_gray1;
  box-sizing: border-box;
  h2{
    @include common_Height(43px);
    color: #ffffff;
    padding: 0 10px;
    font-size: 17px;
    font-weight: normal;
    background: $color_gray1;
  }
  section{
    margin: 15px 0 5px;
    padding: 0 0 15px;
    border-bottom: solid 1px $color_gray3;
    &:last-child{
      border: none;
    }
    h3{
      border-bottom: dotted 1px $color_gray1;
      margin: 0 15px 5px;
      font-size: 16px;
      a{
        color: $color_base;
      }
    }
    h4{
      padding: 5px 2px 0;
      margin: 5px 15px 0;
      font-size: 14px;
      a{
        color: $color_base;
      }
    }
    ul{
      margin: 0 20px;
      overflow: hidden;
      li{
        display: inline-block;
        margin: 5px 30px 0 0;
        text-indent: 15px;
        // // background: url(../img/common_arrow_rg.png) no-repeat left center;
        background-size: auto 50%;
        a{
          color: $color_gray1;
          position: relative;
          top: 1px;
        }
      }
    }
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-column-baseBox{
    width: ($width_all - 3) / 2;
    margin: 16px 0 0;
    &:nth-child(3n){
      margin-right: 0;
    }
    &:nth-child(-n+3){
      margin-top: 16px;
    }
    &:nth-child(even){
      float: right;
    }
    &:nth-child(-n+2){
      margin-top: 0;
    }
  }

  .l-column-adjustBox:nth-child(4){
    display: block;
  }

  .l-column-baseBox_text{
    h3{
      font-size: 16px;
    }
  }

  .m-column-baseBox_category{
    font-size: 14px;
  }

  .l-column-categoryBox{
    margin: 0 0 25px;
    border-radius: 4px;
    overflow: hidden;
    border: solid 1px $color_gray1;
    box-sizing: border-box;
    h2{
      @include common_Height(38px);
      color: #ffffff;
      padding: 0 10px;
      font-size: 16px;
      font-weight: normal;
      background: $color_gray1;
    }
    section{
      margin: -1px 0 0;
      padding: 0;
      h3{
        background: $color_gray4;
        border-bottom: none;
        padding: 0 10px;
        margin: 0;
        @include common_Height(48px);
        a{
          color: $color_base;
        }
      }
      h4{
        padding: 0 10px;
        margin: 0;
        position: relative;
        top: 1px;
        font-size: 14px;
        border-bottom: dotted 1px $color_gray1;
        @include common_Height(48px);
        a{
          color: $color_base;
        }
      }
      ul{
        margin: 0;
        position: relative;
        top: 1px;
        li{
          display: block;
          width: 50%;
          float: left;
          margin: 0;
          @include common_Height(48px);
          border-bottom: solid 1px $color_gray3;
          padding: 0 10px;
          box-sizing: border-box;
          text-indent: 0;
          background: none;
          &:nth-child(odd){
            border-right: solid 1px $color_gray3;
          }
          a{
            color: $color_gray1;
            display: block;
            width: 100%;
          }
        }
        .m-column-categoryList_style{
          border-bottom: none;
        }
      }
    }
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-column-baseBox{
    width: ($width_all - 3) / 2;
    margin: 10px 0 0;
    &:nth-child(3n){
      margin-right: 0;
    }
    &:nth-child(-n+3){
      margin-top: 10px;
    }
    &:nth-child(even){
      float: right;
    }
    &:nth-child(-n+2){
      margin-top: 0;
    }
  }

  .l-column-baseBox_text{
    h3{
      font-size: 12px;
      margin: 8px 0 0;
    }
  }

  .m-column-baseBox_category{
    font-size: 11px;
  }

  .l-column-categoryBox{
    section{
      ul{
        font-size: 12px;
      }
    }
  }

}

/***************************************
 コラム・インタビュー（詳細）
***************************************/

.m-column-title{
  line-height: 1.5;
  padding: 14px 18px;
  border-radius: 4px;
  margin: 0 0 12px;
}

.l-columnArea{
  h3{
    border: solid 1px $color_blue1;
    border-radius: 3px;
    color: $color_blue1;
    padding: 10px;
    margin: 30px 0 20px;
    font-size: 18px;
    line-height: 1.5;
  }
  h4{
    margin: 15px 0;
    font-size: 16px;
  }
  p{
    margin: 20px 0;
    text-justify: inter-ideograph;
  }
  small{
    margin: 0 0 10px;
    font-size: 11px;
    display: block;
  }
  img{
    width: 90%;
    display: block;
    margin: 20px 0 5px;
  }
}

.l-column-icon_category{
  display: inline-block;
  border: solid 1px $color_blue1;
  border-radius: 3px;
  color: $color_blue1;
  padding: 0 6px;
  @include common_Height(26px);
}

.l-interviewArea{

  &-searchPageInfo {
    position: relative;
    top: -70px;
  }

  h3{
    border: solid 1px $color_blue2;
    border-radius: 3px;
    color: $color_blue2;
    padding: 10px;
    margin: 28px 0 20px;
    font-size: 18px;
    line-height: 1.5;
  }
  h4{
    margin: 15px 0;
    font-size: 16px;
  }
  p{
    margin: 20px 0;
  }
  small{
    margin: 0 0 10px;
    font-size: 11px;
    display: block;
  }
  img{
    width: 100%;
    display: block;
    margin: 20px 0 5px;
  }
  span{
    display: block;
    font-weight: bold;
    font-size: 15px;
    margin: 20px 0 15px;
    line-height: 1.4;
    color: $color_base;
  }
}

.l-interview-profileArea{
  background: $color_gray4;
  padding: 15px 20px;
  margin: 30px 0;
  span{
    font-size: 18px;
    font-weight: bold;
    margin: 5px 0 12px;
    padding: 0 5px 0;
    display: inline-block;
    line-height: 1.4;
    color: $color_gray1;
  }
  p{
    margin: 0;
  }
}

.l-column-btnJoblink{
  margin: 30px 0;
  text-align: center;
  a{
    display: inline-block;
    @include common_Height(48px);
    background: #FFDBDB;
    border: solid 2px #FFA2A0;
    border-radius: 6px;
    color: $color_red1;
    font-size: 18px;
    width: 400px;
    img{
      vertical-align: middle;
      width: 16px;
      position: relative;
      top: -2px;
    }
  }
}

.l-column-snsFavoriteBox{
  margin: 10px 0;
  background: #E6E6E6;
  display: table;
  div{
    display: table-cell;
    vertical-align: middle;
  }
}

.m-column-snsFavoriteImg{
  width: 32%;
  display: inline-block;
  vertical-align: top;
  img{
    width: 100%;
  }
}

.m-column-snsFavoriteBtn{
  margin: 10px 0;
  width: 65%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  line-height: 1.4;
  p{
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 15px;
    br{
      display: none;
    }
  }
  small{
    font-size: 15px;
    display: block;
    margin: 10px 0 8px;
  }
  .m-column-snsBtn_fbLike{
    display: inline-block;
    vertical-align: top;
    transform:scale(1.29);
    -webkit-transform:scale(1.29);
    -moz-transform:scale(1.29);
    position: relative;
    top: 0;
    left: -20px;
  }
  .m-column-snsBtn_twitter{
    display: inline-block;
    vertical-align: top;
    transform:scale(1.3);
    -webkit-transform:scale(1.3);
    -moz-transform:scale(1.3);
    position: relative;
    top: 0;
    left: 20px;
  }
}

.l-column-btnBox{
  margin: 0 0 20px;
  a{
    display: inline-block;
    border-radius: 6px;
    border: none;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    background: $color_green1;
    @include common_Height(65px);
    width: 100%;
    margin: 0 0 6px;
    border-bottom: solid 4px $color_green2;
  }
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .m-column-title{
    padding: 10px 14px;
    border-radius: 4px;
    margin: 0 0 12px;
    font-size: 16px;
  }

  .l-columnArea{
    margin: 0 auto;
    h3{
      padding: 10px;
      margin: 30px 0 15px;
      font-size: 15px;
    }
    h4{
      margin: 15px 0;
      font-size: 15px;
    }
    p{
      margin: 20px 0;
    }
    small{
      margin: 0 0 10px;
      font-size: 10px;
    }
  }

  .l-column-icon_category{
    padding: 0 6px;
    @include common_Height(24px);
    font-size: 12px;
    margin: 0 6px 0 0;
  }

  .m-column-title{
    padding: 10px 14px;
    border-radius: 4px;
    margin: 0 0 12px;
    font-size: 16px;
  }

  .l-interviewArea{
    margin: 0 auto;

    &-searchPageInfo {
      margin: 0 10px;
      padding-bottom: 25px;
    }

    h3{
      padding: 10px;
      margin: 26px 0 15px;
      font-size: 15px;
    }
    h4{
      margin: 15px 0;
      font-size: 15px;
    }
    p{
      margin: 20px 0;
    }
    span{
      margin: 15px 0 10px;
    }
  }

  .l-interview-profileArea{
    padding: 10px 15px;
    margin: 20px 0;
    span{
      font-size: 14px;
      font-weight: bold;
      margin: 5px 0;
      padding: 0 5px 0;
    }
    p{
      margin: 5px 0;
      font-size: 12px;
    }
  }

  .m-column-snsFavoriteImg{
    width: 42%;
  }

  .m-column-snsFavoriteBtn{
    width: 55%;
    p{
      font-size: 15px;
      margin: 4px 0 10px;
      br{
        display: inline;
      }
    }
    small{
      font-size: 13px;
      margin: 5px 0 0;
    }
    .m-column-snsBtn_fbLike{
      position: relative;
      left: 0;
      display: block;
      margin: 0 0 12px;
    }
    .m-column-snsBtn_twitter{
      position: relative;
      left: 0;
      display: block;
    }
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-columnArea{
    font-size: 12px;
    img{
      margin: 20px 0 5px;
    }
    h4{
      margin: 10px 0;
    }
    p{
      margin: 15px 0;
    }
  }

  .l-column-btnJoblink{
    margin: 20px 0 25px;
    a{
      @include common_Height(46px);
      font-size: 15px;
      width: 95%;
      img{
        width: 15px;
        position: relative;
        top: -2px;
      }
    }
  }

  .l-interviewArea{
    font-size: 13px;
    img{
      margin: 20px 0 5px;
    }
    h4{
      margin: 10px 0;
    }
    p{
      margin: 15px 0;
    }
  }

  .m-column-snsFavoriteImg{
    width: 46%;
    overflow: hidden;
    img{
      position: relative;
      left: -15px;
      top: 0;
      width: 110%;
    }
  }

  .m-column-snsFavoriteBtn{
    width: 53%;
    p{
      span{
        display: none;
      }
    }
    small{
      font-size: 11px;
      margin: 0;
    }
    .m-column-snsBtn_fbLike{
      margin: 0 0 15px;
    }
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .m-column-snsFavoriteImg{
    width: 48%;
    img{
      position: relative;
      left: -10px;
      top: 0;
      width: 115%;
    }
  }

  .m-column-snsFavoriteBtn{
    width: 52%;
    p{
      font-size: 10px;
    }
    small{
      font-size: 10px;
    }
  }

}

/***************************************
 ソーシャルボタン（コラム）
***************************************/

.l-column-snsBtnArea_pc{
  margin: 10px 0 30px;
  overflow: hidden;
  li{
    vertical-align: top;
    display: inline-block;
    a{
      display: block;
      position: relative;
      width: 74px;
      height: 31px;
      border-radius: 2px;
      img{
        width: auto;
        height: 66%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}

.m-column-snsBtn_fb_pc{
  background: #3b5999;
}

.m-column-snsBtn_twitter_pc{
  background: #54abed;
}

.m-column-snsBtn_hatebu_pc{
  background: #008fde;
}

.m-column-snsBtn_line_pc{
  background: #00c300;
}

.m-column-snsBtn_fbLike_pc{
  display: inline-block;
  transform:scale(1.29);
  -webkit-transform:scale(1.29);
  -moz-transform:scale(1.29);
  position: relative;
  top: 2px;
  left: 10px;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-column-snsBtnArea_sp{
    overflow: hidden;
    position: fixed;
    bottom: -1px;
    background: #ffffff;
    z-index: 2;
    letter-spacing:-0.4em;
    width: $width_all;
    li{
      vertical-align: top;
      display: inline-block;
      letter-spacing:normal;
      width: $width_all / 4;
      a{
        display: block;
        position: relative;
        height: 40px;
        width: $width_all;
        img{
          width: auto;
          height: 55%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }
  }

  .m-column-snsBtn_fb_sp{
    background: #3b5999;
  }

  .m-column-snsBtn_twitter_sp{
    background: #54abed;
  }

  .m-column-snsBtn_hatebu_sp{
    background: #008fde;
  }

  .m-column-snsBtn_line_sp{
    background: #00c300;
  }

  .m-column-snsBtn_fbLike_sp{
    display: inline-block;
    transform:scale(1.29);
    -webkit-transform:scale(1.29);
    -moz-transform:scale(1.29);
    position: relative;
    top:-4px;
    left: 8px;
    margin: 0 0 0 5px;
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-column-snsBtnArea_pc{
    margin: 6px 0 20px;
    li{
      a{
        width: 42px;
      }
    }
  }

}

/***************************************
 コラム・インタビュー（右カラム）
***************************************/

.l-column-sideBox{
  &:hover{
    @include common_Alpha;
  }
  padding: 15px 0 10px;
  overflow: hidden;
  border-bottom: dotted 1px $color_gray2;
  .m-column-sideImg{
    width: 120px;
    height: auto;
    background: #cccccc;
    float: left;
    margin: 0 10px 0 0;
    overflow: hidden;
    img{
      width: 100%;
    }
  }
  .m-column-sideTitle{
    width: 160px;
    float: left;
    color: $color_base;
  }
  h3{
    font-size: 13px;
    line-height: 1.4;
    text-justify: inter-ideograph;
  }
}

.m-column-sideCategory{
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-common-side-searchBox_pc{
    display: none;
  }

  .l-column-sideBox{
    padding: 15px 0;
    .m-column-sideImg{
      width: 33%;
      background: #cccccc;
      float: left;
      margin: 0 10px 0 0;
      overflow: hidden;
    }
    .m-column-sideTitle{
      width: 65%;
      float: left;
    }
    h3{
      font-size: 17px;
      line-height: 1.4;
    }
  }

  .m-column-sideCategory{
    font-size: 14px;
    padding: 5px 0 0;
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-common-side-searchBox_pc{
    display: none;
  }

  .l-column-sideBox{
    .m-column-sideImg{
      width: 40%;
    }
    .m-column-sideTitle{
      width: 55%;
    }
    h3{
      font-size: 13px;
      line-height: 1.4;
    }
  }

  .m-column-sideCategory{
    font-size: 11px;
  }

}

/***************************************
 汎用ページ用
***************************************/

.l-static-area{
  margin: 0 0 30px;
  ol{
    margin: 0 0 0 25px;
    padding: 0;
  }
  ul{
    margin: 0 0 0 24px;
    li{
      text-indent: -24px;
      margin: 6px 0;
      line-height: 1.6;
    }
  }
}

.l-static-alignStyle{
  text-align: right;
  margin: 10px 0 0;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-static-area{
    margin: 0 0 20px;
    p{
      font-size: 12px;
    }
    ol{
      font-size: 12px;
    }
    ul{
      margin: 0 0 0 20px;
      font-size: 12px;
      li{
        text-indent: -20px;
      }
    }
  }

}

/***************************************
 ゼロワンインターンについて
***************************************/

.l-contents_about{
  width: 900px;
  margin: 0 auto 30px;
}

.l-about-main{
  // // background: url(../img/about_main.jpg) no-repeat -30px -130px;
  background-size: 130%;
  color: #ffffff;
  text-align: center;
  padding: 40px 20px 30px;
  border-bottom: none;
}

.l-about-chatch{
  width: 900px;
  margin: 0 auto;
  h2{
    font-size: 38px;
    line-height: 1.4;
    letter-spacing: 0.1em;
  }
  p{
    font-size: 18px;
    line-height: 1.6;
  }
}

.l-about-feature{
  width: 900px;
  margin: 40px auto 0;
  h3{
    font-size: 26px;
    line-height: 1;
  }
  ul{
    font-size: 20px;
    margin: 0 auto;
    display: table;
    border-spacing: 15px;
    li{
      display: table-cell;
      vertical-align: middle;
      background: rgba(67,187,221,0.25);
      color: #ffffff;
      width: 300px;
      height: 120px;
      border-radius: 6px;
      box-sizing: border-box;
      line-height: 1.4;
      position: relative;

    }
  }
}

.l-about-step{
  margin: 0 0 20px;
  padding: 5px 15px 30px;
  border-top: none;
  section{
    text-align: center;
    h2{
      line-height: 1.4;
    }
    p{
      margin: 6px 0;
    }
  }
}

.l-about-stepBox{
  font-size: 22px;
  font-weight: bold;
  color: $color_blue1;
  line-height: 1;
  margin: 0 0 6px;
  span{
    font-size: 34px;
  }
}

.l-about-stepImg{
  background: $color_blue1;
  width: 120px;
  margin: 0 auto 10px;
  @include common_Height(120px);
  border-radius: 50%;
  img{
    width: 78%;
    vertical-align: middle;
    position: relative;
    top: -2px;
  }
}

.m-about-arrow{
  width: 35px;
  display: block;
  margin: 15px auto;
}

.l-about-btnBox{
  text-align: center;
}

.l-about-BalloonArea{
  width: 80%;
  margin: 0 auto;
}

.m-about-btn_red{
  background: $color_red1;
  border: none;
  border-bottom: solid 4px $color_red2;
  display: inline-block;
  border-radius: 5px;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  margin: 0 2px;
  vertical-align: top;
  @include common_Height(70px);
  width: 300px;
}

/* ############ 767px以下 ############ */
@media (max-width: 767px) {

  .l-contents_about{
    width: 93%;
  }

  .l-about-main{
    background-size: 120%;
    padding: 30px 20px 20px;
    background-position: -20px -20px;
  }

  .l-about-chatch{
    width: auto;
    margin: 0 auto;
    h2{
      font-size: 26px;
    }
    p{
      font-size: 14px;
      text-align: left;
      margin: 15px 30px;
    }
  }

  .l-about-feature{
    width: auto;
    margin: 30px 0 0;
    h3{
      font-size: 20px;
    }
    ul{
      font-size: 16px;
      li{
        height: 100px;
      }
    }
  }

  .l-about-step{
    padding: 15px 70px 10px;
    section{
      h2{
        font-size: 22px;
        text-align: left;
      }
      p{
        margin: 6px 0;
        text-align: left;
      }
    }
  }

  .m-about-btn_red{
    @include common_Height(60px);
  }

}

/* ############ 599px以下 ############ */
@media (max-width: 599px) {

  .l-about-style{
    margin: 0 auto;
  }

  .l-contents_about{
    width: 100%;
  }

  .l-about-main{
    border: none;
    background-size: 158%;
    padding: 30px 20px 30px;
    background-position: -100px top;
  }

  .l-about-chatch{
    width: auto;
    margin: 0 auto;
    h2{
      font-size: 24px;
    }
    p{
      font-size: 12px;
      margin: 15px;
    }
  }

  .l-about-feature{
    margin: 30px 0 0;
    h3{
      font-size: 18px;
    }
    ul{
      font-size: 13px;
      border-spacing: 10px;
      li{
        height: 80px;
      }
    }
  }

  .l-about-step{
    margin: 15px 0 20px;
    padding: 0 25px 20px;
    section{
      h2{
        font-size: 19px;
      }
      p{
        font-size: 13px;
        margin: 8px 0;
      }
    }
  }

  .l-about-stepBox{
    font-size: 20px;
    span{
      font-size: 28px;
    }
  }

  .l-about-stepImg{
    width: 110px;
    @include common_Height(110px);
  }

  .m-about-arrow{
    width: 30px;
  }

  .l-about-BalloonArea{
    width: 90%;
    margin: 0 auto;
  }

  .m-about-btn_red{
    @include common_Height(58px);
    font-size: 17px;
    width: 85%;
  }

}

/* ############ 399px以下 ############ */
@media (max-width: 399px) {

  .l-about-main{
    background-size: 180%;
    padding: 25px 10px;
    background-position: -140px top;
    h2{
      font-size: 20px;
    }
    p{
      margin: 10px;
    }
  }

  .l-about-chatch{
    width: auto;
    margin: 0 auto;
    h2{
      font-size: 20px;
    }
    p{
      font-size: 12px;
      margin: 10px;
    }
  }

  .l-about-feature{
    h3{
      font-size: 16px;
    }
    ul{
      font-size: 11px;
      li{
        height: 75px;
      }
    }
  }

  .l-about-step{
    border: none;
    margin: 15px 0 10px;
    padding: 0 20px 10px;
    section{
      h2{
        font-size: 16px;
      }
      p{
        font-size: 12px;
        margin: 8px 0;
      }
    }
  }

  .l-about-stepBox{
    font-size: 18px;
    span{
      font-size: 26px;
    }
  }

  .l-about-stepImg{
    width: 100px;
    @include common_Height(100px);
  }

}
